import React, { useEffect, useState } from 'react';
import { Row, Col, Divider, Dropdown, Space, theme, Menu } from "antd";
import EquipeMenageCreate from "./modal/equipe-menage-create";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { API_ROOT } from "../../../../utils/global-var";
import { useGetStatUserMutation } from "../../services/logement-api";
import { IoEllipsisHorizontal } from "react-icons/io5";
import AttribuerModalView from "./modal/attribuer-logement";
import DemanderAssignationLogementModalView from "./modal/assignation-logement";
import BloquerProfilModalView from "./modal/bloquer-profil";
import PourboireModalView from "./modal/pourboire";
import PourboireConfirmationModalView from "./modal/pourboire-confirmation";
import SuccessNotification from "../../../notification-global/success";
import { useGetDefaultPaymentMethodQuery } from '../../../profil/services/profil-api';
import MoyenPaiementModalView from '../../../modal-global/moyen-de-paiement';
const { useToken } = theme;

function TeamFoundView({ logement }) {
    const [showModal, setShowModal] = useState(false);
    const [showNotif, setShowNotif] = useState(false);
    const [phone, setPhone] = useState('+33 0 0 00 00 00');
    const [showModal2, setShowModal2] = useState(false);
    const [showModalAss, setShowModalAss] = useState(false);
    const [showModalBloquer, setShowModalBloquer] = useState(false);
    const [showModalPourboire, setShowModalPourboire] = useState(false);
    const [showModalPourboireConfirmation, setShowModalPourboireConfirmation] = useState(false);
    const [responsableStat, setResponsableStat] = useState([]);
    const [aidemenagereStat, setAidemenagereStat] = useState([]);
    const [getStatsRequest] = useGetStatUserMutation();
    const [showPayMethodModal, setPayMethodModal] = useState(false);
    const payMethod = useGetDefaultPaymentMethodQuery();


    const checkPayMethod = () => {
        if(payMethod.isSuccess && payMethod.data) {
            setShowModal(true);
        } else {
            setPayMethodModal(true)
        }
    }
    

    useEffect(() => {
        getStatsRequest({ pageView: "RESPONSABLE", users: [logement?.assignment?.responsable?.id] }).unwrap()
            .then((value) => {
                setResponsableStat(value.stats);
            })
            .catch(() => {
            });
        getStatsRequest({ pageView: "AIDE_MENAGERE", users: [logement?.aide_menagere?.id] }).unwrap()
            .then((value) => {
                setAidemenagereStat(value.stats);
            })
            .catch(() => {
            });
    }, [logement?.assignment?.responsable?.id]);

    const itemsalone = [
        {
            key: 'itemsalone7',
            label: (
                <div className="dropdown-item-team" onClick={() => setShowModalPourboire(true)}>
                    <img src="/assets/img/pourboire-icon.png" alt="airbnb" className="dropdown-item-team-img" /> <div className="dropdown-item-team-label">Verser un pourboire</div>
                </div>
            ),
        }
    ];
    const menuTab = (
        <Menu>
            {itemsalone.map(item => (
                item.type === 'divider' ? (
                    <Menu.Divider key="divider" />
                ) : (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                )
            ))}
        </Menu>
    );
    const items = [
        {
            key: 'items1',
            label: (
                <div className="dropdown-item-team" onClick={() => setShowModal2(true)}>
                    <img src="/assets/img/attribuer-lgt-icon.png" alt="airbnb" className="dropdown-item-team-img" /> <div className="dropdown-item-team-label">Attribuer un logement</div>
                </div>
            ),
        },
        {
            key: 'items2',
            label: (
                <div className="dropdown-item-team" onClick={() => setShowModalPourboire(true)}>
                    <img src="/assets/img/pourboire-icon.png" alt="airbnb" className="dropdown-item-team-img" /> <div className="dropdown-item-team-label">Verser un pourboire</div>
                </div>
            ),
        },
        {
            key: 'items3',
            label: (
                <div className="dropdown-item-team">
                    <img src="/assets/img/switch-team.png" alt="airbnb" className="dropdown-item-team-img" /> <div className="dropdown-item-team-label">Changer d'équipe</div>
                </div>
            ),
        },
    ];

    const { token } = useToken();
    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };
    const menuStyle = {
        boxShadow: 'none',
    };

    return (
        <div className="blc-right equipe-de-menage">
            <div className="title">Équipe en charge de ce logement</div>
            <Divider />
            <Row gutter={24}>
                <Col lg={16}>
                    <div className="subTitle">Responsable de site</div>
                    <Row>
                        <Col lg={16}>
                            <p className="calibri-light color-566F8F">Le Responsable de site pilote l’équipe d’aides ménagères qui entretient le logement confié et il
                                veille à la bonne exécution des prestations. Il peut se rendre disponible à votre demande pour visiter le logement ou échanger vos exigences précises en termes de qualité.</p>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <div className="boutton-demande">
                                <button className="add-team" onClick={() => checkPayMethod()}> Demander une nouvelle équipe </button>
                            </div>
                        </Col>
                    </Row>
                    <div className="blc-content">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="cadre-chef">
                                    <img src={API_ROOT + '/' + logement?.assignment?.responsable?.avatar} alt="airbnb" className="chef-img" />
                                </div>
                                <div className="name-chef">
                                    <div className="title sans-pro-semi-bold">{logement?.assignment?.responsable?.firstname} {logement?.assignment?.responsable?.lastname}</div>
                                    <div className="subtitle sans-pro-regular">Kiphone Nettoyage</div>
                                </div>
                                <div className="number-chef">
                                    <PhoneInput
                                        flags={flags}
                                        value={logement?.assignment?.responsable?.phone}
                                        defaultCountry="FR"
                                        disabled={true}
                                    />
                                </div>
                                <div className="button-dropdown-team">
                                    <Dropdown
                                        menu={{
                                            items,
                                        }}
                                        dropdownRender={(menu) => (
                                            <div style={contentStyle}>
                                                {React.cloneElement(menu, {
                                                    style: menuStyle,
                                                })}
                                                <Divider
                                                    style={{
                                                        margin: 0,
                                                    }}
                                                />
                                                <Space
                                                    style={{
                                                        paddingLeft: 15,
                                                        paddingTop: 10,
                                                        paddingBottom: 10,
                                                    }}
                                                >
                                                    <div className="dropdown-item-team" onClick={() => setShowModalBloquer(true)}>
                                                        <img src="/assets/img/bloquer-profil-icon.png" alt="airbnb" className="dropdown-item-team-img" /> <div className="dropdown-item-team-label" style={{ color: "red", fontWeight: "500" }}> Bloquer ce profil</div>
                                                    </div>
                                                </Space>
                                            </div>
                                        )}
                                        placement="bottomRight"
                                    >
                                        <IoEllipsisHorizontal style={{ fontSize: "19px", marginRight: "10px", marginTop: "10px" }} />
                                    </Dropdown>
                                </div>
                                <br /><br />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24}>
                                <div className="leftNC">
                                    <div className="nc set sans-pro-semi-bold">{(responsableStat.length !== 0) && responsableStat?.prestations_total_number}</div>
                                    <div className="nc-label">Prestations</div>
                                </div>
                                <div className="traitMiddle">

                                </div>
                                <div className="rightNC">
                                    <div className="nc set sans-pro-semi-bold">{(responsableStat.length !== 0) && responsableStat?.prestations_successful_percentage}%  <img src="/assets/img/pousse.png" alt="pousse" className="rightNC-img" /></div>
                                    <div className="nc-label">Taux de performance</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <div className="subTitle">Aide-ménagère principale</div>
                    <p className="calibri-light color-566F8F">C’est l’aide-ménagère qui est dédiée à l’entretien de votre logement.</p>
                    <div className="blc-content-simple">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={8}>
                                            <div className="aidem-content">
                                                <img src="/assets/img/user-empty.png" alt="airbnb" className="aidem-img" />
                                            </div>

                                            <div className="aidem-label set sans-pro-regular">{logement?.aide_menagere?.firstname} {logement?.aide_menagere?.lastname}</div>
                                        </Col>
                                        <Col lg={10}>
                                            <div className="aidem-label sans-pro-regular">Taux de performance : {(aidemenagereStat.length !== 0) && aidemenagereStat?.prestations_successful_percentage}%</div>
                                        </Col>
                                        <div className="button-dropdown-team-alone">
                                            <Dropdown
                                                menu={{
                                                    items: items.filter(item => item.key !== '1' && item.key !== '3'),
                                                }}
                                                dropdownRender={(menu) => (
                                                    <div style={contentStyle}>
                                                        {React.cloneElement(menu, {
                                                            style: menuStyle,
                                                        })}
                                                        <Divider
                                                            style={{
                                                                margin: 0,
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                paddingLeft: 15,
                                                                paddingTop: 10,
                                                                paddingBottom: 10,
                                                            }}
                                                        >
                                                            <div className="dropdown-item-team" onClick={() => setShowModalBloquer(true)}>
                                                                <img src="/assets/img/bloquer-profil-icon.png" alt="airbnb" className="dropdown-item-team-img" /> <div className="dropdown-item-team-label" style={{ color: "red", fontWeight: "500" }}> Bloquer ce profil</div>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                )}
                                                placement="bottomRight"
                                            >
                                                <IoEllipsisHorizontal style={{ fontSize: "19px", marginRight: "10px", marginTop: "10px" }} />
                                            </Dropdown>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <div className="subTitle griser-text">Aide-ménagères remplaçantes</div>
                    <p className="griser-text calibri-light color-566F8F">Ce sont les aides ménagères qui sont déjà intervenues sur ce logement en remplacement de l’aide-ménagère <br /> principale. En cas de nouvel empêchement de l’intervenant principal, elles seront sollicitées en priorité.</p>
                    <div className="blc-content-simple">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-item griser-text">
                                    <Row gutter={24}>
                                        <Col lg={8}>
                                            <div className="aidem-content">
                                                <img src="/assets/img/user-empty.png" alt="airbnb" className="aidem-img" />
                                            </div>
                                            <div className="aidem-label set "><span className="griser-text">Aucune</span></div>
                                        </Col>
                                        <Col lg={8}>
                                            <div className="aidem-label griser-text"></div>
                                        </Col>
                                        {/*<div className="button-dropdown-team-alone">
                                            <Dropdown
                                                menu={{
                                                    items: items.filter(item => item.key !== '1' && item.key !== '3'),
                                                }}
                                                dropdownRender={(menu) => (
                                                    <div style={contentStyle}>
                                                        {React.cloneElement(menu, {
                                                            style: menuStyle,
                                                        })}
                                                        <Divider
                                                            style={{
                                                                margin: 0,
                                                            }}
                                                        />
                                                        <Space
                                                            style={{
                                                                paddingLeft: 15,
                                                                paddingTop: 10,
                                                                paddingBottom: 10,
                                                            }}
                                                        >
                                                            <div className="dropdown-item-team"
                                                                 onClick={() => setShowModalBloquer(true)}>
                                                                <img src="/assets/img/bloquer-profil-icon.png"
                                                                     alt="airbnb" className="dropdown-item-team-img"/>
                                                                <div className="dropdown-item-team-label"
                                                                     style={{color: "red", fontWeight: "500"}}> Bloquer
                                                                    ce profil
                                                                </div>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                )}
                                                placement="bottomRight">
                                                <IoEllipsisHorizontal
                                                    style={{fontSize: "19px", marginRight: "10px", marginTop: "10px"}}/>
                                            </Dropdown>
                                        </div>*/}
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <EquipeMenageCreate
                status={showModal}
                logement={logement}
                handleCancel={() => setShowModal(false)}
            />
            <AttribuerModalView
                status={showModal2}
                handleCancel={() => {
                    setShowModal2(false);
                    // setShowModalAss(true);
                }}
            />
            <DemanderAssignationLogementModalView
                status={showModalAss}
                handleCancel={() => setShowModalAss(false)}
            />
            <BloquerProfilModalView
                status={showModalBloquer}
                handleCancel={() => setShowModalBloquer(false)}
            />
            <PourboireModalView
                status={showModalPourboire}
                handleCancel={() => {
                    setShowModalPourboire(false);
                    setShowModalPourboireConfirmation(true);
                }}
            />
            <PourboireConfirmationModalView
                status={showModalPourboireConfirmation}
                handleCancel={() => {
                    setShowModalPourboireConfirmation(false);
                    setShowNotif(true);
                }}
            />
            <MoyenPaiementModalView
                    status={showPayMethodModal}
                    handleCancel={() => setPayMethodModal(false)}
                />
            {showNotif && <SuccessNotification
                show={showNotif}
                placement={"bottomRight"}
                timer={5}
                message={"Pourboire versé"}
                resetShow={() => setShowNotif(false)}
            />}
        </div>
    );
}

export default TeamFoundView;