import React, { useState } from "react";
import { Row, Col, Input, message, Spin } from "antd";
import { useLogementUpdateMutation } from "../../services/logement-api";

const { TextArea } = Input;

function VousFournissezView({ logement }) {
    const [value, setValue] = useState(logement.laundryManagementInstruction);
    const [logementUpdate] = useLogementUpdateMutation();
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState(false);


    const handleBlur = () => {
        if (value?.length > 0) {
            setIsLoading(true)
            logementUpdate({ id: logement.id, data: ({ laundryManagementInstruction: value }) }).unwrap()
                .then(() => {
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                    setIsLoading(false)
                })
                .catch(() => setIsLoading(false)
                );
        }
    };

    return (
        <div className="vous-fournissez blc-right">
            {contextHolder}
            <Spin spinning={isLoading}>
                <Row gutter={24}>
                    <Col lg={24}>
                        <p>Où doit-on déposer le linge sale dans le logement ? (recommandé)</p>
                        <TextArea
                            className="radiusTextArea"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            onBlur={handleBlur}
                            rows={8}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}

export default VousFournissezView;