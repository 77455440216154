import React, { useState, useEffect } from 'react';
import { useLogementWithPerformanceMutation } from '../../../../services/logement-api';
import { Spin } from "antd";
import { renameLogement } from '../../../../utils/logement-utils';
import "../../../../styles/style.scss"

function LogementsActifs({ logementSelected }) {
    const [logements, setLogements] = useState([]);
    const [logementWithPerformance] = useLogementWithPerformanceMutation();
    const [loading, setLoading] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);


    const handleFetchLogements = async (data) => {
        setLoading(true)
        logementWithPerformance(data).unwrap()
            .then((res) => {
                setLogements(renameLogement(res.data.logements))
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            });
    };

    useEffect(() => {
        const data = ({ page: 1, pageSize: 10, order: "DESC", status_color: ["GREEN"] })
        handleFetchLogements(data)
    }, []);



    const handleCheckboxChange = (logementId) => {
        const selectedIndex = selectedItems.indexOf(logementId);
        if (selectedIndex === -1) {
            setSelectedItems([...selectedItems, logementId]);
        } else {
            const updatedSelectedItems = [...selectedItems];
            updatedSelectedItems.splice(selectedIndex, 1);
            setSelectedItems(updatedSelectedItems);
        }
    };

    useEffect(() => {
        if (selectedItems) { logementSelected(selectedItems); }
    }, [selectedItems]);

    return (
        <Spin spinning={loading}>
            <div className='choisir-logement'>
                <div className='choisir'>
                    <br />
                    {logements.map((item, index) => (
                        <div className="result" key={index}>
                            <div className="icone">
                                <img src="./assets/img/icon-adresse.png" alt="cone" />
                            </div>
                            <div className="text">
                                <div className="quartier">{item.logement_libelle}</div>
                                <div className="address disable">{item.logement_codepostal} {item.logement_ville} | {item.forfait_menage}€</div>
                            </div>
                            <div className="check">
                                <input
                                    type="checkbox"
                                    className="checker"
                                    onChange={() => handleCheckboxChange(item.logement_id)}
                                    checked={selectedItems.includes(item.logement_id)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Spin>
    );
}

export default LogementsActifs;
