import React, { useState } from "react";
import {Button, Col, Input, Modal, Row} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import AccueilPubPage from "./pages/accueil-page";
import DashboardPubPage from "./pages/dashboard-page";
import PubLogementModalPage from "./campagne";

function PubModalPage({ status, handleCancel }) {
    const [sectionModalPub, setSectionModalPub] = useState('AccueilPubPage');
    const onCancel = () => {
        handleCancel();
    };
    return (
        <>
            <Modal
                destroyOnClose
                style={{top:65}}
                open={status} footer={null} closable={true} width={1800} onCancel={onCancel} className="pub-page pub-page-special">


                {sectionModalPub === "AccueilPubPage" && <div className="pub"><AccueilPubPage itemSelected={setSectionModalPub} /> </div>}
                {sectionModalPub === "DashboardPubPage" && <div className="pub"><DashboardPubPage itemSelected={setSectionModalPub}/></div>}
                {sectionModalPub === "PubLogementModalPage" && <div className="pub2"><PubLogementModalPage itemSelected={setSectionModalPub}/></div>}

            </Modal>
        </>
    );
}

export default PubModalPage;