export const apiKey = "AIzaSyCGXYxZ8JUgfYoFgXPEEST-FhhoUGUsynk";
export const mapApiJs = "https://maps.googleapis.com/maps/api/js";

export function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  });
}

export const extractAddress = (place) => {
  const address = {
    city: "",
    state: "",
    zip: "",
    country: "",
    street: "",
    street_number: "",
    plain() {
      const city = this.city ? this.city + ", " : "";
      const zip = this.zip ? this.zip + ", " : "";
      const state = this.state ? this.state + ", " : "";
      const street = this.street ? this.street + ", " : "";
      const country = this.country ? this.country + ", " : "";
      const street_number = this.street_number ? this.street_number + ", " : "";
      return city + zip + state + country + street_number + street;
    },
  };

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach((component) => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("locality")) {
      address.city = value;
    }

    if (types.includes("street_number")) {
      address.street_number = value;
    }

    if (types.includes("route")) {
      address.street = value;
    }

    if (types.includes("administrative_area_level_2")) {
      address.state = value;
    }

    if (types.includes("postal_code")) {
      address.zip = value;
    }

    if (types.includes("country")) {
      address.country = value;
    }
  });
  return address;
};
