import React, { useState } from "react";
import { Col, Row, Button, Form, Input, Alert, Divider } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { IoMdRadioButtonOn, IoMdRadioButtonOff } from "react-icons/io";
import { useAuthCheckUserMutation } from "../services/auth-api";
import "../styles/style.scss";
import success from "../../notification-global/success";

export function RegisterPage() {
    const navigate = useNavigate();
    const [profileSeleted, setProfileSeleted] = useState('');
    const [email, setEmail] = useState('');
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);
    const [authCheckUser] = useAuthCheckUserMutation();


    const onCheckUser = async (value) => {
        setIsLoading(true);
        authCheckUser({ email: value })
            .unwrap()
            .then(() => {
                setIsLoading(false);
                setError("Email déjà rattaché à un compte");
                setSuccess("");
            })
            .catch(() => {
                setIsLoading(false);
                setError("");
                setSuccess("Adresse email valide");
            });
    };

    const onFinish = async (values) => {
        const data = {
            ...values, "profil": profileSeleted,
            "role": "customer"
        }

        setIsLoading(true);
        authCheckUser({ email: values.email })
            .unwrap()
            .then(() => {
                setIsLoading(false);
                setError("Email déjà rattaché à un compte");
                setSuccess("");
            })
            .catch(() => {
                setSuccess("");
                setIsLoading(false);
                navigate("/register-profil", { state: data });
            });
    };


    const handleDivClick = (index) => {
        setProfileSeleted(index === profileSeleted ? null : index);
    };

    const handleEmailChange = (e) => setEmail(e.target.value);


    return (
        <div className="auth" data-aos="fade-in">
            <div className="login-page">
                <a href="https://kliner.com">
                    <img src="/assets/img/logo-black-small.png" alt="kliner" className="login-logo-topleft" />
                </a>                <div className="blc-content">
                    <h1>Bienvenue chez Kliner</h1>
                    <p>Vous êtes à quelques secondes de simplifier la gestion du ménage sur vos locations saisonnières comme vous ne l’avez jamais imaginé.</p> <br />
                    <Form
                        name="basic"
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="">
                        <Form.Item name="email">
                            <Input placeholder="Votre mail..." onChange={handleEmailChange}  onBlur={(e) =>onCheckUser(e.target.value)} />
                        </Form.Item>
                        <Divider plain>Quel type de loueur êtes-vous ?</Divider>
                        <div className={profileSeleted === "conciergerie indépendante" ? "radio-btn radio-btn-active" : "radio-btn radio-btn-disabled"} onClick={() => handleDivClick("conciergerie indépendante")}>
                            <span>Professionnel (Conciergerie, gîte...)</span>
                            {profileSeleted === "conciergerie indépendante" ? <IoMdRadioButtonOn className="icon" /> : <IoMdRadioButtonOff className="icon" />}
                        </div>

                        <div className={profileSeleted === "particulier" ? "radio-btn radio-btn-active" : "radio-btn radio-btn-disabled"} onClick={() => handleDivClick("particulier")}>
                            <span>Particulier (LMNP)</span>
                            {profileSeleted === "particulier" ? <IoMdRadioButtonOn className="icon" /> : <IoMdRadioButtonOff className="icon" />}
                        </div>

                        <br />
                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="btn-lg" disabled={!email || !profileSeleted} loading={isLoading}
                                style={{ fontWeight: "600" }}> Continuer
                            </Button>
                        </Form.Item>
                        <center>
                            <span className="text-cgu">En créant votre compte, vous acceptez les <a href="https://www.kliner.com/mentions-legales-confidentialite/" target="_blank" rel="noopener">CGV & la politique de confidentialité</a> de Kliner.</span>
                        </center>
                    </Form>
                    <br />
                    {error && <Alert message={error} type="error" closable />}
                    {success && <Alert message={success} type="success" closable />}

                    <div className="footer">
                        <center>
                            <p>Vous avez déjà un compte ? <Link to={"/"}>Se connecter</Link> </p>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RegisterPage;
