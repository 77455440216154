import React from 'react';
import "../../../../styles/style.scss"

function PourboireListTabPage() {
    return (
        <div className='choisir-logement'>
            <div className='choisir'>
                <p className="disable">
                    Vous ne lui avez jamais versé de
                    pourboire.
                </p>
            </div>
        </div>
    );
}

export default PourboireListTabPage;
