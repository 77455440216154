import React from 'react';
import "./styles/style.scss"
import {Button, Col, Row} from "antd";

function ResponsivePage() {
    return (
        <div className='responsive-page'>
            <Row>
                <Col lg={9}></Col>
                <Col lg={6}>
                    <div className="message">
                        <div className="logo">
                            <img src="./assets/img/logoblackhd.png" alt="logo"/>
                        </div>
                        <br/>
                        <div className="caution">
                            <img src="./assets/img/warning-colored.png" alt="logo"/>
                        </div>
                        <div className="title sans-pro-bold">
                            Les fonctionnalités de l’app Kliner sont limitées sur mobile !
                        </div>
                        <div className="description sans-pro-regular">
                            Nous vous conseillons d’utiliser la plateforme Kliner sur ordinateur afin de bénéficier d’une expérience complète. Cependant vous pouvez utiliser l’application mobile pour garder un œil sur l’entretien de vos logements lorsque vous vous déplacez.
                        </div>
                        <div className="link">
                            <a href="https://kliner.app.link/e/Application-du-menage">
                                <Button type={"primary"} className="sans-pro-semi-bold">Ouvrir l’application</Button>
                            </a>
                        </div>
                    </div>
                </Col>
                <Col lg={9}></Col>
            </Row>
        </div>
    );
}

export default ResponsivePage;
