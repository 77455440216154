import React, {useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { Modal, Row, Col, TimePicker, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { usePrestationUpdateMutation } from "../services/planning-api";
import { setRefresh } from "../services/planning-slice";
import moment from "moment";
import {IoCopyOutline} from "react-icons/io5";

const UpdateTime = ({ status, handleCancel, prestationId, creneauDebut, creneauFin }) => {
    const [timeStart, setTimeStart] = useState(creneauDebut);
    const [timeEnd, setTimeEnd] = useState(creneauFin);
    const [prestationUpdate] = usePrestationUpdateMutation();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeStart(creneauDebut);
        setTimeEnd(creneauFin);
    }, [creneauDebut, creneauFin, prestationId]);
    const handleChangeTimeStart = (time, timeString) => {
        const datePart = creneauDebut.split('T')[0];
        const combinedDateTime = `${datePart}T${timeString}:00.000Z`;
        setTimeStart(combinedDateTime)
    };

    const handleChangeTimeEnd = (time, timeString) => {
        const datePart = creneauFin.split('T')[0];
        const combinedDateTime = `${datePart}T${timeString}:00.000Z`;
        setTimeEnd(combinedDateTime)
    };


    const handleSave = () => {
        const data = ({ creneau_intervention_debut: timeStart, creneau_intervention_fin: timeEnd })
        setLoading(true)
        prestationUpdate({ id: prestationId, data: data })
            .unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                setLoading(false);
                handleCancel()
            })
            .catch(() => setLoading(false));
    }

    return (
        <Modal
            destroyOnClose
            title=""
            open={status}
            footer={null}
            style={{ top: 325, left: -300 }}
            closable={false}
            onCancel={() => handleCancel()}
            width={280}
            className="changer-creneau">
            <Spin spinning={loading}>
                <div className="creneau">
                    <div className="head">
                        <div className="arrow" onClick={handleCancel}><ArrowLeftOutlined /></div>
                        <div className="title"><div className="title-in">Modifier le créneau d'intervention</div></div>
                    </div>
                    <br />
                    <p>La prestation se déroulera entre</p>
                    <br />
                    <Row>
                        <Col lg={10}>
                            <TimePicker  className="form-control" onChange={handleChangeTimeStart} format={'HH:mm'} placeholder="" />
                        </Col>
                        <Col lg={4}>
                            <div className="separator">
                                et
                            </div>
                        </Col>
                        <Col lg={10}>
                            <TimePicker className="form-control" onChange={handleChangeTimeEnd} format={'HH:mm'} placeholder="" />
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <Row>
                        <Col lg={3}>
                            <IoCopyOutline style={{fontSize:"20px", color:"grey"}}/>
                        </Col>
                        <Col lg={21}>
                            <div className="indication-creneau-inteervantion-modal">
                                Ce créneau d’intervention sera désormais retenu pour toutes les prestations sur ce logement.
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Col lg={24} className="button-right">
                            <a href="#" onClick={() => handleSave()}
                                className="suivant sans-pro-regular">Confimer</a>
                        </Col>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default UpdateTime;
