import React, {useEffect, useMemo, useState} from 'react';
import { Button, Divider, notification, Space } from 'antd';
import {IoCheckmarkCircleOutline} from "react-icons/io5";
const Context = React.createContext({
    name: 'Default',
});
const SuccessNotification = ({placement, message, timer, resetShow}) => {
    const [api, contextHolder] = notification.useNotification();
    const [showModal, setShowModal] = useState(true);
    useEffect(() => {
        if(showModal){
            openNotification();
            setShowModal(false);
        }
    }, [showModal]);
    const openNotification = () => {
        api.info({
            message: <div className="messageNotif">{message}</div>,
            description: <div className="messageLineNotif">
                <div className="messageLineNotifCovered"></div>
            </div>,
            placement,
            icon: <div className="iconeNotification">
                <IoCheckmarkCircleOutline />
            </div>,
            duration: timer,
            onClose: resetShow,
            className: "SuccessNotificationClass",
            closeIcon: <div className="closeIconLineNotif">ok</div>
        });
    };
    return (
        <>
            {contextHolder}
        </>
    );
};
export default SuccessNotification;