import React, { useState, useEffect } from 'react';
import {Row, Col, Switch, Divider, message, Table} from "antd";


function PaiementParTiersView({ logement }) {
    const [messageApi, contextHolder] = message.useMessage();
    const payList = [
        {
            statut: false,
            email: "",
            moyen: ""
        }
    ];
    const columns = [
        {
            title: "Activé",
            width: "10%",
            render: (record) => (
                <div>
                    <Switch
                        checked={false}
                        onChange={(checked) => null}
                    />
                </div>
            ),
        },

        {
            title: 'Email',
            width: "30%",
            render: (record) => (
                <div>
                    <div className="actif-inactif">Email du proprietaire...</div>
                </div>
            ),
        },
        {
            title: 'Moyen de paiement',
            width: "30%",
            render: (record) => (
                <div>
                    <div className="card-zone">
                        <Row>
                            <Col lg={4}>
                                <div className="card-image">
                                    <img src="../assets/img/empty-payment-icon.png" alt="check" />
                                </div>
                            </Col>
                            <Col lg={15}>
                                <div className="card-number sans-pro-regular color-A7A7A7">
                                    Aucun
                                </div>
                            </Col>
                            <Col lg={1}>
                                {/*<div className="check-image">
                                    <img src="./assets/img/green-check.png" alt="check"/>
                                    </div> */}
                            </Col>
                        </Row>
                    </div>
                </div>
            ),
        },
        {
            title: 'Statut',
            width: "30%",
            render: (record) => (
                <div className="actif-inactif">{record.statut ? "Actif" : "Inactif"}</div>
            ),
        },
    ];



    return (
        <div className="blc-right equipe-de-menage">
            {contextHolder}
            <div className="title">Paiement par tiers</div>
            <Divider />
            <Row gutter={24}>
                <Col lg={14}>
                    <div className="subTitle">Coordonnées du payeur</div>
                    <Row>
                        <Col lg={16}>
                            <p className="calibri-light color-566F8F">
                                Faites payer les prestations de ménage sur ce logement par le propriétaire.
                                <a href="https://help.kliner.com/fr/articles/9445243" target="_blank"> (En savoir plus)</a>
                            </p>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <div className="boutton-demande" style={{marginTop:"-34px"}}>
                                <button className="add-team disable-Button" onClick={() =>null}> Associer le propriétaire</button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24}>
                            <br/>
                            <Table
                                className="paiement-tier-table"
                                pagination={false}
                                dataSource={payList}
                                columns={columns}
                                rowKey={(record) => record.key}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24}>

                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default PaiementParTiersView;