export const addressChecked = (logement) => {
    if (logement?.libelle.includes("Logement de ")) {
        return false
    } else {
        return true
    }
};

export const gestionCleChecked = (logement) => {
    return !(logement?.gestion_cle === "None" || logement?.gestion_cle === null || logement?.gestion_cle_desc === null);
};

export const surfaceChecked = (logement) => {
    if ((logement?.superficie === null) || (logement?.superficie === "0") || (logement?.superficie === "")) {
        return false
    } else {
        return true
    }
};

export const gestionLingeChecked = (logement) => {
    switch (logement?.laundryManagementType) {
        case "SELF":
            return true;
        case "FULLY_MANAGED":
            return !((logement?.lit_simple === null || logement?.lit_simple === "0") && (logement?.lit_double === null || logement?.lit_double === "0") && (logement?.lit_king === null || logement?.lit_king === "0"));
        case "PARTIALLY_MANAGED":
            return logement?.laundryManagementAdresse !== null;
        case "None":
        case "full":
        case null:
            return false;
        default:
            return false;
    }
};

export const kitAccueilChecked = (cart) => {
    if (cart?.length === 0) {
        return false
    } else {
        return true
    }
};

export const materielProduitChecked = (logement) => {
    if (logement?.statut_kit_linge || logement?.statut_produit_menager) {
        return true
    } else {
        return false
    }
};
export const EquipeMenage = (logement) => {
    if (logement?.assignment?.status === "Offre acceptée") {
        return true
    }
    return false
};


export const renameLogement = (logements) => {
    const nameValueLookup = {};
    return logements.map((obj) => {
        if (nameValueLookup[obj.logement_libelle]) {
            return {
                ...obj,
                logement_libelle: `${obj.logement_libelle} ${nameValueLookup[obj.logement_libelle]++}`,
            };
        } else {
            nameValueLookup[obj.logement_libelle] = 1;
            return obj;
        }
    });
};

export const customStatus = (status) => {
    switch (status) {
        case "ORANGE":
            return "#fa7f0d";
        case "GREEN":
            return "#65ae04";
        case "RED":
            return "#ff0000e3";
        default:
            return "gray";
    }
};