import React, { useState } from 'react';
import { Col, Flex, Layout, Row, Space, Form } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { countryCodesMap } from '../../../utils/global-var';
import "../styles/style.scss"

const { Header } = Layout;

function InviteEnvoyePage() {
    const [form] = Form.useForm();
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState(countryCodesMap['FR']);

    const handleCountryChange = (value) => {
        setCountryCode(countryCodesMap[value]);
    };

    return (
        <div className='addtierce-page'>
            <Header>
                <Flex justify={"space-between"}>
                    <Col lg={23}>
                        <Row>
                            <Col lg={11}>
                                <div className="titre sans-pro-semi-bold">
                                    Intégration d'une équipe tierce à Kliner
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Space className="icons-head">
                            <NavLink to={"/equipes-entretien"}>
                                <CloseOutlined style={{ fontSize: "30px", marginRight: "10px", color: "grey" }} />
                            </NavLink>
                        </Space>
                    </Col>
                </Flex>
            </Header>
            <div className="context">
                <Row>
                    <Col lg={6} />
                    <Col lg={14}>
                        <div className="content-in">
                            <div className="invitationSend">
                                <Row>
                                    <Col lg={2} />
                                    <Col lg={12}>
                                        <div className="image">
                                            <img src="./assets/img/Sent invit.png" alt="thx" />
                                        </div>
                                        <div className="titre sans-pro-semi-bold">
                                            Invitation envoyée !
                                        </div>
                                        <div className="message sans-pro-regular">
                                            Merci Brice, nous venons d’inviter Paul à vous retrouver sur Kliner! Nous vous préviendrons par mail dès que son compte sera prêt sur votre espace.
                                        </div>
                                        <div className="greet sans-pro-regular">
                                            N’hésitez pas à contacter votre partenaire <br />
                                            pour l’inciter à accélérer la démarche.
                                        </div>
                                    </Col>
                                    <Col lg={10} />
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} />
                </Row>
            </div>
            <div className="footer">
                <NavLink to={"/equipes-entretien"}>
                    <div className="button-next sans-pro-semi-bold">
                        <span>Fermer</span>
                    </div>
                </NavLink>
            </div>
        </div>
    );
}

export default InviteEnvoyePage;