import React, {useEffect, useState} from 'react';
import "./styles/style.scss"
import "./styles/styleMax1020.scss"

function AppNotification() {
    const status = sessionStorage.getItem('showNews');
    const [isLoading, setIsLoading] = useState(status?? false);
    const changeSession = () => {
        sessionStorage.removeItem('showNews');
        window.location.reload(true);
        setIsLoading(false);
    }
    return (
        <>
            {(isLoading) && <div className="footer-notification">
                Votre application Kliner à été mise à jour 🎉. <strong style={{cursor:"pointer"}} onClick={changeSession}> Rechargez la page</strong> pour profiter de la dernière version 🤩.
            </div>}
        </>
    );
}

export default AppNotification;
