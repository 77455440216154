import React, { useState, useEffect } from 'react';
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import {Avatar, Tabs} from "antd";
import ToutTabPage from "./tabs/tout";
import TarifsLibresTabPage from "./tabs/tarifs-libres";
import { useEquipePerformanceGetMutation } from '../services/equipe-api';
import "../styles/style.scss"
import {API_ROOT} from "../../../utils/global-var";

const EquipeDrawerPage = ({ equipe }) => {
    console.log("equipe:", equipe);
    const [equipePerformanceGet] = useEquipePerformanceGetMutation();
    const [stat, setStat] = useState()

    console.log(stat);

    const handleFetchEquipe = async (data) => {
        equipePerformanceGet(data).unwrap()
            .then((res) => {
                console.log("res====>", res)
                setStat(res.stats)
                // setResponsables(res.data.responsables);
                 //setStats(res.stats)
            })
            .catch(() => {
                // setIsLoadingLogements(false)
            });
    };



    useEffect(() => {
        const data = ({ "users": [equipe.id] })
        handleFetchEquipe(data)
    }, []);


    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: <div className="sans-pro-regular">Tout</div>,
            children: <ToutTabPage />,
        },
        {
            key: '2',
            label: <div className="sans-pro-regular">Tarifs libres</div>,
            children: <TarifsLibresTabPage />,
        },
    ];
    return (
        <div className="apercu-equipes">
            <div className="blocId">
                <div className="image">
                    <Avatar src={`${API_ROOT}/${equipe?.avatar}`} alt="image" style={{width: "130px", height:"130px"}} />
                </div>
                <div className="name sans-pro-regular">{equipe?.firstname} {equipe?.lastname}</div>
                <div className="corp sans-pro-light">{equipe?.entreprise}</div>
                <div className="stats">
                    <div className="prestations">
                        <div className="number sans-pro-semi-bold">{stat?.prestations_total_number}</div>
                        <div className="label sans-pro-regular">Prestations</div>
                    </div>
                    <div className="traitStat">

                    </div>
                    <div className="percent">
                        <div className="number sans-pro-semi-bold">{stat?.prestations_successful_percentage}% <div className="icon"><img src="./assets/img/pousse.png" alt="pousse" /></div></div>
                        <div className="label sans-pro-regular">Taux de performance</div>
                    </div>
                </div>
                <div className="telephone">
                    <div className="number-chef">
                        <PhoneInput
                            flags={flags}
                            value={equipe?.phone}
                            defaultCountry="FR"
                            disabled={true}
                        />
                    </div>
                </div>
                <div className="logement-head sans-pro-semi-bold">
                    Logements gérés
                </div>
                <div className="tabList">
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                </div>
            </div>
        </div>
    );
}

export default EquipeDrawerPage;
