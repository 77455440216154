import React, { useState, useEffect } from 'react';
import { Row, Col, Switch, Divider, message } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useCoutDureeUpdateMutation, useLogementUpdateMutation } from '../../services/logement-api';
import * as logementActions from '../../services/logement-slice'


function MaterielProduitMenagerView({ logement }) {
    const [messageApi, contextHolder] = message.useMessage();
    const { user } = useSelector((state) => state.auth);
    const [statutKitLinge, setStatutKitLinge] = useState(logement.statut_kit_linge);
    const [statutProduitMenager, setStatutProduitMenager] = useState(logement.statut_produit_menager);
    const [logementUpdate] = useLogementUpdateMutation();
    const [coutDureeUpdate] = useCoutDureeUpdateMutation();
    const dispatch = useDispatch();

    useEffect(() => {
        if(logement){
            setStatutKitLinge(logement.statut_kit_linge);
            setStatutProduitMenager(logement.statut_produit_menager);
        }
    }, [logement]);

    const onChangeLinge = (checked) => {
        setStatutKitLinge(checked);
        const data = ({ statut_kit_linge: checked })
        logementUpdate({ id: logement.id, data: data }).unwrap()
            .then(() => {
               handleUpdateCout({ statutKitLinge: checked, statutProduitMenager: statutProduitMenager })
                //dispatch(logementActions.setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => { });
    };


    const onChangeProduit = (checked) => {
        setStatutProduitMenager(checked);
        const data = ({ statut_produit_menager: checked})
        logementUpdate({ id: logement.id, data: data }).unwrap()
            .then(() => {
                handleUpdateCout({ statutProduitMenager: checked, statutKitLinge: statutKitLinge })
                //dispatch(logementActions.setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => { });
    };


    const handleUpdateCout = (value) => {
        const data = {
            logementId: logement.id,
            user: user.id,
            type: "gestion_kit",
            laundryKit: {
                product:value.statutProduitMenager ,
                kit: value.statutKitLinge,
            },
        };

        coutDureeUpdate(data).unwrap()
            .then((res) => { dispatch(logementActions.setRefresh(true)); })
            .catch(() => { });
    };


    return (
        <div className="blc-right materiel-produit-menager">
            {contextHolder}
            <div className="title">Matériel & Produits ménagers</div>
            <Divider />
            <Row gutter={24}>
                <Col lg={14}>
                    <div className="subTitle">Nécessaire pour faire le ménage</div>
                    <p className="color-566F8F calibri-light">Activez les options ci-dessous si vous souhaitez que Kliner fournisse le matériel de nettoyage et les produits ménagers nécessaires à
                        la bonne réalisation des prestations. </p>
                    <div className="blc-content blc-content-no-text">
                        <Row gutter={24}>
                            <Col lg={24} className="mat-border-rigth">
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={2} className="switch-side">
                                            <Switch value={statutProduitMenager} onChange={(checked) => onChangeProduit(checked)} />
                                        </Col>
                                        <Col lg={18}>
                                            <p className="mintitle">Kliner doit fournir les produits ménagers (produits nettoyants, entretien canalisation… )</p>
                                        </Col>
                                        <Col lg={4} className="">
                                            <div className="price color-5B6E8C sans-pro-light"><span>+3€</span></div>
                                            <div className="price-label color-5B6E8C sans-pro-light"><span>Par prestation</span></div>
                                        </Col>
                                    </Row>
                                </div>

                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={2} className="switch-side">
                                            <Switch value={statutKitLinge} onChange={(checked) => onChangeLinge(checked)} />
                                        </Col>
                                        <Col lg={18}>
                                            <p className="mintitle">Kliner doit fournir le matériel de nettoyage (balai, serpillère, seau, essuie-tout…)</p>
                                        </Col>
                                        <Col lg={4} className="">
                                            <div className="price color-5B6E8C sans-pro-light"><span>+5€</span></div>
                                            <div className="price-label color-5B6E8C sans-pro-light"><span>Par prestation</span></div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default MaterielProduitMenagerView;