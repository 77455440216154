import React, { useState } from "react";
import {Badge, Button, Col, Divider, Input, Modal, Row} from "antd";
import {useControleListFetchQuery} from "../services/control-qualite-api";
import moment from "moment/moment";

function ControleFinPrestationPage({ status, controle, dysfonctionnement, handleCancel }) {
    const dysfonctionnementList = useControleListFetchQuery();
    const onCancel = () => {
        handleCancel();
    };

    const customStatus = (value) => {
        switch (value) {
            case "sale":
                return "Plutôt sale";
            case "degrade":
                return "Très dégradé";
            default:
                return "Normal";
        }
    }

    return (
        <>
            <Modal
                destroyOnClose
                style={{top:150}}
                open={status} footer={null} closable={true} width={800} onCancel={onCancel} styles={{padding:"50px"}} className="controleFinPrestation">
                <div className="titre">
                    Rapport de prestation
                </div>
                <div className="elementControle">
                    <div className="question">Dans quel état de propreté avez-vous trouvé le logement en arrivant ?</div>
                    <div className="direct">{customStatus(controle?.logement_cleaning_state)}</div>
                    <div className="reponse">

                    </div>
                </div>
                <Divider/>
                <div className="elementControle">
                    <div className="question">Avez-vous remis le logement en état d'accueillir de nouveaux voyageurs ?</div>
                    {controle?.is_ready_for_new_traveler !== null && <>
                        <div
                            className="direct">{controle?.is_ready_for_new_traveler ? "OUI" : "NON"}</div>
                    </>
                    }
                </div>
                <Divider/>
                <div className="elementControle">
                    <div className="question">Le logement dispose-t-il du linge de maison nécessaire pour accueillir des voyageurs ?</div>
                    {controle?.is_laundry_available !== null && <>
                        <div
                        className="direct">{controle?.is_laundry_available ? "OUI" : "NON"}</div>
                        {controle?.is_laundry_available === false && <div className="reponse">
                            {controle?.laundry_availability_description}
                        </div>}
                    </>
                    }
                </div>
                <Divider/>
                <div className="elementControle">
                    <div className="question">Le logement dispose-t-il des produits d'accueil nécessaires pour accueillir des voyageurs ?</div>
                    {controle?.is_product_available !== null && <>
                        <div
                            className="direct">{controle?.is_product_available ? "OUI" : "NON"}</div>
                        {controle?.is_product_available === false && <div className="reponse">
                            {controle?.product_availability_description}
                        </div>}
                    </>
                    }
                </div>
                <Divider/>
                <div className="elementControle">
                    <div className="question">Dysfonctionnement remarqué dans le logement:</div>

                    {((dysfonctionnement?.length >= 1 && dysfonctionnement[0]["id"] === 1) || (dysfonctionnement?.length === 0)) && <div className="direct">Rien à signaler</div>}
                    {dysfonctionnement?.length >= 1 && dysfonctionnement[0]["id"] !== 1 && <div className="reponse">
                        <ul>
                            {dysfonctionnement?.map((item, index) => {
                                    return <li key={"dysfonc_id_" + index}>{item["dysfunction"]["name"]}</li>;
                            })}
                        </ul>
                    </div>}
                </div>
            </Modal>
        </>
    );
}

export default ControleFinPrestationPage;