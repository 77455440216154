import React, { useState } from "react";
import {Badge, Button, Col, Input, Modal, Progress, Row, Select} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import AccueilPubPage from "./pages/accueil-page";
import DashboardPubPage from "./pages/dashboard-page";
import {IoArrowForward} from "react-icons/io5";

const PubLogementModalPage = ({itemSelected}) => {

    return (
        <div className='PubLogementModalPage'>
            <div className="header">
                <div className="title sans-pro-semi-bold">
                    Lancer la campagne pour booster vos réservations
                </div>
                <div className="config">
                    <div className="left">
                        <div className="base" onClick={()=> itemSelected("DashboardPubPage")}>
                            Logements /
                        </div>
                        <div className="ville">
                            <Select
                                defaultValue="Belleville"
                                onChange={null}
                                suffixIcon={<div className='icon-logement-select'><img src="../assets/img/arrow-down-gray.png" alt="add"/> </div>}
                                options={[
                                    {
                                        value: 'Belleville',
                                        label: 'Belleville',
                                    },
                                    {
                                        value: 'Mirabeau',
                                        label: 'Mirabeau',
                                    },
                                    {
                                        value: 'Pasteur',
                                        label: 'Pasteur',
                                    },
                                    {
                                        value: 'Hermine',
                                        label: 'Hermine',
                                    },
                                    {
                                        value: 'Longchamps',
                                        label: 'Longchamps',
                                    },
                                    {
                                        value: 'Boulogne',
                                        label: 'Boulogne',
                                    },
                                    {
                                        value: 'Chatelet',
                                        label: 'Chatelet',
                                    },
                                    {
                                        value: 'Malabo',
                                        label: 'Malabo',
                                    },
                                    {
                                        value: 'Trône',
                                        label: 'Trône',
                                    },
                                ]}
                            />
                        </div>
                        <div className="statut">
                            <Badge color={"green"} count={"Publié"}></Badge>
                        </div>
                    </div>
                    <div className="rigth">
                        <Button>
                            Arrêter la campagne
                        </Button>
                    </div>
                </div>
            </div>
            <div className='contentPubLogement'>
                <Row>
                    <Col lg={16}>
                        <div className="leftBox">
                            <div className="stats">
                                <Row>
                                    <Col lg={8}>
                                        <div className="stats-off">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/target-icon.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    AUDIENCE
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                Voyageur vers Paris
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="stats-off">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/budget -icon.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    BUDGET JOURNALIER
                                                </div>
                                                <div className="price">
                                                    5.00€
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                <div className="indicateur">
                                                    <div className="inner"> </div>
                                                </div>
                                                <div className="progresscontent">
                                                    <Progress percent={0} showInfo={false} strokeColor="#74DA74" trailColor="#F3E3D1" />
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="stats-off">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/diagram.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    RESULSTATS QUOTIDIENS ESTIMES
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                40 - 117 <span>visites de votre annonce</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="configuration">
                                <div className="title">
                                    Paramètres de la publicité
                                    <div className="icone">
                                        <img src="./assets/img/ia.png" alt="icon"/>
                                    </div>
                                </div>
                                <div className="listConfig">
                                    <div className="element">
                                        <div className="icone">
                                            <img src="./assets/img/url icon.png" alt="icon"/>
                                        </div>
                                        <div className="titleConf">
                                            URL de l'annonce
                                        </div>
                                        <div className="valueConf sans-pro-semi-bold">
                                            oaiziaodizzinnzixzoiznxz.co
                                        </div>
                                        <div className="action">
                                            <div className="icone">

                                            </div>
                                            <div className="icone">
                                                <img src="./assets/img/edit -grey.png" alt="icon"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="element augImage">
                                        <div className="icone">
                                            <img src="./assets/img/titre court icon.png" alt="icon"/>
                                        </div>
                                        <div className="titleConf">
                                            Titre court
                                        </div>
                                        <div className="valueConf sans-pro-semi-bold">
                                            Hôtel particulier d’exception loué entier
                                        </div>
                                        <div className="action">
                                            <div className="icone">
                                                <img src="./assets/img/sync-grey.png" alt="icon"/>
                                            </div>
                                            <div className="icone">
                                                <img src="./assets/img/edit -grey.png" alt="icon"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="element reducImage">
                                        <div className="icone">
                                            <img src="./assets/img/titre long icon.png" alt="icon"/>
                                        </div>
                                        <div className="titleConf">
                                            Titre long
                                        </div>
                                        <div className="valueConf sans-pro-semi-bold">
                                            Réservez ce logement unique pour votre séjour à Paris
                                        </div>
                                        <div className="action">
                                            <div className="icone">
                                                <img src="./assets/img/sync-grey.png" alt="icon"/>
                                            </div>
                                            <div className="icone">
                                                <img src="./assets/img/edit -grey.png" alt="icon"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="element">
                                        <div className="icone">
                                            <img src="./assets/img/description icon.png" alt="icon"/>
                                        </div>
                                        <div className="titleConf">
                                            Description
                                        </div>
                                        <div className="valueConf sans-pro-semi-bold">
                                            Envie d’une escapade inoubliable à Paris ? Evadez-vous dans ce logement exceptionnel pour seulement 90€ la nuit !
                                        </div>
                                        <div className="action">
                                            <div className="icone">
                                                <img src="./assets/img/sync-grey.png" alt="icon"/>
                                            </div>
                                            <div className="icone">
                                                <img src="./assets/img/edit -grey.png" alt="icon"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="element">
                                        <div className="icone">
                                            <img src="./assets/img/images icon.png" alt="icon"/>
                                        </div>
                                        <div className="titleConf">
                                            Images
                                        </div>
                                        <div className="valueConf sans-pro-semi-bold">
                                           Déjà importé de votre annonce!
                                        </div>
                                        <div className="action">
                                            <div className="icone">

                                            </div>
                                            <div className="icone">

                                            </div>
                                        </div>
                                    </div>
                                    <div className="element augImage">
                                        <div className="icone">
                                            <img src="./assets/img/publicité multicanal.png" alt="icon"/>
                                        </div>
                                        <div className="titleConf">
                                            Plateformes de diffusion
                                        </div>
                                        <div className="valueConf">
                                            <div className="icone">
                                                <img src="./assets/img/Instagram_icon.png" alt="icon"/>
                                            </div>
                                            <div className="icone">
                                                <img src="./assets/img/facebook.png" alt="icon"/>
                                            </div>
                                            <div className="icone">
                                                <img src="./assets/img/google icon.png" alt="icon"/>
                                            </div>
                                            <div className="icone">
                                                <img src="./assets/img/TikTok-icon-black-brand.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="action">
                                            <div className="icone">

                                            </div>
                                            <div className="icone">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="title">
                                Performances
                                <div className="filter">
                                    <div className="date-start">janv. 1, 2024</div>
                                    <IoArrowForward />
                                    <div className="date-end">mars. 12, 2024</div>
                                    <div className="icone">
                                        <img src="./assets/img/planning.png" alt="icone"/>
                                    </div>
                                </div>
                            </div>
                            <div className="stats">
                                <Row>
                                    <Col lg={8}>
                                        <div className="stats-in">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/diagram.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    BUDGET DÉPENSÉ
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                0€
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="stats-in">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/diagram.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    IMPRESSIONS
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                0€
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="stats-in">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/diagram.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    VISITES PAGE DE PRESTATION
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                0€
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="stats-in">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/diagram.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    RÉSERVATIONS GÉNÉRÉES
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                0€
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="stats-in">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/diagram.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    REVENUS LOCATIFS
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                0€
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={8}>
                                        <div className="stats-in">
                                            <div className="head">
                                                <div className="icone">
                                                    <img src="./assets/img/diagram.png" alt="icon"/>
                                                </div>
                                                <div className="text">
                                                    ROI
                                                </div>
                                            </div>
                                            <div className="content-stats">
                                                0€
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={1}/>
                    <Col lg={7}>
                        <div className="rigthBox">
                            <div className="title">
                                APERÇU DE LA PUBLICITÉ
                            </div>
                            <div className="apercuPub">

                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default PubLogementModalPage;