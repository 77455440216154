import React from 'react';
import { Modal, Space, Button } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { showIcalDialog } from '../../../services/logement-slice';

function CalendarEmptyModal() {
    const dispatch = useDispatch();
    const icalDialog = useSelector((state) => state.logement);

    return (
        <Modal open={icalDialog.isEmpty} footer={null} closable={false}>
            <h2>Calendrier vide</h2>
            <p>
                Nous n’avons détecté aucun évènement à une date postérieure à
                aujourd’hui sur le calendrier fourni. Nos robots repasseront toutes
                les 90 minutes pour vérifier s’il y a du nouveau
            </p>
            <br />
            <Space style={{ display: "flex", justifyContent: "end" }}>
                <Button type="primary" htmlType="submit" onClick={() => dispatch(showIcalDialog({ isEmpty: false }))}>
                    Ok. j'ai compris
                </Button>
            </Space>
        </Modal>
    );
}

export default CalendarEmptyModal;
