import React, { useState } from 'react';
import "../styles/style.scss"
import { Badge, Col, Divider, Row, Table } from "antd";
import { API_ROOT } from "../../../utils/global-var";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useGetInvoicesQuery } from '../services/profil-api';
import moment from "moment";
import "moment/locale/fr";

function FacturesPartPage() {
    const invoices = useGetInvoicesQuery();
    console.log("###################################", invoices);
    const factures = [
        {
            key: 3,
            facture: `B5B3BC8B-0012`,
            date: `01.05.2024`,
            total: "134.00 €",
            statut: "paid",
        },
        {
            key: 3,
            facture: `B5B3BC8B-0012`,
            date: `01.05.2024`,
            total: "134.00 €",
            statut: "not-paid",
        },
        {
            key: 3,
            facture: `B5B3BC8B-0012`,
            date: `01.05.2024`,
            total: "134.00 €",
            statut: "review",
        },
        {
            key: 3,
            facture: `B5B3BC8B-0012`,
            date: `01.05.2024`,
            total: "134.00 €",
            statut: "paid",
        },
    ];
    const columns = [
        {
            title: 'Numéro de facture',
            key: "numero",
            width: "25%",
            render: (record) => <div style={{ cursor: "pointer" }}>
                <div className="titleTable">{record.facture}</div>
            </div>,
        },
        {
            title: 'Date',
            key: "date",
            width: "23%",
            render: (record) => <div style={{ cursor: "pointer" }}>
                <div className="titleTable">{record.date}</div>
            </div>,
        },
        {
            title: 'Total',
            key: "total",
            width: "23%",
            render: (record) => <div style={{ cursor: "pointer" }}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: 'Statut',
            key: "statut",
            width: "23%",
            render: (record) => <div className="badgeTable">
                {(record.statut === "during") && (<Badge color="#faad14" count={"En cours"} />)}
                {(record.statut === "pending") && (<Badge color="black" count={"A faire"} />)}
                {(record.statut === "done") && (<Badge color="green" count={"Fait"} />)}
                {(record.statut === "cancel") && (<Badge color="red" count={"Annulé"} />)}
            </div>,
        },
        {
            title: '',
            key: "download",
            width: "6%",
            render: (record) => <div className="download">
                <img src="./assets/img/logon.png" alt="download" />
            </div>,
        }
    ];
    return (
        <div className="profil-part-page">
            <h2 className='sans-pro-semi-bold'>Mes factures</h2>
            <div className="headprofil">
                <Table
                    className="facture-table"
                    columns={columns}
                />
                {invoices.isSuccess && invoices.data?.map((item, index) => (
                    <Row className="factures-listes" key={"factures_" + index}>
                        <Col lg={6}>
                            <div className="tableTitle sans-pro-semi-bold">{item["number"]}</div>
                        </Col>
                        <Col lg={6}>
                            <div className="tableTitle">{moment(new Date(item.created * 1000)).format("LLL")}</div>
                        </Col>
                        <Col lg={6}>
                            <div className="tableTitle">{((item["amount_due"] || 0) / 100).toFixed(2)} €</div>
                        </Col>
                        <Col lg={5}>
                            <div className="tableTitle">
                                {(item["status"] === "paid") && (<Badge color="green" count={"Payée"} />)}
                                {(item["status"] === "open") && (<Badge color="red" count={"Impayée"} />)}
                                {(item["status"] === "draft") && (<Badge color="black" count={"Brouillon"} />)}
                                {(item["status"] === "void") && (<Badge color="red" count={"Annulée"} />)}
                            </div>
                        </Col>
                        <Col lg={1}>
                            <a className="download" href={item.invoice_pdf} download={true} >
                                <img src="./assets/img/download-icon.png" alt="download" />
                            </a>
                        </Col>
                    </Row>
                ))}
            </div>
        </div>
    );
}

export default FacturesPartPage;
