import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover,
    Button
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import EvaluationSuccess from "../evaluation/evaluation-sucess";

const AgrandirView = ({ status, handleCancel, setValid }) => {
    const [mydate, setMysate] = useState(null);
    const evaluation = useSelector((state) => state.dashboard);

    useEffect(() => {
        setMysate(null);
    }, [status]);
    const handleChangeDate = (value) => {
        if (value === null) {
            setMysate("");
        } else {
            setMysate(value);
        }
    }

    const handleValid = () => {
        setValid(true);
        handleCancel();
    };

    const contentInfo = (
        <div className="infoEquipe">
            <p>
                Secteur dans lequel vous <br /> recherchez des logements
            </p>
        </div>
    );


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={true}
            onCancel={() => {
                handleCancel();
            }}
            width={700}
            className="agrandir"
        >
            <div className="agrandir-parc">
                <div className="title sans-pro-semi-bold"><div className="title-label">Kliner vous aide à développer votre conciergerie</div></div>
                <br />

                <div className="steps">
                    <div className="step1">
                        <div className="label">
                            <span>1</span>
                        </div>
                        <div className="circle">
                            <div className="circle-objet"></div>
                            <div className="line-object"></div>
                        </div>
                    </div>
                    <div className="step2">
                        <div className="label">
                            <span>2</span>
                        </div>
                        <div className="circle">
                            <div className="circle-objet"></div>
                            <div className="line-object"></div>
                        </div>
                    </div>
                    <div className="step3">
                        <div className="label">
                            <span>3</span>
                        </div>
                        <div className="circle">
                            <div className="circle-objet"></div>
                            <div className="line-object"></div>
                        </div>
                    </div>
                </div>

                <Row>
                    <Col lg={8}>
                        <div className="step-text-title sans-pro-semi-bold">Remplir ce formulaire</div>
                        <div className="step-text-describe sans-pro-light color-213856">
                            Dîtes-nous quel type de <br /> propriétaires vous <br /> recherchez.
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="step-text-title sans-pro-semi-bold">Trouver des matchs</div>
                        <div className="step-text-describe sans-pro-light color-213856">
                            On détecte les propriétaires <br /> compatibles dans notre <br /> base de données.
                        </div>
                    </Col>
                    <Col lg={8}>
                        <div className="step-text-title sans-pro-semi-bold">Mettre en relation</div>
                        <div className="step-text-describe sans-pro-light color-213856">
                            Nous vous présentons <br /> des profils à compter de <br /> votre 6ème mois de <br /> fidélité à Kliner.
                        </div>
                    </Col>
                </Row>
                <br />
                <br />
                <Row>
                    <Col lg={22} >
                        <label htmlFor="secteur" className="secteur sans-pro-regular" >Quel secteur géographique couvrez-vous ?</label>
                        <Popover placement="top" content={contentInfo} overlayClassName="infoContentEquipe">
                            <div className="info-equipe">
                                <img src="/assets/img/info-circled.png" alt="info" />
                            </div>
                        </Popover>
                        <Input prefix={<SearchOutlined />} id="secteur" className="form-control" onChange={(e) => handleChangeDate(e)} placeholder="Saisir la ville, le département ou la région visée" />
                    </Col>
                </Row>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <Row>
                    <Col lg={10} >
                        <label htmlFor="standing" className="secteur sans-pro-regular" >Quel standing de logement acceptez-vous ?</label>
                        <div className="info-equipe">

                        </div>
                        <Select
                            id="standing"
                            onChange={null}
                            className="form-control"
                            placeholder="Sélectionner"
                            suffixIcon={<div className='icon-agrandir-select'><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>}
                            options={[
                                {
                                    value: 'Standing A (Haut de gamme)',
                                    label: 'Standing A (Haut de gamme)',
                                },
                                {
                                    value: 'Standing B (Moyen de gamme)',
                                    label: 'Standing B (Moyen de gamme)',
                                },
                                {
                                    value: 'Standing C (Ordinaire)',
                                    label: 'Standing C (Ordinaire)',
                                },
                                {
                                    value: 'Tous types de standing',
                                    label: 'Tous types de standing',
                                },
                            ]}
                        />

                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider />
                    <Row>
                        <Col lg={12} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Annuler </span></a>
                        </Col>
                        <Col lg={12} className="button-right">
                            <Button 
                             type="primary"
                            className='btn'>
                                <center>Envoyer</center>
                            </Button>
                            {/* <a href="#" onClick={handleValid}
                                className="suivant sans-pro-regular">Envoyer</a> */}
                        </Col>
                    </Row>
                </div>
            </div>
            {evaluation.isEligible && <EvaluationSuccess />}

        </Modal>
    );
};
export default AgrandirView;
