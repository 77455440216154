import React from 'react';
import "../../styles/style.scss"
import {Button, Col, Input, Row} from "antd";
import {IoArrowForward} from "react-icons/io5";

const DashboardPubPage = ({itemSelected}) => {

    const graphInfo =  [
        {
            month: "FEV",
            percent: 52,
            current: false
        },
        {
            month: "MARS",
            percent: 24,
            current: false
        },
        {
            month: "AVR",
            percent: 36,
            current: false
        },
        {
            month: "MAI",
            percent: 48,
            current: false
        },
        {
            month: "JUIN",
            percent: 88,
            current: false
        },
        {
            month: "FEV",
            percent: 76,
            current: false
        },
        {
            month: "AOÛT",
            percent: 46,
            current: true
        },
    ];
    return (
        <>
            <div className="menu">
                <div className={"menu-in sans-pro-semi-bold"} onClick={() => itemSelected("AccueilPubPage")}>Accueil</div>
                <div className={"menu-in sans-pro-semi-bold active"} onClick={() => itemSelected("DashboardPubPage")}>Dashboard</div>
            </div>
        <div className='DashboardPubPage'>

            <Row>
                <Col lg={16}>
                    <div className="leftBox">
                        <div className="title">
                            Performances
                            <div className="filter">
                                <div className="date-start">janv. 1, 2024</div>
                                <IoArrowForward />
                                <div className="date-end">mars. 12, 2024</div>
                                <div className="icone">
                                    <img src="./assets/img/planning.png" alt="icone"/>
                                </div>
                            </div>
                        </div>
                       <div className="stats">
                           <Row>
                               <Col lg={8}>
                                   <div className="stats-in">
                                       <div className="head">
                                           <div className="icone">
                                               <img src="./assets/img/diagram.png" alt="icon"/>
                                           </div>
                                           <div className="text">
                                               BUDGET DÉPENSÉ
                                           </div>
                                       </div>
                                       <div className="content-stats">
                                           0€
                                       </div>
                                   </div>
                               </Col>
                               <Col lg={8}>
                                   <div className="stats-in">
                                       <div className="head">
                                           <div className="icone">
                                               <img src="./assets/img/diagram.png" alt="icon"/>
                                           </div>
                                           <div className="text">
                                               IMPRESSIONS
                                           </div>
                                       </div>
                                       <div className="content-stats">
                                           0€
                                       </div>
                                   </div>
                               </Col>
                               <Col lg={8}>
                                   <div className="stats-in">
                                       <div className="head">
                                           <div className="icone">
                                               <img src="./assets/img/diagram.png" alt="icon"/>
                                           </div>
                                           <div className="text">
                                               VISITES PAGE DE PRESTATION
                                           </div>
                                       </div>
                                       <div className="content-stats">
                                           0€
                                       </div>
                                   </div>
                               </Col>
                               <Col lg={8}>
                                   <div className="stats-in">
                                       <div className="head">
                                           <div className="icone">
                                               <img src="./assets/img/diagram.png" alt="icon"/>
                                           </div>
                                           <div className="text">
                                               RÉSERVATIONS GÉNÉRÉES
                                           </div>
                                       </div>
                                       <div className="content-stats">
                                           0€
                                       </div>
                                   </div>
                               </Col>
                               <Col lg={8}>
                                   <div className="stats-in">
                                       <div className="head">
                                           <div className="icone">
                                               <img src="./assets/img/diagram.png" alt="icon"/>
                                           </div>
                                           <div className="text">
                                               REVENUS LOCATIFS
                                           </div>
                                       </div>
                                       <div className="content-stats">
                                           0€
                                       </div>
                                   </div>
                               </Col>
                               <Col lg={8}>
                                   <div className="stats-in">
                                       <div className="head">
                                           <div className="icone">
                                               <img src="./assets/img/diagram.png" alt="icon"/>
                                           </div>
                                           <div className="text">
                                               ROI
                                           </div>
                                       </div>
                                       <div className="content-stats">
                                           0€
                                       </div>
                                   </div>
                               </Col>
                           </Row>
                       </div>
                        <div className="graph">
                            <Row>
                                <Col lg={15}>
                                    <div className="graph-line">
                                        <div className="head">
                                            <div className="icone">
                                                <img src="./assets/img/logon.png" alt="icon"/>
                                            </div>
                                            <div className="text">
                                                Taux d’occupation moyen <span>(6 derniers mois)</span> : <strong style={{fontWeight:"600"}}>64%</strong>
                                            </div>
                                        </div>
                                        <div className="myGraph">
                                            {graphInfo.map((element, index) => (
                                                <div className="element" key={"elementGraph"+index}>
                                                    <div className="bar">
                                                        <div className="nb">{element.percent}%</div>
                                                        <div className={element.current ? "bar-in-current" : "bar-in"} style={{height:element.percent+"%"}}></div>
                                                    </div>
                                                    <div className="month">{element.month}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={9}>
                                    <div className="graph-stats">
                                        <div className="head">
                                            <div className="icone">
                                                <img src="./assets/img/logon.png" alt="icon"/>
                                            </div>
                                            <div className="text">
                                                Meilleures campagnes (ROI)
                                            </div>
                                        </div>
                                        <div className="element">
                                            <div className="number">
                                                x9.9
                                            </div>
                                            <div className="zone">
                                                <div className="ville">
                                                    Belleville
                                                </div>
                                                <div className="nbreservation">
                                                    36 réservations
                                                </div>
                                            </div>
                                            <div className="checker">
                                                <img src="./assets/img/success-icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="element">
                                            <div className="number">
                                                x9.4
                                            </div>
                                            <div className="zone">
                                                <div className="ville">
                                                    Boulogne
                                                </div>
                                                <div className="nbreservation">
                                                    17 réservations
                                                </div>
                                            </div>
                                            <div className="checker">
                                                <img src="./assets/img/success-icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                        <div className="element">
                                            <div className="number">
                                                x5.2
                                            </div>
                                            <div className="zone">
                                                <div className="ville">
                                                    Longchamps
                                                </div>
                                                <div className="nbreservation">
                                                    24 réservations
                                                </div>
                                            </div>
                                            <div className="checker">
                                                <img src="./assets/img/success-icon.png" alt="icon"/>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col lg={2}/>
                <Col lg={6}>
                    <div className="rigthBox">
                        <div className="title">
                            Logements <span>(Taux d'occupation)</span>
                        </div>
                        <div className="sub">
                            Vos logements connectés aux plateformes de réservation par API
                        </div>
                        <div className="box-info">
                            <div className="element">
                                <div className="ville">Beleville</div>
                                <div className="percent">88%</div>
                                <div className="action">
                                    <a href="#" onClick={() => itemSelected("PubLogementModalPage")}> Gérer <IoArrowForward /></a>
                                </div>
                            </div>
                            <div className="element">
                                <div className="ville">Maribeau</div>
                                <div className="percent">46%</div>
                                <div className="action">
                                    <Button type={"primary"} onClick={() => itemSelected("PubLogementModalPage")}>
                                        Lancer une campagne
                                    </Button>
                                </div>
                            </div>
                            <div className="element">
                                <div className="ville">Pasteur</div>
                                <div className="percent">92%</div>
                                <div className="action">
                                    <Button type={"primary"} onClick={() => itemSelected("PubLogementModalPage")}>
                                        Lancer une campagne
                                    </Button>
                                </div>
                            </div>
                            <div className="element">
                                <div className="ville">Hermine</div>
                                <div className="percent">76%</div>
                                <div className="action">
                                    <a href="#" onClick={() => itemSelected("PubLogementModalPage")}> Gérer <IoArrowForward /></a>
                                </div>
                            </div>
                            <div className="element">
                                <div className="ville">Longchamps</div>
                                <div className="percent">84%</div>
                                <div className="action">
                                    <Button type={"primary"} onClick={() => itemSelected("PubLogementModalPage")}>
                                        Lancer une campagne
                                    </Button>
                                </div>
                            </div>
                            <div className="element">
                                <div className="ville">Boulogne</div>
                                <div className="percent">88%</div>
                                <div className="action">
                                    <Button type={"primary"} onClick={() => itemSelected("PubLogementModalPage")}>
                                        Lancer une campagne
                                    </Button>
                                </div>
                            </div>
                            <div className="element">
                                <div className="ville">Chatelet</div>
                                <div className="percent">46%</div>
                                <div className="action">
                                    <a href="#" onClick={() => itemSelected("PubLogementModalPage")}> Gérer <IoArrowForward /></a>
                                </div>
                            </div>
                            <div className="element">
                                <div className="ville">Malaabo</div>
                                <div className="percent">27%</div>
                                <div className="action">
                                    <Button type={"primary"} onClick={() => itemSelected("PubLogementModalPage")}>
                                        Lancer une campagne
                                    </Button>
                                </div>
                            </div>

                            <div className="element">
                                <div className="ville">Trône</div>
                                <div className="percent">35%</div>
                                <div className="action">
                                    <Button type={"primary"} onClick={() => itemSelected("DashboardPubPage")}>
                                        Lancer une campagne
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
        </>
    );
}

export default DashboardPubPage;