import React, { useState } from "react";
import { Modal, Divider, Row, Col } from "antd";

const DemanderAssignationLogementModalView = ({ status, handleCancel }) => {
    const [mydate, setMysate] = useState(null);

    const handleValid = () => {
        handleCancel();
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionLogementNotPossible"
        >

            <div className="supprimer">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/launch-icon.png" alt="airbnb" className="title-img" /><div className="title-label">Assignation de logement(s)

                </div></div>
                <Row>
                    <Col>
                        <span>
                            Vous êtes sur le point de confier la gestion du ménage de ce(s) logement(s) à <br />
                            un nouveau prestataire. Si vous confirmez il sera notifié, avec prise d’effet <br />
                            immédiat.
                        </span>
                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider />
                    <Row>
                        <Col lg={14} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Annuler la demande</span></a>
                        </Col>
                        <Col lg={10} className="button-right">
                            <a href="#" onClick={handleValid}
                                className="suivant sans-pro-regular">Confirmer</a>
                        </Col>
                    </Row>
                </div>
            </div>

        </Modal>
    );
};
export default DemanderAssignationLogementModalView;
