import { createSlice } from "@reduxjs/toolkit";

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    isEligible: false
  },
  reducers: {
    setEvaluation: (state, { payload }) => {
      state.isEligible = payload.isEligible;
      return state;
    },
  },
});

export const { setEvaluation } = dashboardSlice.actions;
export default dashboardSlice.reducer;
