import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { usePrestationUpdateMutation } from "../services/planning-api";
import { setRefresh } from "../services/planning-slice";


const PrestationCancel = ({ handleCancel, prestationId }) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const [prestationUpdate] = usePrestationUpdateMutation();


    const handleSave = () => {
        setLoading(true)
        prestationUpdate({ id: prestationId, data: { status: "Annulé" } })
            .unwrap()
            .then(() => {
                dispatch(setRefresh(true));
                setLoading(false);
                handleCancel()
            })
            .catch(() => setLoading(false));
    }



    return (
        <div className="annuler-prestation">
            <div className="head">
                <div className="title">Annuler la prestation</div>
            </div>
            <div className="main-image">
                <img src="./assets/img/red-caution.png" alt="caution" />
            </div>
            <div className="description">
                Cette opération est irréversible. La prestation sera retirée du planning de l’intervenant et des frais d’annulation peuvent s’appliquer en cas d’annulation tardive.
            </div>
            <Button className="annulation-button" onClick={() => handleSave()} loading={loading}>Annuler la prestation</Button>
        </div>
    );
};
export default PrestationCancel;
