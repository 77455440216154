import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover
} from "antd";
import {SearchOutlined} from "@ant-design/icons";

const PartagerModalView = ({status, handleCancel}) => {

    const handleValid = () => {
        handleCancel();
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={true}
            style={{top: 410, left:546}}
            onCancel={() => {
                handleCancel();
            }}
            width={350}
            className="partagerModal"
        >

            <div className="partager">
                <div className="title sans-pro-semi-bold">
                    <div className="title-label">Partagez votre lien de parrainage</div>
                </div>

                <div className="element">
                    Copier le lien
                    <div className="icone">
                        <img src="./assets/img/copy.png" alt="icone"/>
                    </div>
                </div>
                <Divider />
                <div className="element">
                    E-mail
                    <div className="icone">
                        <img src="./assets/img/mail.png" alt="icone"/>
                    </div>
                </div>
                <Divider />
                <div className="element">
                    Gmail
                    <div className="icone">
                        <img src="./assets/img/gmail.png" alt="icone"/>
                    </div>
                </div>
                <Divider />
                <div className="element">
                   Outlook/Hotmail/Live
                    <div className="icone">
                        <img src="./assets/img/outlook.png" alt="icone"/>
                    </div>
                </div>
                <Divider />
                <div className="element">
                    Yahoo
                    <div className="icone">
                        <img src="./assets/img/yahoo.png" alt="icone"/>
                    </div>
                </div>
                <Divider />
                <div className="element">
                    Facebook
                    <div className="icone">
                        <img src="./assets/img/facebook icon.png" alt="icone"/>
                    </div>
                </div>
                <Divider />
                <div className="element">
                    Whatsapp
                    <div className="icone">
                        <img src="./assets/img/whatsapp.png" alt="icone"/>
                    </div>
                </div>
                <Divider />
                <div className="element">
                    Messenger
                    <div className="icone">
                        <img src="./assets/img/messenger.png" alt="icone"/>
                    </div>
                </div>
            </div>

        </Modal>
    );
};
export default PartagerModalView;
