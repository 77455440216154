import React, { useState } from "react";
import { Modal, Divider, Row, Col, Spin } from "antd";

const SupprimerCompteModalView = ({ status, handleCancel }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionLogement">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/warning-icon.png" alt="airbnb" className="title-img" /><div className="title-label">Êtes-vous sûr de vouloir supprimer votre compte ?
                    </div></div>
                    <Row>
                        <Col>
                            <span>
                                La suppression de votre compte est une opération irréversible. Vos données <br/>
                                personnelles seront effacées et vous perdrez accès à tout historique.
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Non, finalement je reste</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => null /*handleCancel()*/}
                                   className="suivant sans-pro-regular disable-Button">Oui, supprimer définitivement</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default SupprimerCompteModalView;
