import React from 'react';
import { Row, Col, Divider } from "antd";


function TeamPendingView() {
    return (
        <div className="blc-right equipe-de-menage">
            <div className="title">Équipe en charge de ce logement</div>
            <Divider />
            <Row gutter={24}>
                <Col lg={16}>
                    <div className="subTitle">Responsable de site</div>
                    <Row>
                        <Col lg={16}>
                            <p className="calibri-light color-566F8F">Le Responsable de site pilote l’équipe d’aides ménagères qui entretient le logement confié et il
                                veille à la bonne exécution des prestations. Il peut se rendre disponible à votre demande pour visiter le logement ou échanger vos exigences précises en termes de qualité.</p>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <div className="boutton-demande">
                                <button className="add-team-disabled sans-pro-regular" disabled={true}> Demander une nouvelle équipe </button>
                            </div>
                        </Col>
                    </Row>
                    <div className="blc-content">
                        <Row gutter={24}>
                            <Col lg={24} className="done">
                                <div className="icone-done">
                                    <img src="/assets/img/done.png" alt="done" style={{width:"55px"}}/>
                                </div>
                                <div className="text-done">
                                    <div className="title">MERCI. VOTRE DEMANDE D’ÉQUIPE EST EN COURS DE TRAITEMENT...</div>
                                    <div className="subtitle calibri-light">Nous vous préviendrons dès que l’équipe sera prête.</div>
                                </div>
                                <br/><br/> <br/>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24}>
                                <div className="steps">
                                    <div className="step1">
                                        <div className="circle">
                                            <div className="circle-objet active">
                                                <img src="/assets/img/check-green.png" alt="done"/>
                                            </div>
                                            <div className="line-object line-active"></div>
                                        </div>
                                    </div>
                                    <div className="step2">
                                        <div className="circle">
                                            <div className="circle-objet desactive">
                                                <img src="/assets/img/Checked-grey.png" alt="done"/>
                                            </div>
                                            <div className="line-object line-desactive"></div>
                                        </div>
                                    </div>
                                    <div className="step2">
                                        <div className="circle">
                                            <div className="circle-objet desactive">
                                                <img src="/assets/img/Checked-grey.png" alt="done"/>
                                            </div>
                                            <div className="line-object line-desactive"></div>
                                        </div>
                                    </div>
                                    <div className="step2">
                                        <div className="circle">
                                            <div className="circle-objet desactive">
                                                <img src="/assets/img/Checked-grey.png" alt="done"/>
                                            </div>
                                            <div className="line-object line-desactive"></div>
                                        </div>
                                    </div>
                                    <div className="step3">
                                        <div className="circle">
                                            <div className="circle-objet desactive">
                                                <img src="/assets/img/Checked-grey.png" alt="done"/>
                                            </div>
                                            <div className="line-object line-desactive"></div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={5}>
                                <div className="step-text sans-pro-semi-bold color-566F8F"> <div className="step-text-title step-text-title-1">demande <br/> reçue</div></div>
                            </Col>
                            <Col lg={5}>
                                <div className="step-text sans-pro-semi-bold color-566F8F"><div className="step-text-title step-text-title-2">envoyé à <br/> notre réseau</div> </div>
                            </Col>
                            <Col lg={5}>
                                <div className="step-text sans-pro-semi-bold color-566F8F"><div className="step-text-title step-text-title-3">équipe <br/> trouvée </div></div>
                            </Col>
                            <Col lg={5}>
                                <div className="step-text sans-pro-semi-bold color-566F8F"><div className="step-text-title step-text-title-4">formation de <br/> l'aide ménagère </div> </div>
                            </Col>
                            <Col lg={4}>
                                <div className="step-text sans-pro-semi-bold color-566F8F"><div className="step-text-title step-text-title-5">organisation <br/> logistique </div> </div>
                            </Col>
                        </Row>
                    </div>
                    <br/>
                    <div className="subTitle">Aide-ménagère principale</div>
                    <p className="calibri-light color-566F8F">C’est l’aide-ménagère qui est dédiée à l’entretien de votre logement.</p>
                    <br />
                    <div className="blc-content-simple">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={24}>
                                            <div className="aidem-content">
                                                <img src="/assets/img/user-empty.png" alt="airbnb" className="aidem-img"/>
                                            </div>
                                            <div className="aidem-label">Aucune</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br/>
                    <div className="subTitle">Aide-ménagères remplaçantes</div>
                    <p className="calibri-light color-566F8F">Ce sont les aides ménagères qui sont déjà intervenues sur ce logement en remplacement de l’aide-ménagère <br/> principale. En cas de nouvel empêchement de l’intervenant principal, elles seront sollicitées en priorité.</p>
                    <br />
                    <div className="blc-content-simple">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={24}>
                                            <div className="aidem-content">
                                                <img src="/assets/img/user-empty.png" alt="airbnb" className="aidem-img"/>
                                            </div>
                                            <div className="aidem-label">Aucune</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default TeamPendingView;