import React, { useState } from "react";
import {Modal, Divider, Row, Col, Spin, message} from "antd";
import { useEquipeMenageCreateMutation } from "../services/logement-api";

const DemanderEquipeLogementModalView = ({ status, logementIds, nbLogement, handleCancel }) => {
    const [isLoading, setIsLoading] = useState(false);

    const [equipeCreate] = useEquipeMenageCreateMutation();

    const handleDemandeEquipe = () => {
        setIsLoading(true)
        equipeCreate({ logement_ids: logementIds, start_date: "" }).unwrap()
            .then(() => { handleCancel(); setIsLoading(false) ; message.success("Demande envoyée.")})
            .catch(() => setIsLoading(false));
    }

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionLogementNotPossible">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/launch-icon.png" alt="airbnb" className="title-img" />
                        <div className="title-label">Demander une équipe de ménage sur ces logements ({nbLogement})</div>
                    </div>
                    <Row>
                        <Col>
                            <span className="sans-pro-regular">
                                Tous les logements qui ne sont pas entièrement complétés ou qui ont déjà une équipe <br />
                                de ménage en place (soit tous ceux qui n’ont pas un statut ‘orange’) seront <br />
                                automatiquement éliminés de la sélection lors de la création des demandes d’équipe.
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={14} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={10} className="button-right">
                                <a href="#" onClick={() => handleDemandeEquipe()}
                                    className="suivant sans-pro-regular">Lancer la demande</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default DemanderEquipeLogementModalView;
