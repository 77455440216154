import React, { useState, useEffect } from 'react';
import { Row, Col, Divider, Spin, message } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useProductsFetchQuery } from '../../services/product-api';
import { useSelector, useDispatch } from "react-redux";
import { API_ROOT, tarifKitAccueil } from '../../../../utils/global-var';
import { useCartCreateMutation, useCartDecreaseMutation, useCartFetchAmenities2Mutation, useCoutDureeUpdateMutation } from '../../services/logement-api';
import { setRefresh } from '../../services/logement-slice';


function KitAccueilView({ logement }) {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const products = useProductsFetchQuery();
    const { user } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [cartCreate] = useCartCreateMutation();
    const [cartDecrease] = useCartDecreaseMutation();
    const [coutDureeUpdate] = useCoutDureeUpdateMutation();
    const [cartFetchAmenities2] = useCartFetchAmenities2Mutation();
    const [cart, setCart] = useState([]);

    const handleFetchCart = () => {
        cartFetchAmenities2({ id: logement?.id })
            .unwrap()
            .then((res) => { setCart(res); handleUpdateCout(res); })
            .catch(() => setIsLoading(false));
    };


    useEffect(() => {
        handleFetchCart();
    }, []);

    const handleAddToCart = (item) => {
        setIsLoading(true);
        const data = {
            prix: item.prix,
            type: "amenitie",
            quantite: 1,
            logementId: logement.id,
            produitId: item.id,
            userId: user.id,
            profil: user.profil
        };
        cartCreate(data)
            .unwrap()
            .then(() => {
                handleFetchCart()
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => setIsLoading(false));
    };

    const handleDecreaseToCart = (item) => {
        setIsLoading(true);
        const data = {
            logementId: logement.id,
            produitId: item.id,
            userId: user.id,
            type: "decrease"
        };
        cartDecrease(data)
            .unwrap()
            .then(() => {
                handleFetchCart()
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => setIsLoading(false));
    };

    const handleUpdateCout = (res) => {
        const data = {
            logementId: logement.id,
            cout_kit_accueil: tarifKitAccueil(res),
            userId: user.id,
            type: "kit accueil"
        };
        coutDureeUpdate(data)
            .unwrap()
            .then(() => {
                setIsLoading(false);
                dispatch(setRefresh(true));
            })
            .catch(() => setIsLoading(false));
    };


    const getQuantity = (item) => {
        if (!item)
            return 0;
        if (cart) {
            const items = cart.filter(x => x.produit.id === item.id);
            if (items.length > 0) {
                return items[0].quantite.toString();
            }
        }
        return 0;
    }



    return (
        <div className="kit-accueil blc-right">
            {contextHolder}
            <Spin spinning={isLoading}>
                <div className="title">Kit d’accueil</div>
                <Divider />
                <Row gutter={24}>
                    <Col lg={14}>
                        <div className="subTitle">Produits pour le confort des voyageurs</div>
                        <p className="color-566F8F calibri-light">Les kits sélectionnés sont systématiquement déposés dans les logements à chaque prestation de ménage.</p>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={14}>
                        <div className="blc-content">
                            <Row gutter={24}>
                                {products.isFetching ? (
                                    <div className="loader">
                                        <Spin tip="Loading..." />
                                    </div>
                                ) : products?.data?.length === 0 ? (
                                    <div className="no-products">
                                    </div>
                                ) : (
                                    <div>
                                        {products.data.map((item, index) => (
                                            <Col lg={24} key={index}>
                                                <div className="contour-item">
                                                    <Row gutter={24}>
                                                        <Col lg={19}>
                                                            <Row>
                                                                <Col lg={5}>
                                                                    <div className="kit-accueil-image-zone">
                                                                        <img
                                                                            src={`${API_ROOT}/${item.image}`}
                                                                            className="kit-accueil-image"
                                                                            alt={item.produit}
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={18} className="description">
                                                                    <p className="sans-pro-semi-bold color-213856">
                                                                        {item.produit} ({item.prix}€)
                                                                    </p>
                                                                    <p className="color-5B6E8C sans-pro-light">{item.description} </p>
                                                                </Col>
                                                            </Row>
                                                        </Col>

                                                        <Col lg={5} className="quantity-controls">
                                                            <span className={"moins " + (parseInt(getQuantity(item)) === 0 ? " disable-Button" : "")} onClick={() => handleDecreaseToCart(item)}>
                                                                <MinusOutlined />
                                                            </span>
                                                            <div className={"nombreDefault st_" + index}>
                                                                <span className='quantity'>{getQuantity(item)}</span>
                                                            </div>
                                                            <span className="plus" onClick={() => handleAddToCart(item)}>
                                                                <PlusOutlined />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                {
                                                    (products?.data?.length !== index + 1) && (<Divider />)
                                                }
                                            </Col>
                                        ))}
                                    </div>
                                )}
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}

export default KitAccueilView;