import React, { useState } from "react";
import { Button, Form, Input, Alert } from "antd";
import { Link } from "react-router-dom";
import { useAuthResetPasswordMutation } from "../services/auth-api";
import { GrPowerCycle } from "react-icons/gr";
import "../styles/style.scss";

export function SendResetPasswordPage() {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [authResetPassword] = useAuthResetPasswordMutation();
    const [email, setEmail] = useState();
    const [success, setSuccess] = useState(false);

    const onFinish = async (values) => {
        setEmail(values.email)
        setIsLoading(true);
        authResetPassword(values)
            .unwrap()
            .then((res) => {
                if (res?.status === 400) {
                    setIsLoading(false);
                    setError(res.message);
                } else {
                    form.resetFields();
                    setIsLoading(false);
                    setSuccess(true)
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });
    };


    const handleResend = async () => {
        setIsLoading(true);
        authResetPassword({ 'email': email })
            .unwrap()
            .then((res) => {
                if (res?.status === 400) {
                    setIsLoading(false);
                    setError(res.message);
                } else {
                    form.resetFields();
                    setIsLoading(false);
                }
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.message);
            });
    };

    return (
        <div className="auth" data-aos="fade-in">
            <div className="login-page">
                <a href="https://kliner.com">
                    <img src="/assets/img/logo-black-small.png" alt="kliner" className="login-logo-topleft" />
                </a>
                {
                    success ?
                        <div className="blc-content">
                            <h1>Email envoyé !</h1>
                            <p>Suivez les instructions reçues par mail pour définir un nouveau mot de passe. Toujours pas reçu le mail ? Veuillez vérifier vos courriers indésirables (spams).</p>
                            <p className="btn-resend-mail" onClick={() => handleResend()}>Renvoyer le mail de réinitialisation <GrPowerCycle className="icon" /></p>
                            {error && <Alert message={error} type="error" closable />}
                            <div className="footer">
                                <center>
                                    <p><Link to={"/"}>Se connecter</Link></p>
                                </center>
                            </div>
                        </div> :
                        <div className="blc-content">
                            <h1>Nous allons vous envoyer un lien pour réinitialiser votre mot de passe.</h1>
                            <p>Saisissez l’email associé à votre compte</p>
                            <Form
                                name="basic"
                                form={form}
                                onFinish={onFinish}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <Form.Item name="email" rules={[{ required: true, message: "Champs requis!" }]}>
                                    <Input placeholder="Votre mail" />
                                </Form.Item>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn-lg"
                                        loading={isLoading}
                                        style={{ fontWeight: "600" }}
                                    >
                                        Envoyer
                                    </Button>
                                </Form.Item>
                            </Form>
                            {error && <Alert message={error} type="error" closable />}

                            <div className="footer">
                                <center>
                                    <p><Link to={"/"}>Se connecter</Link></p>
                                </center>
                            </div>
                        </div>
                }

            </div>
        </div>
    );
}

export default SendResetPasswordPage;