import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const equipeApi = createApi({
    reducerPath: "equipeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Equipe"],
    endpoints: (builder) => ({
        equipePerformanceGet: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/performance/user/entretien-equipe",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Equipe"],
        }),

        equipePayementsTips: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/payments/tips",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Equipe"],
        }),

        equipeTierceInvitation: builder.mutation({
            query: (payload) => ({
                url: "/api/v1/auth/equipe-tierce/invitation",
                method: "POST",
                body: payload,
            }),
            invalidatesTags: ["Equipe"],
        }),
    }),
});

export const {
    useEquipePayementsTipsMutation,
    useEquipePerformanceGetMutation, 
    useEquipeTierceInvitationMutation
} = equipeApi;
