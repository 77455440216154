import React, { useState, useEffect } from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Alert,
    Button,
    Space, Form
} from "antd";

import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { useUserUpdateMutation } from "../../services/profil-api";
import {setSuccess} from "../../../planning/services/planning-slice";

const ProfilModalView = ({ status, handleCancel, userInfo, addr, closeModal }) => {
    const [form] = Form.useForm();
    const [newUser, setNewUser] = useState({});
    const [newAddr, setNewAddr] = useState({});
    const [userUpdate] = useUserUpdateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (userInfo && addr) {
            form.setFieldsValue({
                firstname: userInfo.firstname,
                lastname: userInfo.lastname,
                phone: userInfo.phone,
                email: userInfo.email
            });
            setNewAddr(addr);
        }
    }, [form, addr, userInfo]);

    const userInfoUpdate = (field, value) => {
        newUser[field] = value;
    };

    const handleSubmit = () => {
        setIsLoading(true);
        userUpdate({ id: userInfo.id, data: newUser })
          .unwrap()
          .then(() => {
            // form.resetFields();
            // handleCancel();
            setIsLoading(false);
          })
          .catch(error => {
            setIsLoading(false);
            setError(error.data.message);
          });
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{ top: 250 }}
            closable={true}
            onCancel={() => {
                closeModal();
            }}
            width={750}
            className="editer-information"
        >

            <div className="informations">
                <div className="contentIfon">
                    <div className="title">
                        <div className="icone">
                            <img src="./assets/img/data-icon.png" alt="image" />
                        </div>
                        <div className="text sans-pro-semi-bold">
                            Informations personnelles
                        </div>
                    </div>
                    <Form
                        name="basic"
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                        layout="vertical"
                        className="form-edit-profil"
                    >
                        <Row>
                            <Col lg={11}>
                                <div className="input-barrier">
                                <label htmlFor="firstname" className={"force-label-effet color-5B6E8C"}>Prénom *</label>
                                <Form.Item
                                    name="firstname"
                                    className="input-barrier"
                                    rules={[{ required: true, message: "Champs requis!" }]}>
                                    <Input id="firstname" placeholder="" onChange={e => userInfoUpdate("firstname", e.target.value)} />
                                </Form.Item>
                                </div>
                            </Col>
                            <Col lg={2}></Col>
                            <Col lg={11}>
                                <div className="input-barrier">
                                    <label htmlFor="lastname" className={"force-label-effet color-5B6E8C"}>Nom *</label>
                                    <Form.Item
                                        name="lastname"
                                        className="input-barrier"
                                        rules={[{ required: true, message: "Champs requis!" }]}>

                                        <Input id="lastname" placeholder="" onChange={e => userInfoUpdate("lastname", e.target.value)} />
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col lg={11}>
                                <div className="input-barrier">
                                <label htmlFor="email" className={"force-label-effet color-5B6E8C"}>Email *</label>
                                <Form.Item
                                    name="email"
                                    className="input-barrier"
                                    rules={[{ required: true, message: "Champs requis!" }]}>

                                    <Input id="email" placeholder="" disabled />
                                </Form.Item>
                                </div>
                            </Col>
                            <Col lg={2}></Col>
                            <Col lg={11}>
                                <div className="input-barrier-number">
                                <label htmlFor="phone" className={"force-label-effet color-5B6E8C"}>Téléphone (mobile) *</label>
                                <Form.Item
                                    name="phone"
                                    className="input-barrier-number"
                                    rules={[{ required: true, message: "Champs requis!" }]}>

                                    <PhoneInput
                                        flags={flags}
                                        defaultCountry="FR"
                                        disabled={true}
                                    />
                                </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>

                <div className="footer-modal">
                    <Divider />
                    <Row>
                        <Col lg={17} className="annuler">
                            <a href="#" onClick={() => closeModal()} className="annuler"><span> Annuler</span></a>
                        </Col>
                        <Col lg={7} className="button-right">
                            <Button type="primary" onClick={() => handleSubmit()} loading={isLoading} className="suivant sans-pro-regular">
                                Mettre à jour
                            </Button>
                        </Col>
                    </Row>
                </div>

                {/* <div className="footer-modal">
                    <Divider />
                    {error && <Alert message={error} type="error" closable />}
                    <br />
                    <Space style={{ display: "flex", justifyContent: "end" }}>
                        <Button onClick={() => closeModal()}>Annuler</Button>
                        <Button type="primary" onClick={() => handleSubmit()} loading={isLoading}>
                            Mettre à jour
                        </Button>
                    </Space>
                </div> */}
            </div>


        </Modal>
    );
};
export default ProfilModalView;
