import React, { useState } from "react";
import { Button, Form, Input, Alert, Checkbox } from "antd";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { setToken } from "../services/auth-slice";
import { useAuthloginMutation } from "../services/auth-api";
import { saveTokenState } from "../../../utils/local-storage";
import "../styles/style.scss";

export function LoginPage() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [authlogin] = useAuthloginMutation();
    // const [authFetchToken] = useAuthFetchTokenMutation();

    const onFinish = async (values) => {
        setIsLoading(true);
        authlogin({ ...values, platform: "mobile customer" })
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                saveTokenState(res.access_token, res.refresh_token);
                dispatch(setToken(res.access_token));
                 sessionStorage.setItem('showNews', true);
                navigate("/dashboard");
                // authFetchToken()
                //     .unwrap()
                //     .then((res) => {
                //         setIsLoading(false);
                //         dispatch(setUser(res));
                //         navigate("/dashboard");
                //     })
                //     .catch((error) => {
                //         setError(error.data.message);
                //         setIsLoading(false);
                //     });
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    };

    return (
        <div className="auth" data-aos="fade-in">
            <div className="login-page">
                <a href="https://kliner.com">
                    <img src="/assets/img/logo-black-small.png" alt="kliner" className="login-logo-topleft" />
                </a>                <div className="blc-content">
                    <h1>Connectez-vous</h1>
                    <p>Rentrez vos identifiants pour accéder à votre espace.</p>
                    <Form
                        name="basic"
                        form={form}
                        onFinish={onFinish}
                        autoComplete="off"
                        layout="vertical">
                        <label>E-mail associé au compte</label>
                        <Form.Item name="email" rules={[{ required: true, message: "Champs requis!" }]}>
                            <Input placeholder="Votre mail" />
                        </Form.Item>
                        <label>Mot de passe</label>
                        <Form.Item name="password" rules={[{ required: true, message: "Champs requis!" }]}>
                            <Input.Password placeholder="Saisir votre mot de passe" />
                        </Form.Item>
                        <Form.Item name="remember" valuePropName="checked">
                            <Checkbox>Rester connecté(e)</Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="btn-lg"
                                loading={isLoading}
                                style={{ fontWeight: "600" }}
                            >
                                Accéder à votre espace Kliner
                            </Button>
                        </Form.Item>
                    </Form>
                    {error && <Alert message={error} type="error" closable />}

                    <div className="footer">
                        <center>
                            <p><Link to={"/send-reset-password"}>Mot de passe oublié</Link></p>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginPage;
