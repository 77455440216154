import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const mediaprestationApi = createApi({
    reducerPath: "mediaprestationApi",
    baseQuery: fetchBaseQuery({
        baseUrl: API_ROOT,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth.token;
            headers.set("Authorization", `Bearer ${token}`);
            return headers;
        },
    }),
    tagTypes: ["Mediaprestation"],
    endpoints: (builder) => ({
        mediaprestationFetch: builder.query({
            query: (payload) => `api/v1/prestation/stats-by-media?start_date=${payload.start}&end_date=${payload.end}`,
            providesTags: ["Mediaprestation"],
        }),

        mediaprestationFetchOne: builder.query({
            query: (start) => `/api/v1/prestation/stats-by-media/detail?start_date=${start}`,
            providesTags: ["Mediaprestation"],
        }),
    }),
});

export const {
    useMediaprestationFetchQuery,
    useMediaprestationFetchOneQuery,
} = mediaprestationApi;
