import React, { useState } from "react";
import { Modal, Divider, Row, Col, Spin } from "antd";

const SuspendreModalView = ({ status, handleCancel, unpaidInvoices }) => {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 230 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suspendreLogement">

            <Spin spinning={isLoading}>
                <div className="suspendre">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/warning-icon.png" alt="airbnb" className="title-img" /><div className="title-label">Votre compte a été suspendu
                    </div></div>
                    <Row>
                        <Col>
                            <p>
                                En raison de factures impayées malgré nos relances, l’accès à votre compte a été bloqué et nous avons suspendu nos services sur vos logements (les prestations programmées ont été annulées).
                            </p>
                            <p>
                                Veuillez régulariser toute facture en attente de règlement pour réactiver nos services (les prestations à venir à compter de la date de régularisation seront automatiquement reprogrammées).
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24}>
                            <div className="aregler">
                                <div className="titre">À RÉGLER</div>
                                <ul>

                                    {
                                        unpaidInvoices && unpaidInvoices.map(invoice=>(
                                            <li>
                                            <div className="facture">Facture N° {invoice.reference} </div> |
                                            <div className="montant"> {invoice.amount_due} €</div>
                                            <div className="link">
                                                (<a href={invoice.pay_url}>régler la facture</a>)
                                            </div>
                                        </li>
                                        ))
                                    }
                                    {/* <li>
                                        <div className="facture">Facture N° 0645256-123 </div> |
                                        <div className="montant"> 2 355.00€</div>
                                        <div className="link">
                                            (<a href="#">régler la facture</a>)
                                        </div>
                                    </li>
                                    <li>
                                        <div className="facture">Facture N° 0645256-123 </div> |
                                        <div className="montant">48.53€</div>
                                        <div className="link">
                                            (<a href="#">régler la facture</a>)
                                        </div>
                                    </li> */}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={4} className="button-left">

                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => handleCancel()}
                                   className="suivant sans-pro-regular">Vos factures sont réglées ?</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default SuspendreModalView;
