import React, { useState } from "react";
import { Modal, Divider, Row, Col, Spin } from "antd";
import { useDispatch } from "react-redux";

const BloquerProfilModalView = ({ status, handleCancel }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = () => {
        handleCancel();
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => handleCancel()}
            width={600}
            className="suppressionLogement">

            <Spin spinning={isLoading}>
                <div className="supprimer">
                    <div className="title sans-pro-semi-bold"><img src="/assets/img/warning-icon.png" alt="airbnb" className="title-img" /><div className="title-label">Êtes-vous sûr de vouloir bloquer ce responsable de site ?
                    </div></div>
                    <Row>
                        <Col>
                            <span>
                                Si vous bloquez ce responsable de site, il ne pourra plus être assigné sur des logements <br/>
                                de votre parc. Il restera toutefois actif sur les logements où il est déjà assigné.
                            </span>
                        </Col>
                    </Row>
                    <div className="footer-modal">
                        <Divider />
                        <Row>
                            <Col lg={12} className="button-left">
                                <a href="#" onClick={() => {
                                    handleCancel();
                                }} className="annuler"><span> Annuler la demande</span></a>
                            </Col>
                            <Col lg={11} className="button-right">
                                <a href="#" onClick={() => handleDelete()}
                                   className="suivant sans-pro-regular">Oui, bloquer ce profil</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default BloquerProfilModalView;
