import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover
} from "antd";
import {SearchOutlined} from "@ant-design/icons";

const PourboireConfirmationModalView = ({status, handleCancel}) => {
    const [mydate, setMysate] = useState(null);

    const handleValid = () => {
        handleCancel();
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => {
                handleCancel();
            }}
            width={600}
            className="suppressionLogementNotPossible"
        >

            <div className="supprimer">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/launch-icon.png" alt="airbnb" className="title-img"/><div className="title-label">Versement de pourboire

                </div></div>
                <Row>
                    <Col>
                        <span>
                           Vous êtes sur le point de verser un pourboire de 100€ à ce prestataire. Si vous <br/>
                            confirmez, votre moyen de paiement sera immédiatement débité et il recevra <br/>
                            le montant indiqué déduit des frais bancaires de traitement (3%).
                        </span>
                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider/>
                    <Row>
                        <Col lg={14} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Annuler la demande</span></a>
                        </Col>
                        <Col lg={10} className="button-right">
                            <a href="#" onClick={handleValid}
                               className="suivant sans-pro-regular">Confirmer</a>
                        </Col>
                    </Row>
                </div>
            </div>

        </Modal>
    );
};
export default PourboireConfirmationModalView;
