import React, { useState, useEffect } from "react";
import { Row, Col, Image } from "antd";
import { useParams } from "react-router-dom";
import { usePrestationCatalogFetchMutation } from "../services/planning-api";
import {API_ROOT, convertDateStringToTimeStringComplete} from "../../../utils/global-var";
import moment from "moment";
moment.locale("fr");

export function Catalog({ libelle }) {
    const { id, status } = useParams();
    const [prestationCatalogFetch] = usePrestationCatalogFetchMutation();
    const [catalog, setCatalog] = useState([]);

    useEffect(() => {
        prestationCatalogFetch({
            libelle: libelle,
            prestationId: id,
            status: status,
        })
            .unwrap()
            .then((res) => {
                setCatalog(res);
            });
        // eslint-disable-next-line
    }, [id, status]);

    return (
        <div className="catalog">
            <div className="catalog-title">{libelle}</div>
            <Row gutter={24}>
                {catalog.map((item, index) => (
                    <Col lg={4}>
                        <div className="catalog-content">
                            <Image src={`${API_ROOT}/${item.image}`} index={index} />
                            <div className="date">{convertDateStringToTimeStringComplete(item.createdAt)}</div>
                        </div>
                    </Col>
                ))}
            </Row>
        </div>
    );
}
export default Catalog;
