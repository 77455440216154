import React, { useState } from 'react';
import { Col, Flex, Layout, Row, Space, Form, Input, Avatar } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import { API_ROOT, countryCodesMap } from '../../../utils/global-var';
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import { UserOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import "../styles/style.scss"

const { Header } = Layout;

function ContacterPartenairePage() {
    const { user } = useSelector((state) => state.auth);
    const { state } = useLocation();
    const [form] = Form.useForm();
    const [phone, setPhone] = useState('');
    const [firstname, setFirstname] = useState("");
    const [countryCode, setCountryCode] = useState(countryCodesMap['FR']);
    const navigate = useNavigate();

    const handleCountryChange = (value) => {
        setCountryCode(countryCodesMap[value]);
    };


    const handleNext = () => {
        navigate("/remuneration-partenaire", {
            state: { ...state, phone: phone, name: firstname },
        });
    };


    const handleInputChange = (event) => {
        setFirstname(event.target.value);
    }

    return (
        <div className='addtierce-page'>
            <Header>
                <Flex justify={"space-between"}>
                    <Col lg={23}>
                        <Row>
                            <Col lg={11}>
                                <div className="titre sans-pro-semi-bold">
                                    Intégration d'une équipe tierce à Kliner
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Space className="icons-head">
                            <NavLink to={"/equipes-entretien"}>
                                <CloseOutlined style={{ fontSize: "30px", marginRight: "10px", color: "grey" }} />
                            </NavLink>
                        </Space>
                    </Col>
                </Flex>
            </Header>
            <div className="context">
                <Row>
                    <Col lg={6} />
                    <Col lg={14}>
                        <div className="content-in">
                            <div className="head">
                                <div className="image">
                                    <img src={`${API_ROOT}/${user.avatar}`} alt=''></img>
                                    <Avatar src={`${API_ROOT}/${user.avatar}`} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} />
                                </div>
                                <div className="name">{user.firstname}</div>
                            </div>
                            <div className="titlePage sans-pro-bold">
                                Comment pouvons-nous contacter votre <br /> prestataire ?
                            </div>
                            <div className="subTitle sans-pro-regular">
                                Nous utiliserons ces informations pour l’inviter à vous retrouver sur Kliner.
                            </div>
                            <div className="form-partenaire">
                                <Form
                                    name="basic"
                                    form={form}
                                    onFinish={null}
                                    autoComplete="off"
                                    layout="vertical">
                                    <Row>
                                        <Col lg={6}>
                                            <label className="sans-pro-semi-bold">Prénom</label>
                                            <Form.Item name="email" rules={[{ required: true, message: "Champs requis!" }]} className="calibri-light">
                                                <Input placeholder="Prénom" className="calibri-light"
                                                    value={firstname}
                                                    onChange={handleInputChange}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={1} />
                                        <Col lg={8}>
                                            <label className="sans-pro-semi-bold">Portable</label>
                                            <PhoneInput
                                                className="phone-input calibri-light"
                                                placeholder="0611220011"
                                                flags={flags}
                                                value={phone}
                                                onChange={setPhone}
                                                defaultCountry="FR"
                                                onCountryChange={handleCountryChange}
                                            />
                                            <div style={{ position: 'absolute', left: 105, top: '56%', transform: 'translateY(-50%)', color: "gray", borderRight: "1.5px solid #8080805c", paddingRight: "6px", width: "35px", height: "15px", verticalAlign: "top" }}>
                                                <div className="inCountry" style={{ marginTop: "-2px" }}>
                                                    {countryCode}
                                                </div>
                                            </div>
                                            <div style={{ position: 'absolute', right: 10, top: '56%', transform: 'translateY(-50%)' }}>
                                                <UserOutlined />
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} />
                </Row>
            </div>
            <div className="footer">
                <NavLink to={"/inviter-un-tierce"}>
                    <div className="button-retour sans-pro-semi-bold">Retour</div>
                </NavLink>
                <div className={"button-next sans-pro-semi-bold "+ (firstname === "" || phone === "" ? "disable-Button" : "")} onClick={() => firstname === "" || phone === "" ? null :handleNext()}><span>Suivant</span></div>
            </div>
        </div>
    );
}

export default ContacterPartenairePage;