import React, {useState, useEffect} from "react";
import {
    Modal,
    Divider,
    Row,
    Col,
} from "antd";
import { useAddPaymentMethodMutation } from "../profil/services/profil-api";

const MoyenPaiementModalView = ({status, handleCancel}) => {
    const [_addPaymentMethod] = useAddPaymentMethodMutation();

    const addPaymentMethod = () => {
        _addPaymentMethod("")
            .unwrap()
            .then((response) => {
                window.location.href = response?.session_url;
            })
        .catch(() => { });
    }

    const handleValid = () => {
        handleCancel();
    };


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{top: 400}}
            onCancel={() => {
                handleCancel();
            }}
            width={550}
            className="validPaie"
        >

            <div className="paie">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/logon.png" alt="airbnb"
                                                               className="title-img"/>
                    <div className="title-label">Un moyen de paiement est requis

                    </div>
                </div>
                <Row>
                    <Col>
                        <span>
                           Cette fonctionnalité permet la programmation automatique des prestations de ménage. <br/>
                            Vous devez ajouter un moyen de paiement afin de l’utiliser. Pour rappel, vous n’êtes <br/>
                            facturé qu’à la fin des prestations.
                        </span>
                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider/>
                    <Row>
                        <Col lg={11} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Poursuivre ma navigation</span></a>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={11} className="button-right">
                            <a href="#" onClick={addPaymentMethod}
                               className="suivant sans-pro-regular">Ajouter un moyen de paiement</a>
                        </Col>
                    </Row>
                </div>
            </div>

        </Modal>
    );
};
export default MoyenPaiementModalView;
