import React from "react";
import Catalog from "./catalog";

export function PrestationCatalog() {
    return (
        <div className="prestation-catalog">
            <div className="container">
                <Catalog libelle="Entrée & Couloirs" />
                <Catalog libelle="Salon & Cuisine" />
                <Catalog libelle="Chambres" />
                <Catalog libelle="Diverses" />
            </div>
        </div>
    );
}
export default PrestationCatalog;
