import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover
} from "antd";
import {SearchOutlined} from "@ant-design/icons";

const DemandeView = ({status, handleCancel}) => {
    const [mydate, setMysate] = useState(null);

    const handleValid = () => {
        handleCancel();
    };

    const contentInfo = (
        <div className="infoEquipe">
            <p>
                hhh
            </p>
        </div>
    );


    return (
        <Modal
            title=""
            open={status}
            footer={null}
            closable={false}
            style={{ top: 400 }}
            onCancel={() => {
                handleCancel();
            }}
            width={650}
            className="demande"
        >
            <div className="demande-cours">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/info-pop-up_colored.png" alt="airbnb" className="title-img"/><div className="title-label">Demande en cours de traitement</div></div>
                <Row>
                    <Col>
                        <span className="sans-pro-regular">
                            La demande que vous avez faite a bien été enregistrée. <br/>
                            Dès que vous remplirez les conditions et à chaque fois qu’il y aura un <br/> profil qui matche, vous recevrez une alerte par mail.
                        </span>
                    </Col>
                </Row>
                <div className="footer-modal">
                    <Divider/>
                    <Row>
                        <Col lg={9} className="button-left">
                            <a href="#" onClick={() => {
                                handleCancel();
                            }} className="annuler"><span> Supprimer la demande </span></a>
                        </Col>
                        <Col lg={1}>
                        </Col>
                        <Col lg={7} className="button-right">
                            <a href="#" onClick={handleValid}
                               className="modifier sans-pro-regular">Modifier la demande</a>
                        </Col>
                        <Col lg={6} className="button-right">
                            <a href="#" onClick={handleValid}
                               className="suivant sans-pro-regular">Fermer la fenêtre</a>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};
export default DemandeView;
