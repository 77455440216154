import React, { useState } from "react";
import { Modal, Row, Col, Alert, Space, Button, Form, Input, Select, Divider } from "antd";
import { useAuthRatingCreateMutation } from "../services/dashboard-api";

const { TextArea } = Input;
const { Option } = Select;

function Evaluation({ status, handleCancel }) {
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [ratingSelected, setRatingSelected] = useState('');
    const [comment, setComment] = useState('');
    const [authRatingCreate] = useAuthRatingCreateMutation();
    const [form] = Form.useForm();

    const handleDivClick = (index) => {
        setRatingSelected(index === ratingSelected ? null : index);
    };

    const onCancel = () => {
        handleCancel();
        form.resetFields();
    };


    const handleCommentChange = (e) => setComment(e.target.value);

    const handleSubmit = () => {
        const data = { "stars": ratingSelected, comment };
        setIsLoading(true);
        authRatingCreate(data)
            .unwrap()
            .then(() => {
                form.resetFields();
                handleCancel();
                setIsLoading(false);
                setShowSuccessModal(true)
            })
            .catch((error) => {
                setIsLoading(false);
                setError(JSON.stringify(error.data.message));
            });
    };

    return (
        <>
            <Modal
                open={status}
                footer={null}
                closable={true}
                width={700}
                onCancel={onCancel}
                className="evaluation"
            >
                <Form name="basic" autoComplete="off" layout="vertical" form={form} onFinish={handleSubmit}>
                    <div>
                        <h2>Évaluez votre expérience avec Kliner</h2>
                        <p>Sur une échelle de 1 à 10 quelle est la probabilité que vous recommandiez les
                            services de Kliner autour de vous ?</p>
                        <div className="point flex justify-content-space-between">
                            <div className={ratingSelected === 0 ? "point-value active" : "point-value"} onClick={() => handleDivClick(0)}>1</div>
                            <div className={ratingSelected === 1 ? "point-value active" : "point-value"} onClick={() => handleDivClick(1)}>2</div>
                            <div className={ratingSelected === 2 ? "point-value active" : "point-value"} onClick={() => handleDivClick(2)}>3</div>
                            <div className={ratingSelected === 3 ? "point-value active" : "point-value"} onClick={() => handleDivClick(3)}>4</div>
                            <div className={ratingSelected === 4 ? "point-value active" : "point-value"} onClick={() => handleDivClick(4)}>5</div>
                            <div className={ratingSelected === 5 ? "point-value active" : "point-value"} onClick={() => handleDivClick(5)}>6</div>
                            <div className={ratingSelected === 6 ? "point-value active" : "point-value"} onClick={() => handleDivClick(6)}>7</div>
                            <div className={ratingSelected === 7 ? "point-value active" : "point-value"} onClick={() => handleDivClick(7)}>8</div>
                            <div className={ratingSelected === 8 ? "point-value active" : "point-value"} onClick={() => handleDivClick(8)}>9</div>
                            <div className={ratingSelected === 9 ? "point-value active" : "point-value"} onClick={() => handleDivClick(9)}>10</div>
                        </div>
                        <br />
                        <div className="point flex justify-content-space-between">
                            <span>Aucune chance</span>
                            <span>Très probablement</span>
                        </div>
                        <br /><br />
                        <Divider />
                        <br />
                        <h3>Que devrions-nous améliorer ?</h3>
                        <p>Que faisons-nous de bien que vous appréciiez et de moins bien qu’il faudrait améliorer ?</p>

                        <Row>
                            <Col lg={24}>
                                <Form.Item name="description">
                                    <TextArea rows={7} placeholder="Exprimez-vous ici" onChange={handleCommentChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    {error && <Alert message={error} type="error" closable />}
                    <br />
                    <Space style={{ display: "flex", justifyContent: "space-between" }}>
                        <span className="btn-cancel" onClick={() => onCancel()}>Annuler</span>
                        <Button type="primary" className="btn-send" loading={isLoading}
                            disabled={!ratingSelected} onClick={() => handleSubmit()}>
                            Envoyer
                        </Button>
                    </Space>
                </Form>
            </Modal>

        </>
    );
}

export default Evaluation;
