import React, {useState, useEffect} from "react";
import {
    Input,
    Modal,
    Divider,
    Row,
    Col,
    Select,
    Popover, Space, Radio, Spin, message, TimePicker, Checkbox
} from "antd";
import {ArrowLeftOutlined, MinusOutlined, PlusOutlined, SearchOutlined} from "@ant-design/icons";

import {useSelector} from "react-redux";

const ChoisirModalView = ({status, handleCancel}) => {

    const timeFormat = 'HH:mm';
    const onChange = (e) => {
        console.log(`checked = ${e.target.checked}`);
    };
    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 525, left: 350}}
            closable={false}
            onCancel={() => {
                handleCancel();
            }}
            width={280}
            className="choisir-logement"
        >

            <div className="choisir">
                <div className="head">
                    <div className="arrow" onClick={handleCancel}><ArrowLeftOutlined /></div>
                    <div className="title"><div  className="title-in">Choisir un logement</div></div>
                </div>
                <br/>
                <Input
                    className='search-input'
                    placeholder="Chercher par nom de logement..."
                    prefix={<SearchOutlined className="site-form-item-icon" />}
                />
                <br/>
                <br/>
                <div className="result">
                    <div className="icone">
                        <img src="./assets/img/icon-adresse.png" alt="cone"/>
                    </div>
                    <div className="text">
                        <div className="quartier">Pasteur</div>
                        <div className="address disable">75010 Paris | 45.00€</div>
                    </div>
                    <div className="check">
                        <Checkbox onChange={onChange}></Checkbox>
                    </div>
                </div>
                <div className="result">
                    <div className="icone">
                        <img src="./assets/img/icon-adresse.png" alt="cone"/>
                    </div>
                    <div className="text">
                        <div className="quartier">Pasteur</div>
                        <div className="address disable">75010 Paris | 45.00€</div>
                    </div>
                    <div className="check">
                        <Checkbox onChange={onChange}></Checkbox>
                    </div>
                </div>
                <div className="footer-modal">

                    <Row>
                        <Col lg={24} className="button-right">
                            <a href="#" onClick={handleCancel}
                               className="suivant sans-pro-regular">Confimer</a>
                        </Col>
                    </Row>
                </div>
            </div>


        </Modal>
    );
};
export default ChoisirModalView;
