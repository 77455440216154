import React, { useState, useEffect } from "react";
import { Button, Form, Input, Alert } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useAuthChangePasswordMutation } from "../services/auth-api";
import { useUserCheckTokenMutation } from "../../profil/services/profil-api";
import "../styles/style.scss";

export function ResetPasswordPage() {
    const navigate = useNavigate();
    let token = new URLSearchParams(window.location.search).get("token");
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [tokenExpired, setTokenExpired] = useState(false);
    const [userCheckToken] = useUserCheckTokenMutation();
    const [userChangePassword] = useAuthChangePasswordMutation();
    const [user, setUser] = useState();

    const onFinish = async ({ password }) => {
        setIsLoading(true);
        userChangePassword({ id: user.id, password, email: user.email })
            .unwrap()
            .then(() => navigate("/"))
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    };

    useEffect(() => {
        userCheckToken({ token })
            .unwrap()
            .then((res) => {
                if (res.status === 400) {
                    setTokenExpired(true)
                } else {
                    setUser(res.message);
                }
            })
            .catch(() => setTokenExpired(true));
    }, [userCheckToken, token]);


    return (
        <div className="auth" data-aos="fade-in">
            <div className="login-page">
                <a href="https://kliner.com">
                    <img src="/assets/img/logo-black-small.png" alt="kliner" className="login-logo-topleft" />
                </a>
                {
                    tokenExpired ?
                        <div className="blc-content">
                            <p style={{ textAlign: "center" }}>
                                Le lien de réinitialisation du mot de passe a expiré. Veuillez
                                demander une nouvelle <br /><br />
                                <Link to={"/send-reset-password"}>réinitialisation du mot de passe. </Link>
                            </p>

                            {error && <Alert message={error} type="error" closable />}
                            <div className="footer">
                                <center>
                                    <p><Link to={"/"}>Se connecter</Link></p>
                                </center>
                            </div>
                        </div> :
                        <div className="blc-content">
                            <h1> Définir un nouveau mot de passe</h1>
                            <div>
                                <Form
                                    name="basic"
                                    form={form}
                                    onFinish={onFinish}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <label>Saisissez votre nouveau mot de passe</label>
                                    <Form.Item label="" name="password"
                                        rules={[{ required: true, message: "Champs requis!" }, {
                                            min: 8, message: "min 8 caractères"
                                        }]} hasFeedback>
                                        <Input.Password />
                                    </Form.Item>
                                    <label>Confirmez le nouveau mot de passe</label>
                                    <Form.Item
                                        name="passwordConfirm"
                                        onPaste={(e) => { e.preventDefault(); return false; }}
                                        onCopy={(e) => { e.preventDefault(); return false; }}
                                        rules={[{ required: true, message: "Champs requis!" },
                                        { min: 8, message: "min 8 caractères" },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue("password") === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                    new Error(
                                                        "Les mots de passe ne correspondent pas!"
                                                    )
                                                );
                                            },
                                        }),
                                        ]}
                                        dependencies={["password"]}
                                        hasFeedback
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="btn-lg"
                                            loading={isLoading}
                                        >
                                            Enregistrer
                                        </Button>
                                    </Form.Item>
                                    <p className="text-info">
                                        Une fois le nouveau mot de passe enregistré, vous allez
                                        être redirigé vers la page de connexion.
                                    </p>
                                </Form>
                            </div>
                            {error && <Alert message={error} type="error" closable />}

                            <div className="footer">
                                <center>
                                    <p><Link to={"/"}>Se connecter</Link></p>
                                </center>
                            </div>
                        </div>
                }

            </div>
        </div>
    );
}

export default ResetPasswordPage;