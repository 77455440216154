import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_ROOT } from "../../../utils/global-var";

export const profilApi = createApi({
  reducerPath: "profilApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),

  tagTypes: ["Profil"],
  endpoints: (builder) => ({
    addPaymentMethod: builder.mutation({
      query: (type) => ({
        url: "/api/v1/stripe/checkout-payment-method",
        method: "POST",
        body: { paymentMethodType: type },
      }),
      invalidatesTags: ["Profil"],
    }),

    getInvoices: builder.query({
      query: () => `/api/v1/stripe/customer/invoices`,
      providesTags: ["Profil"],
    }),

    getUserInfo: builder.query({
      query: (userId) => `/api/v1/user/${userId}`,
      providesTags: ["Profil"]
    }),

    getDefaultPaymentMethod: builder.query({
      query: () => `/api/v1/stripe/users/default-payment-method-details`,
      providesTags: ["Profil"],
    }),

    userUpdate: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/user/${payload.id}`,
        method: "PATCH",
        body: payload.data,
      }),
      invalidatesTags: ["Profil"],
    }),

    userCheckToken: builder.mutation({
      query: (payload) => ({
        url: `/api/v1/user/check-token`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Profil"],
    }),

    getAccountStatus: builder.query({
      query: (userId) => `/api/v1/user/${userId}/account-status`,
      providesTags: ["Profil"],
    }),
  }),
});

export const {
  useAddPaymentMethodMutation,
  useGetDefaultPaymentMethodQuery,
  useGetInvoicesQuery,
  useGetUserInfoQuery,
  useUserUpdateMutation,
  useUserCheckTokenMutation,
  useGetAccountStatusQuery
} = profilApi;
