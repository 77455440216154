import React, { useState } from 'react';
import { Button } from "antd";
import SupprimerCompteModalView from "./modal";
import "../styles/style.scss"


function CloturePartPage() {
    const [showModalSupprimer, setShowModalSupprimer] = useState(false);

    return (
        <div className="profil-part-page">
            <h2 className="sans-pro-semi-bold">Fermeture de compte</h2>
            <div className="headprofil fermeture-compte">
                <div className="icone">
                    <img src="./assets/img/no-card-icon.png" alt="" />
                </div>
                <div className="text">
                    <div className="label sans-pro-regular">Suppression de compte</div>
                    <div className="indication sans-pro-regular">Danger - Irréversible</div>
                </div>
                <div className="button">
                    <Button className="button-supprimer" onClick={() => setShowModalSupprimer(true)}>Supprimer le compte</Button>
                </div>
            </div>

            <SupprimerCompteModalView
                status={showModalSupprimer}
                handleCancel={() => setShowModalSupprimer(false)}
            />
        </div>
    );
}

export default CloturePartPage;
