import React, { useState } from 'react';
import { Col, Flex, Layout, Row, Space, Form, Input, Popover, AutoComplete, Avatar, Spin } from "antd";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { API_ROOT } from '../../../utils/global-var';
import ChoisirForfaitView from '../drawer/tabs/modal/forfait';
import { useLogementFetchOne2Mutation, useLogementWithPerformanceMutation } from '../../logements/services/logement-api';
import { renameLogement } from '../../logements/utils/logement-utils';
import { useEquipeTierceInvitationMutation } from '../services/equipe-api';
import "../styles/style.scss"

const { Header } = Layout;

function RemunerationPartenairePage() {
    const { user } = useSelector((state) => state.auth);
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [logementFetchOne2] = useLogementFetchOne2Mutation();
    const [loading, setLoading] = useState(false);
    const [logements, setLogements] = useState([]);
    const [logement, setLogement] = useState();
    const [logementWithPerformance] = useLogementWithPerformanceMutation();
    const [equipeTierceInvitation] = useEquipeTierceInvitationMutation()
    const [showModal2, setShowModal2] = useState(false);
    const navigate = useNavigate();


    const contentInfo = (
        <div className="infoRemu">
            <p>
                Frais indiqués pour une prestation <br />
                de ménage classique. Le forfait est <br />
                automatiquement doublé pour <br />
                un ménage en profondeur.
            </p>
        </div>
    );


    const handleSearch = (value) => {
        const data = ({ search: value })
        logementWithPerformance(data).unwrap()
            .then((res) => {
                setLogements(() => {
                    return renameLogement(res.data.logements).map((item) => ({
                        label: `${item.logement_libelle}`,
                        value: `${item.logement_libelle}`,
                        values: `${item.logement_id}`,
                    }));
                });
            })
            .catch(() => { });
    };


    const onSelect = (val, option) => {
        setLoading(true)
        logementFetchOne2({ id: option.values }).unwrap()
            .then((res) => {
                setLoading(false)
                setLogement(res);
            })
            .catch(() => setLoading(false)
            );
    };


    const handleSave = () => {
        const data = ({ ...state, logements: [logements] })
        setLoading(true)

        equipeTierceInvitation(data).unwrap()
            .then((res) => {
                setLoading(false)
                navigate("/invitation-envoyee");
            })
            .catch(() => setLoading(false));
    };


    return (
        <div className='addtierce-page'>
            <Header>
                <Flex justify={"space-between"}>
                    <Col lg={23}>
                        <Row>
                            <Col lg={11}>
                                <div className="titre sans-pro-semi-bold">
                                    Intégration d'une équipe tierce à Kliner
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Space className="icons-head">
                            <NavLink to={"/equipes-entretien"}>
                                <CloseOutlined style={{ fontSize: "30px", marginRight: "10px", color: "grey" }} />
                            </NavLink>
                        </Space>
                    </Col>
                </Flex>
            </Header>
            <div className="context">
                <Row>
                    <Col lg={6} />
                    <Col lg={14}>
                        <Spin spinning={loading}>
                            <div className="content-in">
                                <div className="head">
                                    <div className="image">
                                        <img src={`${API_ROOT}/${user.avatar}`} alt=''></img>
                                        <Avatar src={`${API_ROOT}/${user.avatar}`} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} />
                                    </div>
                                    <div className="name">{user.firstname}</div>
                                </div>
                                <div className="titlePage sans-pro-bold">
                                    Quelle rémunération devrons-nous verser à ce <br />
                                    partenaire pour l’entretien de vos logements ?
                                </div>
                                <div className="subTitle sans-pro-regular">
                                    Indiquez les frais de ménage à reverser à votre partenaire pour chaque logement que vous lui confiez, de façon à conserver les accords que vous avez négociés. Notez que nous prélèverons une commission sur les frais de ménage versés.
                                    <div style={{ display: "inline-block", marginLeft: "5px" }}><a href="https://help.kliner.com/fr/articles/9343270-pourquoi-kliner-preleve-une-commission-sur-la-facturation-des-equipes-tierces" target="_blank"> En savoir plus sur nos conditions</a></div>
                                </div>
                                <div className="form-partenaire-remuneration">
                                    <Form
                                        name="basic"
                                        form={form}
                                        onFinish={null}
                                        autoComplete="off"
                                        layout="vertical">
                                        <Row>
                                            <label className="sans-pro-semi-bold">Retrouvez un logement et indiquez les frais de ménage à appliquer
                                            </label>
                                            <Col lg={12}>
                                                <AutoComplete
                                                    style={{ width: "100%" }}
                                                    className="form-control researchInput"
                                                    onSelect={(val, option) => onSelect(val, option)}
                                                    onSearch={handleSearch}
                                                    options={logements}
                                                    prefix={<SearchOutlined className="site-form-item-icon" />}
                                                >
                                                    <Input className="form-control" placeholder="Chercher et selectionnez le logement souhaité" />
                                                </AutoComplete>
                                            </Col>
                                            <Col lg={1} />
                                        </Row>
                                        {logement &&
                                            <div className="block-tarif">
                                                <Row>
                                                    <Col lg={10} >
                                                        <div className="logement">
                                                            <div className="label">Logement 1</div>
                                                            <div className="input calibri-light">
                                                                {logement?.libelle} ({logement?.ville})
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg={7} >
                                                        <div className="label2">Frais de ménage (TTC)
                                                            <Popover placement="top" content={contentInfo} overlayClassName="infoRemuneration">
                                                                <div className="info-remuneration-ttc">
                                                                    <img src="/assets/img/info-circled.png" alt="info" />
                                                                </div>
                                                            </Popover>
                                                        </div>
                                                        <Row>
                                                            <Col lg={11}>
                                                                <div className="input-clear calibri-light">
                                                                    {logement?.cout_duree.cout_menage_classique}€
                                                                    <div className="iconMore" onClick={() => setShowModal2(true)}>
                                                                        <img src="./assets/img/pen-colored.png" alt="more" />
                                                                    </div>
                                                                </div>
                                                                <p>Vous nous payez</p>
                                                            </Col>
                                                            <Col lg={1}></Col>
                                                            <Col lg={11}>
                                                                <div className="input textcenter calibri-light">
                                                                    €
                                                                </div>
                                                                <p>Votre partenaire reçoit</p>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <div className="close">
                                                            <CloseOutlined style={{ fontSize: "15px", marginRight: "10px", color: "grey" }} />
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }
                                    </Form>
                                </div>
                                <ChoisirForfaitView
                                    status={showModal2}
                                    top={755}
                                    left={400}
                                    handleCancel={() => setShowModal2(false)}
                                />
                            </div>
                        </Spin>
                    </Col>
                    <Col lg={5} />
                </Row>
            </div>
            <div className="footer">
                <NavLink to={"/contacter-partenaire"}>
                    <div className="button-retour sans-pro-semi-bold">Retour</div>
                </NavLink>
                <div className={"button-next sans-pro-semi-bold "+ (logement !== null ? "disable-Button" : "")} onClick={() => logement !== null ? null : handleSave()}><span>Suivant</span></div>
            </div>
        </div>
    );
}

export default RemunerationPartenairePage;