import React, { useEffect, useState } from 'react';
import "../styles/style.scss"
import { EditOutlined } from "@ant-design/icons";
import { Col, Row, Avatar } from "antd";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";
import LogementCreate from "../../logements/create";
import ProfilModalView from "./modal";
import { API_ROOT, getUser } from '../../../utils/global-var';
import { useGetUserInfoQuery } from '../services/profil-api';

function ProfilPartPage() {
    const [showModal, setShowModal] = useState(false);
    const user = getUser();
    const userInfo = useGetUserInfoQuery(user?.id);
    const [userAddress, setUserAddress] = useState({});

    useEffect(() => {
        if (userInfo.isSuccess) {
            const addr = JSON.parse(userInfo?.data?.residence_entreprise || userInfo?.data?.residence_principale || "{}");
            setUserAddress(addr);
        }

    }, [userInfo]);


    const cancel = () => {
        window.location.reload();
        setShowModal(false);
    }


    return (
        <div className="profil-part-page">
            <h2 className="sans-pro-semi-bold">Mon profil</h2>
            <div className="headprofil">
                <div className="imageSide">
                    <Avatar src={`${API_ROOT}/${userInfo?.avatar}`} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} />
                </div>
                <div className="infSide">
                    <div className="name sans-pro-regular color-595959">
                        {(userInfo?.isSuccess && userInfo.data?.firstname) || ""} {" " + ((userInfo?.isSuccess && userInfo?.data?.lastname) || "")}
                    </div>
                    <div className="function sans-pro-regular">
                        {userInfo?.data?.profil === "particulier" ? "LMNP" : userInfo?.data?.profil}
                    </div>
                </div>
                <div className="statusSide">
                    <div className="vip">VIP</div>
                </div>
            </div>

            <div className="contentProfil">
                <div className="head sans-pro-semi-bold">
                    Informations personnelles
                    <div className="icone" onClick={() => setShowModal(true)}>
                        <EditOutlined style={{ color: '#566F8F', fontSize: '22px', opacity: '0.8' }} />
                    </div>
                </div>
                <div className="content">
                    <Row>
                        <Col lg={12}>
                            <div className="label">
                                Prénom
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userInfo?.isSuccess && userInfo.data?.firstname}
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Nom
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userInfo?.isSuccess && userInfo.data?.lastname}
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Adresse email
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userInfo?.isSuccess && userInfo.data?.email}
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Téléphone (mobile)
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                <div className="number-chef sans-pro-regular color-595959">
                                    <PhoneInput
                                        flags={flags}
                                        className="sans-pro-regular color-595959"
                                        value={userInfo?.isSuccess && userInfo.data?.phone}
                                        defaultCountry="FR"
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="label">
                                Rôle
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userInfo?.data?.profil === "particulier" ? "LMNP" : userInfo?.data?.profil}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <ProfilModalView
                status={showModal}
                handleCancel={() => cancel()}
                closeModal={() => setShowModal(false)}
                userInfo={userInfo.data}
                addr={userAddress}
            />
        </div>
    );
}

export default ProfilPartPage;
