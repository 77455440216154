import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Input, Modal, Row, Col, Tabs, Spin } from "antd";
import { ArrowLeftOutlined, SearchOutlined } from "@ant-design/icons";
import LogementsActifs from "./tabs/logements-actifs";
import LogementsInActifs from "./tabs/logements-inactifs";
import { useLogementAssignmentMutation } from "../../../services/logement-api";

const AttribuerModalView = ({ status, handleCancel }) => {
    const [logementSelected, setLogementSelected] = useState([]);
    const [logementAssignment] = useLogementAssignmentMutation();
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state) => state.auth);

    console.log("logementSelected:", logementSelected);

    const items = [
        {
            key: '1',
            label: 'Inactifs',
            children: <LogementsInActifs logementSelected={setLogementSelected} />,
        },
        {
            key: '2',
            label: 'Actifs',
            children: <LogementsActifs logementSelected={setLogementSelected} />,
        },
    ];

    const onChange = (key) => {
        console.log(key);
    };


    const handleAssignment = async () => {
        const data = ({
            logements: logementSelected,
            responsable: user.id,
            aidemenagere: null,
            status: "Assignation en cours"
        })
        setLoading(true)
        logementAssignment(data).unwrap()
            .then((res) => {
                setLoading(false)
            })
            .catch(() => setLoading(false));
    };
    return (
        <Modal
            destroyOnClose
            title=""
            open={status}
            footer={null}
            style={{ top: 345, left: 250 }}
            closable={false}
            onCancel={() => handleCancel()}
            width={280}
            className="choisir-logement choisir-logement2">
            <Spin spinning={loading}>
                <div className="choisir">
                    <div className="head">
                        <div className="arrow" onClick={handleCancel}><ArrowLeftOutlined /></div>
                        <div className="title"><div className="title-in">Attribuer un logement</div></div>
                    </div>
                    <br />
                    <Input
                        className='search-input'
                        placeholder="Chercher par nom de logement..."
                        prefix={<SearchOutlined className="site-form-item-icon" />}
                    />
                    <div className="tabList">
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                    </div>

                    <div className="footer-modal">
                        <Row>
                            <Col lg={24} className="button-right">
                                <a href="#" onClick={() => handleAssignment()}
                                    className="suivant sans-pro-regular">Attribuer la selection</a>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Spin>
        </Modal>
    );
};
export default AttribuerModalView;
