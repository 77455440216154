import React, {} from "react";
import {
    Input,
    Modal,
    Row,
    Col,
    Tabs
} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";
import {IoLogoEuro} from "react-icons/io5";
import PourboireListTabPage from "./tabs/pourboire-list";

const PourboireModalView = ({status, handleCancel}) => {

    const timeFormat = 'HH:mm';

    const items = [
        {
            key: '1',
            label: 'Historique',
            children: <PourboireListTabPage />,
        },
    ];
    const onChange = (key) => {
        console.log(key);
    };

    return (
        <Modal
            title=""
            open={status}
            footer={null}
            style={{top: 345, left: 250}}
            closable={false}
            onCancel={() => {
                handleCancel();
            }}
            width={280}
            className="choisir-logement choisir-logement2"
        >

            <div className="choisir">
                <div className="head">
                    <div className="arrow" onClick={handleCancel}><ArrowLeftOutlined /></div>
                    <div className="title"><div  className="title-in">Verser un pourboire</div></div>
                </div>
                <br/>
                <Input
                    className='search-input'
                    placeholder="Chercher par nom de logement..."
                    prefix={<IoLogoEuro className="site-form-item-icon" />}
                />
                <br/>
                <div className="tabList">
                    <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
                </div>

                <div className="footer-modal">
                    <Row>
                        <Col lg={24} className="button-right">
                            <a href="#" onClick={handleCancel}
                               className="suivant sans-pro-regular">Valider</a>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};
export default PourboireModalView;
