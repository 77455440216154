import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Divider, Row, Col } from "antd";
import * as dashboardActions from '../services/dashboard-slice'

const EvaluationSuccess = () => {
    const dispatch = useDispatch();
    const {isEligible} = useSelector((state) => state.dashboard);

    return (
        <Modal title="" open={isEligible} footer={null} closable={false} style={{ top: 400 }}
            width={550}
            className="validProgramme" styles={{ background: 'red !important' }}>
            <div className="programme">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/logon.png" alt="airbnb"
                    className="title-img" />
                    <div className="title-label">Pas encore éligible à ce service</div>
                </div>
                <div>Cette fonctionnalité est disponible uniquement pour les clients actifs chez Kliner depuis au moins 6 mois, sans incident de paiement récent.</div>
                <div className="footer-modal">
                    <Divider />
                    <Row>
                        <Col lg={8} className="button-left"></Col>
                        <Col lg={8} className="button-right">
                            <a href="#" className="suivant sans-pro-regular" onClick={() => dispatch(dashboardActions.setEvaluation({ isEligible: false }))}>Ok j’ai compris</a>
                        </Col>
                        <Col lg={8}></Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};
export default EvaluationSuccess;
