import React, {useState} from 'react';
import {Layout, Input, Col, Flex, Space, Row, Tabs} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FaRegCircleQuestion } from "react-icons/fa6";
import {IoSettingsOutline} from "react-icons/io5";
import "./styles/style.scss"
import "./styles/styleMax1020.scss"
import {NavLink} from "react-router-dom";

const { Header } = Layout;

function AppHeader() {
    const [research, setResearch] = useState("");
    const items = [
        {
            key: '1',
            label: <div className="sans-pro-regular">Tout <div className="nbResult"><div className="nbResult-in">0</div></div></div>,
            children: <div className="resultZone"><div className="empty">0 résultat</div></div>,
        },
        {
            key: '2',
            label: <div className="sans-pro-regular">Logements <div className="nbResult"><div className="nbResult-in">0</div></div></div>,
            children: <div className="resultZone"><div className="empty">0 résultat</div></div>,
        },
        {
            key: '3',
            label: <div className="sans-pro-regular">Responsables de site <div className="nbResult"><div className="nbResult-in">0</div></div></div>,
            children: <div className="resultZone"><div className="empty">0 résultat</div></div>,
        },
        {
            key: '4',
            label: <div className="sans-pro-regular">Parrainés <div className="nbResult"><div className="nbResult-in">0</div></div></div>,
            children: <div className="resultZone"><div className="empty">0 résultat</div></div>,
        },
    ];

    const onChange = (key) => {
        console.log(key);
    };
    const handleInputChange = (e) => {
        setResearch(e.target.value);
        console.log("Current input value:", e.target.value);
    };
    return (
        <Header>
            <Flex justify={"space-between"} className="headerBarClass">
                <Col lg={7} />
                <Col lg={16}>
                    <Row>
                        <Col lg={14}>
                            <div className="topBarSearch">
                                <Input
                                    className='search-input'
                                    placeholder="Rechercher un logement, une prestation, une aide-ménagère, une facture..."
                                    prefix={<SearchOutlined className="site-form-item-icon" />}
                                    suffix={<div className="slash"><div>/</div></div>}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={14} style={{position:"relative"}}>
                            {research.length > 0 && <div className="resultResearch">
                                <Tabs defaultActiveKey="1" items={items} onChange={onChange}/>
                            </div>}
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Space className="icons-head">
                        <a href="https://help.kliner.com/fr/" target="_blank" className="noColor"><FaRegCircleQuestion /></a>
                        <NavLink to={"/mon-profil"} className="noColor"> <IoSettingsOutline /></NavLink>
                    </Space>
                </Col>
            </Flex>
        </Header>
    );
}

export default AppHeader;
