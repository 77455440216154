import React, { useState } from 'react';
import { Row, Col, Divider, Input, message, Spin, Popover } from "antd";
import { useCoutDureeUpdateMutation, useLogementUpdateMutation } from '../services/logement-api';
import { useSelector, useDispatch } from "react-redux";
import { setRefresh } from '../services/logement-slice';

const { TextArea } = Input;


function SurfaceView({ logement }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [supLogement, setSupLogement] = useState(logement.superficie === null ? 0 : logement.superficie);
    const [cumul, setCumul] = useState(logement.balconySurfaceArea);
    const [isLoading, setIsLoading] = useState(false);
    const [estime, setEstime] = useState(logement.gardenSurfaceArea);
    const [logementUpdate] = useLogementUpdateMutation();
    const [instructionAdresse, setInstructionAdresse] = useState(logement.instruction_adresse);
    const [coutDureeUpdate] = useCoutDureeUpdateMutation();
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();


    const handleChange = (e, value) => {
        if (value === "logement") {
            setSupLogement(e);
        }
        if (value === "cumul") {
            setCumul(e);
        }
        if (value === "estime") {
            setEstime(e);
        }
    };

    const handleBlur = (e, value) => {
        if (value === "logement") {
            handleUpdate({ superficie: e })
        }
        if (value === "cumul") {
            handleUpdate({ balconySurfaceArea: e })
        }
        if (value === "estime") {
            handleUpdate({ gardenSurfaceArea: e })
        }
    };

    const handleUpdate = async (data) => {
        setIsLoading(true)
        logementUpdate({ id: logement.id, data: data }).unwrap()
            .then(() => handleUpdateCout())
            .catch(() => { setIsLoading(false) });
    };


    const handleUpdateInstruction = () => {
        if (instructionAdresse?.length > 0) {
            logementUpdate({ id: logement.id, data: ({ instruction_adresse: instructionAdresse }) }).unwrap()
                .then(() => {
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                })
                .catch(() => { setIsLoading(false) });
        }
    };


    const contentInfo = (
        <div className="infoLogement">
            <p>
                Si seule une partie du logement est mise <br />
                en location saisonnière, veuillez <br />
                soustraire de la superficie totale du <br />
                logement les espaces qui ne seront pas <br />
                à nettoyer pendant les prestations.
            </p>
        </div>
    );

    const handleUpdateCout = () => {
        coutDureeUpdate({
            logementId: logement.id,
            superficie: supLogement,
            superficieBalcon: cumul,
            superficieJardin: estime,
            userId: user.id,
            type: "superficie"
        }).unwrap()
            .then(() => {
                setIsLoading(false)
                dispatch(setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => { setIsLoading(false) });
    };


    return (
        <Spin spinning={isLoading}>
            <div className="surface-a-nettoyer blc-right">
                {contextHolder}
                <div className="title">Surfaces à nettoyer</div>
                <Divider />
                <Row gutter={24}>
                    <Col lg={14}>
                        <div className="subTitle">Superficie (m2)</div>
                        <p className="surface-a-nettoyer-first-p color-566F8F">
                            Indiquez la superficie de tout espace du logement que vous souhaitez que l’aide-ménagère nettoie à chaque passage. Si par exemple vous souhaitez qu’une terrasse ou un jardin soit nettoyé, il faut indiquer sa superficie en plus de celle de l’espace de vie faute de quoi ces espaces ne seront pas pris en compte pour les prestations.
                        </p>
                    </Col>
                    <Col lg={10}>
                        <div className="subTitle">Instructions de ménage</div>
                        <p className="surface-a-nettoyer-first-p color-566F8F">Laissez toute consigne qui vous semble utile pour l’entretien de ce logement. Si vous
                            n’en donnez pas, les aides ménagères suivront les <a href='https://help.kliner.com/fr/articles/8992920-les-standards-de-kliner-pour-une-prestation-de-menage-bnb' target="_blank">standards Kliner</a>.</p>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col lg={14}>
                        <div className="blc-content blc-content-no-text">
                            <Row gutter={24}>
                                <Col lg={24}>
                                    <div className="contour-plein-gris">
                                        <Row gutter={24}>
                                            <Col lg={20}>
                                                <p className="mintitle">Superficie du logement (obligatoire) *
                                                    <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                                                        <div className="info-logement">
                                                            <img src="/assets/img/info-circled.png" alt="info" />
                                                        </div>
                                                    </Popover>
                                                </p>
                                            </Col>
                                            <Col>
                                                <div className="nombre">
                                                    <Input disabled={logement?.superficie?.length > 0 ? true : false} className="nombre-input" value={supLogement === 0 ? "" : supLogement} placeholder={supLogement === 0 ? "0" : ""} prefix={<div className="valeur">m²</div>}
                                                        onChange={(e) => handleChange(e.target.value, "logement")}
                                                        onBlur={(e) => handleBlur(e.target.value, "logement")} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={24}>
                                    <div className="contour-plein-gris">
                                        <Row gutter={24}>
                                            <Col lg={20}>
                                                <p className="mintitle">Superficie cumulée de toutes les terrasses ou balcons à nettoyer </p>
                                            </Col>
                                            <Col>
                                                <div className="nombre">
                                                    <Input className="nombre-input" value={cumul === 0 ? "" : cumul} placeholder={cumul === 0 ? "0" : ""} prefix={<div className="valeur">m²</div>}
                                                        onChange={(e) => handleChange(e.target.value, "cumul")}
                                                        onBlur={(e) => handleBlur(e.target.value, "cumul")} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={24}>
                                    <div className="contour-plein-gris">
                                        <Row gutter={24}>
                                            <Col lg={20}>
                                                <p className="mintitle">Superficie estimée de tout jardin ou allée à nettoyer </p>
                                            </Col>
                                            <Col>
                                                <div className="nombre">
                                                    <Input className="nombre-input" value={estime === 0 ? estime : "0"} placeholder={estime === 0 ? "0" : ""} prefix={<div className="valeur">m²</div>}
                                                        onChange={(e) => handleChange(e.target.value, "estime")}
                                                        onBlur={(e) => handleBlur(e.target.value, "estime")} />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <br /> <br />
                        <div className="subTitle">Ajustement de durée</div>
                        <div className="blc-content surface-a-nettoyer-second-p sans-pro-light color-9AA1AB">
                            <p>Nous utilisons principalement la superficie du logement pour déterminer la durée forfaitaire
                                des prestations de ménage.
                                Cette durée est importante pour construire le planning des aides ménagères. </p> <br />
                            <p>Si la durée définie par défaut vous semble trop éloignée du temps habituellement nécessaire
                                pour faire une bonne
                                prestation selon votre expérience, vous pouvez <a href="https://app.lemcal.com/@rzdrilic/30-minutes" target="_blank">contacter votre Gestionnaire de
                                    compte</a>, qui se chargera de modifier la
                                durée selon votre demande.</p> <br />
                            <p>Toutefois veuillez noter que si vous demandez une réduction de la durée forfaitaire de ménage
                                ou si vous refusez une
                                demande d’augmentation de notre part, vous libérez Kliner de notre engagement à ne facturer
                                à 100% que les prestations
                                notées 5 étoiles. Il en va de même si nous constatons un écart entre la superficie déclarée
                                ici et celle constatée sur place.</p>
                        </div>
                    </Col>
                    <Col lg={10}>
                        <TextArea
                            value={instructionAdresse}
                            onBlur={handleUpdateInstruction}
                            onChange={(e) => setInstructionAdresse(e.target.value)}
                            rows={28}
                        />
                    </Col>
                </Row>
            </div>
        </Spin>
    );
}

export default SurfaceView;