import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Divider, Row, Col } from "antd";
import { setSuccess } from "../services/planning-slice";

const PlanningSuccess = ({onOpenNewPrestation, comeFrom, onOpenDetail}) => {
    const dispatch = useDispatch();
    const { isSuccess } = useSelector((state) => state.planning);
    const planning = useSelector((state) => state.planning);
    const { user } = useSelector((state) => state.auth);

    return (
        <Modal title="" open={isSuccess} footer={null} closable={false} style={{ top: 400 }}
            width={550}
            className="validProgramme2"
        >
            <div className="programme">
                <div className="title sans-pro-semi-bold"><img src="/assets/img/check-green.png" alt="airbnb"
                    className="title-img" />
                    <div className="title-label">La prestation a été créée</div>
                </div>
                <p>
                    Merci {user?.firstname} ! Votre prestation a été rajoutée au planning des aides ménagères.
                </p>
                <div className="footer-modal">
                    <Divider />
                    <Row>
                        <Col lg={11} className="button-left">
                            <a href="#" onClick={() => { dispatch(setSuccess(false));
                                if(comeFrom === "planning"){
                                    onOpenDetail(true);
                                }
                            }} className="modifier"><span> Voir la prestation</span></a>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={11} className="button-right">
                            <a href="#" onClick={() => { dispatch(setSuccess(false));
                                if(comeFrom === "planning"){
                                    onOpenNewPrestation(true);
                                }
                            }}
                                className="suivant sans-pro-regular">Programmer une autre</a>
                        </Col>
                    </Row>
                </div>
            </div>
        </Modal>
    );
};
export default PlanningSuccess;
