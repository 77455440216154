import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Col, Flex, Layout, Row, Space, Avatar } from "antd";
import { useNavigate } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import { API_ROOT } from '../../../utils/global-var';
import "../styles/style.scss"

const { Header } = Layout;

function QuelProfilePage() {
    const { user } = useSelector((state) => state.auth);
    const [selectItem, setSelectItem] = useState(null);
    const navigate = useNavigate();

    const handleNext = () => {
        navigate("/contacter-partenaire", {
            state: { profile: selectItem },
        });
    };

    return (
        <div className='addtierce-page'>
            <Header>
                <Flex justify={"space-between"}>
                    <Col lg={23}>
                        <Row>
                            <Col lg={11}>
                                <div className="titre sans-pro-semi-bold">
                                    Intégration d'une équipe tierce à Kliner
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Space className="icons-head">
                            <NavLink to={"/equipes-entretien"}>
                                <CloseOutlined style={{ fontSize: "30px", marginRight: "10px", color: "grey" }} />
                            </NavLink>
                        </Space>
                    </Col>
                </Flex>
            </Header>
            <div className="context">
                <Row>
                    <Col lg={6} />
                    <Col lg={14}>
                        <div className="content-in">
                            <div className="head">
                                <div className="image">
                                    {/*<img src={`${API_ROOT}/${user.avatar}`} alt=''></img>*/}
                                    <Avatar src={`${API_ROOT}/${user.avatar}`} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} />
                                </div>
                                <div className="name">{user.firstname}</div>
                            </div>
                            <div className="titlePage sans-pro-bold">
                                Quel est le profil de votre partenaire ménage ?
                            </div>
                            <div className="subTitle sans-pro-regular">
                                Cela nous permettra de déterminer le type de relation que nous devrons mettre en
                                place.
                                <a href="https://help.kliner.com/fr/articles/9343012-comment-kliner-gere-le-defi-administratif-pour-integrer-vos-equipes-sur-la-plateforme" target="_blank">En savoir plus</a>
                            </div>
                            <div className="choice">
                                <Row>
                                    <Col lg={9}>
                                        <div className={"blockChoice " + (selectItem === "pme" ? "active" : "")} onClick={() => setSelectItem("pme")}>
                                            <div className="image">
                                                <img src="./assets/img/PME icon.png" alt="icone" />
                                            </div>
                                            <div className="blockTitle sans-pro-semi-bold">
                                                PME de nettoyage
                                            </div>
                                            <div className="blockDesc sans-pro-regular">
                                                Une société qui emploie plusieurs <br /> aide-ménagères.
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={9}>
                                        <div className={"blockChoice " + (selectItem === "auto-entrepreneur" ? "active" : "")} onClick={() => setSelectItem("auto-entrepreneur")}>
                                            <div className="image">
                                                <img src="./assets/img/Auto-E icon.png" alt="icone" />
                                            </div>
                                            <div className="blockTitle sans-pro-semi-bold">
                                                Auto-entrepreneur
                                            </div>
                                            <div className="blockDesc sans-pro-regular">
                                                Une aide-ménagère qui travaille <br /> pour sa micro-entreprise.
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={9}>
                                        <div className={"blockChoice " + (selectItem === "emploi direct" ? "active" : "")} onClick={() => setSelectItem("emploi direct")}>
                                            <div className="image">
                                                <img src="./assets/img/emploi direct icon.png" alt="icone" />
                                            </div>
                                            <div className="blockTitle sans-pro-semi-bold">
                                                Emploi direct
                                            </div>
                                            <div className="blockDesc sans-pro-regular">
                                                Un particulier que vous payez en <br /> direct de façon déclarée ou non.
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} />
                </Row>
            </div>
            <div className="footer">
                <div className={"button-next-center sans-pro-semi-bold "+ (selectItem === null ? "disable-Button" : "")} onClick={() => selectItem === null ? null : handleNext()}>
                    <span>Suivant</span>
                </div>
            </div>
        </div>
    );
}

export default QuelProfilePage;