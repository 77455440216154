import React, { useState, useEffect } from 'react';
import { Row, Col, Divider } from "antd";
import { useSelector } from "react-redux";
import { CheckCircleOutlined, SettingOutlined, SyncOutlined } from "@ant-design/icons";
import MoyenPaiementModalView from "../../../modal-global/moyen-de-paiement";
import { useGetDefaultPaymentMethodQuery } from '../../../profil/services/profil-api';
import AjoutIcalView from './ajout-ical-view';
import { useIcalendarFetchMutation } from '../../services/logement-api';


function AutomatisationView({ logement }) {
    const defaultPaymentMethod = useGetDefaultPaymentMethodQuery();
    const [icalendarFetch] = useIcalendarFetchMutation();
    const [showModal, setShowModal] = useState(false);
    const [showPaieModal, setShowPaieModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [icals, setIcals] = useState([])
    const { isRefresh } = useSelector((state) => state.logement);

    const handleFetchIcals = async () => {
        setIsLoading(true);
        icalendarFetch({ id: logement?.id })
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                setIcals(res)
            })
            .catch((error) => {
                setIsLoading(false);
            })
    };


    useEffect(() => {
        handleFetchIcals()
    }, []);

    useEffect(() => {
        isRefresh &&
            handleFetchIcals()
    }, [isRefresh]);


    const automatePlanning = () => {
        if (defaultPaymentMethod.isSuccess && defaultPaymentMethod.data) {
            // AUTOMATE PLANNING
            setShowModal(true);
        } else {
            // No payment method
            setShowPaieModal(true);
        }
    }



    return (
        <div className="blc-right automatisation">
            <div className="title">Automatisation du planning</div>
            <Divider />
            <Row gutter={24}>
                <Col lg={14}>
                    <div className="subTitle">Synchroniser le planning de ménage avec vos plateformes de réservation</div>
                    <p className="color-566F8F calibri-light">Connectez Kliner à vos plateformes de réservation pour automatiser la programmation des prestations de ménage.
                        Une fois la connexion mise en place, une prestation de ménage classique sera automatiquement programmée à
                        chaque départ voyageur.</p>
                    <div className="blc-content blc-content-no-text">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={19}>
                                            <div className="mintitle sans-pro-semi-bold color-213856">Automatiser le planning de ménage</div>
                                            <span className="sans-pro-light color-5B6E8C">Prestations de ménage créées automatiquement à la fin de chaque réservation</span>
                                        </Col>
                                        <Col lg={5} className="button-side" style={{paddingLeft:"0px"}}>
                                            {/* <a href="#" className="contour-item-a " onClick={() => automatePlanning()}> <SyncOutlined className="icon-primary-bold"  /> Automatiser </a> */}
                                            {logement.responsable ?
                                                <a href="#" className="contour-item-a" style={{padding:"10px 8px"}} onClick={() => automatePlanning()}> <SyncOutlined className="icon-primary-bold" /> Automatiser </a>
                                                :
                                                <a href="#" className="contour-item-a" style={{ color: "gray", padding:"10px 8px" }}> Automatiser </a>
                                            }
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row gutter={24}>
                <Col lg={14}>
                    <div className="subTitle">Plateformes de réservation connectées</div>
                    <div className="blc-content blc-content-no-text">
                        {icals.map((item, index) => (
                            <Col lg={24}>
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={10}>
                                            <img src="/assets/img/ical icon.png" alt="airbnb" className="plateforme-img" /> <div className="plateforme-label sans-pro-regular">Kliner</div>
                                        </Col>
                                        <Col lg={9}>
                                            <div className="plateforme-status"><CheckCircleOutlined className="icon-green-bold sans-pro-regular" /> Connecté</div>
                                        </Col>
                                        <Col lg={5} className="button-side">
                                            <div className="boutton-gerer disabled-block">
                                                <a href="#" className="contour-item-a2 disable-Button" onClick={() => null /*setShowModal(true)*/}> <SettingOutlined className="icon-primary-bold" /> Gerer </a>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <br />
                            </Col>
                        ))}
                    </div>
                </Col>
            </Row>

            <AjoutIcalView
                status={showModal}
                handleCancel={() => setShowModal(false)}
                logement={logement}
            />

            <MoyenPaiementModalView
                status={showPaieModal}
                handleCancel={() => setShowPaieModal(false)}
            />
        </div>
    );
}

export default AutomatisationView;