import React from 'react';
import "../styles/style.scss"
import { Table } from "antd";

function AttestationPartPage() {

    const columns = [
        {
            title: 'Periode',
            key: "numero",
            width: "32%",
            render: (record) => <div style={{ cursor: "pointer" }}>
                <div className="titleTable">{record.facture}</div>
            </div>,
        },
        {
            title: 'Logement',
            key: "date",
            width: "32%",
            render: (record) => <div style={{ cursor: "pointer" }}>
                <div className="titleTable">{record.date}</div>
            </div>,
        },
        {
            title: 'Total dépensé',
            key: "total",
            width: "32%",
            render: (record) => <div style={{ cursor: "pointer" }}>
                <div className="titleTable">{record.total}</div>
            </div>,
        },
        {
            title: '',
            key: "download",
            width: "4%",
            render: (record) => <div className="download">
                <img src="./assets/img/logon.png" alt="download" />
            </div>,
        }
    ];
    return (
        <div className="profil-part-page">
            <h2 className='sans-pro-semi-bold'>Mes attestations</h2>
            <div className="headprofil">
                <Table className="facture-table" columns={columns} />
                {/* {factures.map((item, index) => (
                    <Row className="factures-listes" key={"factures_" + index}>
                        <Col lg={8}>
                            <div className="tableTitle sans-pro-semi-bold">{item["facture"]}</div>
                        </Col>
                        <Col lg={8}>
                            <div className="tableTitle">{item["date"]}</div>
                        </Col>
                        <Col lg={7}>
                            <div className="tableTitle">{item["total"]}</div>
                        </Col>
                        <Col lg={1}>
                            <div className="download">
                                <img src="./assets/img/logon.png" alt="download" />
                            </div>
                        </Col>
                    </Row>
                ))} */}
            </div>
        </div>
    );
}

export default AttestationPartPage;
