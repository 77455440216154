import { createSlice } from "@reduxjs/toolkit";

export const logementSlice = createSlice({
  name: "auth",
  initialState: {
    isRefresh: false,
    isEmpty: false,
    isUsed: false,
  },
  reducers: {
    setRefresh: (state, { payload }) => {
      state.isRefresh = payload;
      return state;
    },

    setIcal: (state, { payload }) => {
      state.isEmpty = payload;
      return state;
    },

    showIcalDialog: (state, { payload }) => {
      state.isEmpty = payload.isEmpty
      state.isSuccess = payload.isSuccess
      state.isUsed = payload.isUsed
    },
  },
});

export const { setRefresh, setIcal, showIcalDialog } = logementSlice.actions;
export default logementSlice.reducer;
