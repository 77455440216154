import React, { useState } from 'react';
import "./styles/style.scss"
import "./styles/styleMax1020.scss"
import { Avatar, Button, Col, Divider, Dropdown, Menu, Popover, Progress, Row, Table } from "antd";
import moment from "moment/moment";
import PartagerModalView from "./modal/partager";
import {convertDateStringToTimeStringComplete} from "../../utils/global-var";

function ParrainagePage() {
    const [showModal, setShowModal] = useState(false);
    const contentInfo = (
        <div className="infoLogement">
            <p>
                Indique si le parrainé a commencé à <br />
                utiliser les services Kliner… <br />
                autrement dit, s’il vous rapporte <br />
                déjà de l’argent.
            </p>
        </div>
    );

    const columns = [
        {
            title: "Parrainés",
            width: "20%",
            render: (record) => (
                <div style={{ cursor: 'pointer', color: "#263754", fontSize: "15px" }}>
                    <span className="sans-pro-semi-bold">{record.parrain}</span>
                </div>
            ),
        },

        {
            title: <div className="statusHeadColClass">
                Statut
                <Popover placement="top" content={contentInfo} overlayClassName="infoContentLogement">
                    <div className="info-logement">
                        <img src="/assets/img/info-circled.png" alt="info" />
                    </div>
                </Popover>
            </div>,
            render: (record) => (
                <div>
                    {(record.statut === "actif") && (<div className="badge-green" >Actif</div>)}
                    {(record.statut === "inactif") && (<div className="badge-grey" >Inactif</div>)}
                </div>
            ),
        },

        {
            title: 'Avoir générés',
            render: (record) => (
                <div>
                    {record.special}
                </div>
            ),
        },
        {
            title: "Créé le",
            key: "createdAt",
            width: "20%",
            render: (record) => (
                <span> {convertDateStringToTimeStringComplete(record.logement_createdAt)}</span>
            ),
        }
    ];

    const parrainList = [
        {
            key: 1,
            parrain: `Agnes Debangy`,
            date: `10 jannvier 2023 à 10:22`,
            statut: "actif",
            special: "4.50€",
        },
        {
            key: 2,
            parrain: `Paul Bermude`,
            date: `10 jannvier 2023 à 10:22`,
            statut: "inactif",
            special: "0.00€",
        },
    ];

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    let locale = {
        emptyText: (
            <div className='empty-blc'>
                <div className='blc'>
                    <p>Aucun parrainé pour le moment</p>
                </div>
            </div>
        )
    };

    return (
        <div className='parrainage-page parrainage-page-padding'>
            <div className='page-header'>
                <div className='page-title'>
                    <h1>Parrainage</h1>
                </div>
            </div>
            <div className="content-parrainage">
                <Row>
                    <Col lg={8}>
                        <div className="titre sans-pro-semi-bold">
                            <span>Économisez jusqu’à 6000€
                                de frais de ménage</span> en parrainant
                            des nouveaux loueurs sur Kliner !
                        </div>
                        <div className="explication">
                            <p>Invitez un loueur de meublés de tourisme à utiliser notre plateforme pour l’entretien de ses logements... <br />
                                Chaque mois vous profiterez d’une remise sur vos frais de ménage équivalent à 10% des dépenses effectuées par votre parrainé chez Kliner sur la même période.</p>

                            <div className="linkIN">
                                <a href="https://help.kliner.com/fr/articles/9334607-comment-fonctionne-notre-programme-de-parrainage" target="_blank">Consulter les conditions détaillées du programme</a>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4}>
                    </Col>
                    <Col lg={12}>
                        <div className="percent color-5B6E8C">(0%)</div>
                        <Progress percent={0} showInfo={false} strokeColor="#74DA74" />
                        <div className="size">
                            <div className="left sans-pro-semi-bold">0€</div>
                            <div className="right sans-pro-semi-bold">6000€</div>
                        </div>
                        <div className="share">
                            <span className="calibri-light" style={{ color: "#8080806e" }}>Lien de parrainage en cours de création…</span>
                            <Button type={"primary"} className="button" onClick={() => setShowModal(true)}>
                                Partager
                            </Button>
                        </div>

                        <div className="shareText sans-pro-semi-bold">
                            Partagez votre lien de parrainage avec vos relations
                        </div>
                    </Col>
                </Row>
                <br />
                <Divider />
                <br />
                <Row>
                    <Col lg={24}>
                        <div className="headerDown sans-pro-semi-bold">
                            Simple comme bonjour
                        </div>
                    </Col>
                    <Col lg={7}>
                        <div className="number">1</div>
                        <div className="title sans-pro-semi-bold">Parrainez un nouveau loueur</div>
                        <div className="description sans-pro-light">
                            Envoyez votre lien de parrainage à vos relations et veillez à ce qu’elles l’utilisent pour créer leur compte sur Kliner
                        </div>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={7}>
                        <div className="number">2</div>
                        <div className="title sans-pro-semi-bold">Suivez la progression de votre parrainage</div>
                        <div className="description sans-pro-light">
                            Découvrez qui a utilisé votre lien pour créer un compte sur Kliner et consultez le montant d’avoir que vos parrainages vous génèrent chaque jour
                        </div>
                    </Col>
                    <Col lg={1}></Col>
                    <Col lg={7}>
                        <div className="number">3</div>
                        <div className="title sans-pro-semi-bold">Baissez vos frais de ménage</div>
                        <div className="description sans-pro-light">
                            Recevez votre récompense financière sous forme d’avoir appliqué à vos factures mensuelles de frais de ménage.
                        </div>
                    </Col>
                    <Col lg={1}></Col>
                </Row>
                <br />
                <br />
            </div>

            <div className="tabZone">
                <Table
                    className="parrainage-table"
                    pagination={{ pageSize: 10 }}
                    rowSelection={rowSelection}
                    // dataSource={parrainList}
                    columns={columns}
                    rowKey={(record) => record.key}
                    locale={locale}
                />
            </div>

            <PartagerModalView
                status={showModal}
                handleCancel={() => setShowModal(false)}
            />
        </div>
    );
}

export default ParrainagePage;
