import React from 'react';
import "../../styles/style.scss"

function ToutTabPage() {
    return (
        <div className='tout'>
            <div className="disclaimer sans-pro-regular">
                Tous les logements de votre parc gérés par ce prestataire
            </div>
            {/* <div className="listElement">
                <div className="element">
                    <di className="icone">
                        <img src="./assets/img/icon-adresse.png" alt="icone"/>
                    </di>
                    <div className="zone sans-pro-regular">Montparnasse</div>
                    <div className="ville sans-pro-regular">(Paris)</div>
                    -
                    <div className="forfait sans-pro-regular"> Forfait: 45.00€</div>
                </div>
            </div> */}
        </div>
    );
}

export default ToutTabPage;
