import React, { useState, useEffect, useRef } from "react";
import {Row, Col, Input, message, Spin, Popover} from "antd";
import { apiKey, extractAddress, loadAsyncScript, mapApiJs } from "../../../../utils/googlemap-utils";
import { useLogementUpdateMutation } from "../../services/logement-api";
import {setRefresh} from "../../services/logement-slice";
import {useDispatch} from "react-redux";
const { TextArea } = Input;


function LingeSaleView({ logement }) {
    const [value, setValue] = useState(logement.laundryManagementInstruction);
    const [messageApi, contextHolder] = message.useMessage();
    const searchInput = useRef(null);
    const [logementUpdate] = useLogementUpdateMutation();
    const [isLoading, setIsLoading] = useState(false);
    const [address, setAddress] = useState();
    const dispatch = useDispatch();

    const initMapScript = () => {
        if (window.google) return Promise.resolve();
        const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
        return loadAsyncScript(src);
    };

    const initAutocomplete = (searchInput) => {
        if (!searchInput) return;
        const autocomplete = new window.google.maps.places.Autocomplete(searchInput);
        autocomplete.setFields(["address_component", "geometry", "formatted_address", "name"]);
        autocomplete.addListener("place_changed", () => {
            const place = autocomplete.getPlace();
            setAddress(extractAddress(place));
        });
    };


    useEffect(() => {
        initMapScript().then(() => initAutocomplete(searchInput.current));
    }, []);


    const handleAdressSaveFunction = () => {
        if (address) handleAdressSave();
    };

    useEffect(handleAdressSaveFunction, [address]);

    const handleAdressSave = async () => {
        if (address) {
            const data = ({
                laundryManagementAdresse: address?.street_number + " " + address?.street + ", " + address?.city,
            })
            setIsLoading(true)
            logementUpdate({ id: logement.id, data: data }).unwrap()
                .then(() => {
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                    dispatch(setRefresh(true));
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                });
        }
    };


    const handleBlur = () => {
        if (value?.length > 0) {
            setIsLoading(true)
            logementUpdate({ id: logement.id, data: ({ laundryManagementInstruction: value }) }).unwrap()
                .then(() => {
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                    setIsLoading(false)
                })
                .catch(() => setIsLoading(false)
                );
        }
    };

    const contentInfo = (
        <div className="infoLinge">
            <p>
                Si l’adresse fournie est incorrecte ou si <br/>
                le Lavomatic n’est pas ouvert à l’heure <br/>
                de la prestation, le linge sale sera <br/>
                ramené au logement et la prestation <br/>
                sera tout de même facturée
            </p>
        </div>
    );

    return (
        <div className="lavomatic blc-right">
            {contextHolder}
            <Spin spinning={isLoading}>
                <Row gutter={24}>
                    <Col lg={24}>
                        <p>Veuillez indiquer l’adresse d’un Lavomatic à proximité du logement* (obligatoire)
                            <Popover placement="top" content={contentInfo} overlayClassName="infoContentLinge">
                                <div className="info-linge">
                                    <img src="/assets/img/info-circled.png" alt="info" />
                                </div>
                            </Popover>
                        </p>
                        <Row>
                            <Col lg={16}>
                                <input type="text" className="ant-input" style={{height:"48px"}} ref={searchInput} placeholder={logement?.laundryManagementAdresse ? logement?.laundryManagementAdresse : "Saisir l’adresse"} />
                            </Col>
                        </Row>
                         <br /> <br /><br /> <br /> <br />
                    </Col>

                    <Col lg={24}>
                        <p>Où doit-on déposer le linge lavé dans le logement ? (recommandé)</p>
                        <TextArea
                            className="radiusTextArea"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            onBlur={handleBlur}
                            rows={8}
                        />
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}

export default LingeSaleView;