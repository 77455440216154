import React, { useState } from 'react';
import { Modal, Divider, Row, Col, Form, Input, Alert, Button, Select } from "antd";
import { useIcalendarCreateMutation } from '../../services/logement-api';
import { useDispatch } from "react-redux";
import * as logementActions from '../../services/logement-slice'


const { Option } = Select;

const AjoutIcalView = ({ status, handleCancel, logement }) => {
    const [form] = Form.useForm();
    const [platform, setPlatform] = useState();
    const [selectPlatform, setSelectPlatform] = useState(true);
    const [icalendarCreate] = useIcalendarCreateMutation();
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const handleSelectPlatform = (e) => setPlatform(e);

    const handleSubmit = async (value) => {
        const data = ({ ...value, platform, logementId: logement.id, downloadLockedEvents: false })
        if (!error) {
            setIsLoading(true);
            icalendarCreate(data)
                .unwrap()
                .then((data) => {
                    form.resetFields();
                    setIsLoading(false);
                    if (data.msg === "No Event") {
                        dispatch(logementActions.showIcalDialog({ isEmpty: true }));
                        handleCancel()
                    } else if (data.msg === "Already used") {
                        dispatch(logementActions.showIcalDialog({ isUsed: false }))
                        handleCancel()
                    } else {
                        if (data.checkoutUrl) {
                            window.location.href = data.checkoutUrl;
                        } else {
                            dispatch(logementActions.setRefresh(true));
                            setIsLoading(false);
                            handleCancel()
                        }
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    setError(error.data.message);
                });
        }
    };



    const validateUrl = (rule, value, callback) => {
        const urlRegex = /^(http|https):\/\/[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;
        if (!value) {
            callback();
        } else if (!urlRegex.test(value)) {
            callback("URL invalide");
        } else {
            callback();
        }
    };


    return (
        <div>
            <Modal title="" open={status} footer={null} closable={true} onCancel={() => handleCancel()} width={700} className="add-automatisation-modal">
                <div className="add-automatisation">
                    {
                        selectPlatform ?
                            <div>
                                <div className="select-plateforme">
                                    <div className="title sans-pro-semi-bold">Sélectionnez une plateforme de réservation</div>
                                    <br />
                                    <Form.Item label="" name="plateforme">
                                        <Select className="form-control" suffixIcon={<div className='icon-logement-select' style={{ marginTop: "10px" }}><img src="../assets/img/arrow-down-gray.png" alt="add" /> </div>} placeholder="Choisir une plateforme" onChange={(e) => handleSelectPlatform(e)} overlayClassName="automatisation-select">
                                            {/* <Option key="Airbnb" value="Airbnb" style={{ paddingTop: "10px" }}>
                                                <img src="/assets/img/logon.png" alt="Airbnb" width={30} style={{ borderRadius: "50%" }} /> <div style={{ display: "inline-block", verticalAlign: "top", padding: "6px" }}>Airbnb</div>
                                            </Option>
                                            <Option key="booking" value="Booking" style={{ paddingTop: "10px" }}>
                                                <img src="/assets/img/logon.png" alt="Airbnb" width={30} style={{ borderRadius: "50%" }} /> <div style={{ display: "inline-block", verticalAlign: "top", padding: "6px" }}>Booking.com</div>
                                            </Option> */}
                                            <Option key="none" value="None" style={{ paddingTop: "10px" }}>
                                                <img src="/assets/img/no-find-platform.png" alt="Airbnb" width={30} style={{ borderRadius: "50%" }} /> <div style={{ display: "inline-block", verticalAlign: "top", padding: "6px" }}>Je ne trouve pas ma plateforme</div>
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                    <span className="sans-pro-regular">
                                        Nous rajoutons aussi vite que possible de nouvelles plateformes à notre liste d’intégrations. Si la
                                        plateforme de réservation recherchée n’est pas encore disponible dans la liste, sélectionnez l’option <strong>Je
                                            ne trouve pas ma plateforme’</strong> pour automatiser le planning avec <a href="https://help.kliner.com/fr/articles/8953325" target="_blank">un lien iCalendar (iCal)</a>.
                                    </span>
                                </div>
                                <div className="footer-modal">
                                    <Divider />
                                    <Row>
                                        <Col lg={12} className="annuler" onClick={() => handleCancel()}>
                                            <span> Annuler </span>
                                        </Col>
                                        <Col lg={12} className="button-right">
                                            <Button type="primary" onClick={() => setSelectPlatform(false)}>Suivant</Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            :
                            <div>
                                <Form
                                    name="basic"
                                    form={form}
                                    onFinish={handleSubmit}
                                    autoComplete="off">
                                    <div className="ajouter-ical">
                                        <div className="title"><img src="/assets/img/logon.png" alt="airbnb" className="title-img" /> <div className="title-label">Ajouter un lien iCal</div></div>
                                        <br />
                                        <Form.Item
                                            name="icsUrl"
                                            rules={[
                                                { required: true, message: "Champs requis!" },
                                                { validator: validateUrl },
                                            ]}
                                        >
                                            <Input className="form-control" placeholder="Collez l’URL du planning de réservation" />
                                        </Form.Item>
                                        <span className="indicatison-lien-ical"><a href='https://help.kliner.com/fr/collections/9454167' target='_blank'>Comment trouver l’adresse (URL) du planning de réservation ?</a></span>
                                    </div>
                                    <br />
                                    {error && <Alert message={error} type="error" closable />}
                                    <br />
                                    <div className="footer-modal">
                                        <Divider />
                                        <Row>
                                            <Col lg={12} className="annuler" onClick={() => (handleCancel(), setSelectPlatform(true))}>
                                                <span> Annuler </span>
                                            </Col>
                                            <Col lg={12} className="button-right">
                                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                                    Enregistrer
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                    }
                </div>
            </Modal>
        </div>
    );
};
export default AjoutIcalView;
