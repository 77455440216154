import React, { useEffect, useState } from 'react';
import "../styles/style.scss"
import { EditOutlined } from "@ant-design/icons";
import { Col, Divider, Radio, Row, Switch } from "antd";
import { IoArrowForward } from "react-icons/io5";
import ProfilModalView from "../profil/modal";
import InformationModalView from "./modal";
import { useAddPaymentMethodMutation, useGetDefaultPaymentMethodQuery, useGetUserInfoQuery } from '../services/profil-api';
import { getUser } from '../../../utils/global-var';

function InformationPartPage() {
    const [activeSwitch, setActiveSwitch] = useState("");
    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [addPaymentMethod] = useAddPaymentMethodMutation();
    const user = getUser();
    const defaultPaymentMethod = useGetDefaultPaymentMethodQuery();
    const userInfo = useGetUserInfoQuery(user?.id);
    const [userAddress, setUserAddress] = useState({});


    console.log("userInfo:====>", userInfo.data);

    useEffect(() => {
        if (userInfo.isSuccess) {
            const addr = JSON.parse(userInfo?.data?.residence_entreprise || userInfo?.data?.residence_principale || "{}");
            setUserAddress(addr);
            // console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx", userInfo, addr);
        }
    }, [userInfo]);

    const handleSwitchChange = (switchName, checked) => {
        setLoading(true)
        if (checked) {
            setActiveSwitch(switchName);

        } else {
            setActiveSwitch(null);
        }
    };

    const _addPaymentMethod = (type) => {
        addPaymentMethod(type)
            .unwrap()
            .then((response) => {
                window.location.href = response?.session_url;
            })
            .catch(() => { });
    }

    const getLast4 = (paymentMethod) => {
        switch (paymentMethod.type) {
            case "card":
                return paymentMethod.card.last4;
            case "sepa_debit":
                return paymentMethod.sepa_debit.last4;
            default:
                return "";
        }
    }

    const getPaymentMethodIcon = (paymentMethod) => {

        if (paymentMethod?.type === "card") {
            switch (paymentMethod?.card?.brand) {
                case "visa": return "../assets/img/visa.png"
                case "mastercard": return "../assets/img/mastercard.png"
                case "amex": return "../assets/img/amex.png"
                default: return "../assets/img/empty-payment-icon.png";
            }
        } else if (paymentMethod?.type === "sepa_debit") {
            return "../assets/img/sepa-icon.png";
        } else {
            return "../assets/img/empty-payment-icon.png";
        }
    }


    const cancel = () => {
        window.location.reload();
        setShowModal(false);
    }

    console.log("userInfo:", userInfo);

    return (
        <div className="profil-part-page">
            <h2 className="sans-pro-semi-bold">Mes informations de facturation</h2>
            <div className="headprofil">
                <div className="head sans-pro-semi-bold">
                    Profil de facturation
                </div>
                <div className="content-head">
                    <div>
                        <Row gutter={24}>
                            <Col lg={21}>
                                <div className="titleHead sans-pro-regular">Je suis un loueur professionnel (Conciergerie, gîte, hôtel...)</div>
                                <span className="sans-pro-regular">Les tarifs affichés sur la plateforme sont hors taxes (HT). La TVA s’applique en sus sur toutes vos factures.</span>
                            </Col>
                            <Col lg={3}>
                                <Switch checked={userInfo.data.profil === "conciergerie indépendante" && true} />
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <br />
                    <div>
                        <Row gutter={24}>
                            <Col lg={21}>
                                <div className="titleHead sans-pro-regular">Je suis un loueur particulier (LMNP)</div>
                                <span className="sans-pro-regular">Les tarifs affichés sur la plateforme sont toutes taxes incluses (TTC). Vous recevrez une attestation de crédit <br /> d’impôt pour les prestations éligibles.</span>
                            </Col>
                            <Col lg={3}>
                                <Switch checked={userInfo.data.profil === "particulier" && true} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="contentProfil">
                <div className="head sans-pro-semi-bold">
                    Moyen de paiement
                    <div className="icone">
                        <a href="#" className="aHead" onClick={() => _addPaymentMethod("")}> Ajouter un moyen de paiement</a>
                    </div>
                </div>
                <Row>
                    <Col lg={14}>
                        <div className="content-align">
                            <div className="default-select">
                                <div className="image">
                                    <img src={getPaymentMethodIcon(defaultPaymentMethod?.data)} alt="credit" />
                                </div>
                                {defaultPaymentMethod?.isSuccess && !defaultPaymentMethod?.data && (<div className="desc">
                                    <div className="title">Aucun moyen de paiement</div>
                                    <span className="indication" style={{ color: "#9199A4" }}>Par defaut</span>
                                </div>)}
                                {defaultPaymentMethod?.isSuccess && defaultPaymentMethod?.data && (<div className="desc">
                                    <div className="title">*******************{getLast4(defaultPaymentMethod?.data)}</div>
                                    <span className="indication" style={{ color: "#9199A4" }}>Par defaut</span>
                                </div>)}
                                <div className="point" style={{ paddingTop: '15px' }}>
                                    <Radio checked={true}></Radio>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={2}>
                    </Col>
                    <Col lg={8}>
                        <div className="other-card">
                            <a href="#" onClick={() => _addPaymentMethod("card")}>
                                <div className="image">
                                    <img src="./assets/img/Credit-Card-3-icon.png" alt="card" />
                                </div>
                                <div className="card-name">
                                    Carte bancaire
                                </div>
                                <div className="icone">
                                    <IoArrowForward />
                                </div>
                            </a>
                        </div>
                        <div className="other-card disabled-block">
                            <a href="#" onClick={() => _addPaymentMethod("sepa_debit")}>
                                <div className="image">
                                    <img src="./assets/img/paypal-disable.png" alt="card" />
                                </div>
                                <div className="card-name disable">Paypal</div>
                                <div className="icone disable">
                                    <a href="#">
                                        <IoArrowForward />
                                    </a>
                                </div>
                            </a>
                        </div>
                        <div className="other-card">
                            <a href="#" onClick={() => _addPaymentMethod("sepa_debit")}>
                                <div className="image">
                                    <img src="./assets/img/sepa-icon.png" alt="card" />
                                </div>
                                <div className="card-name">
                                    SEPA
                                </div>
                                <div className="icone">
                                    <a href="#" onClick={() => _addPaymentMethod("sepa_debit")}>
                                        <IoArrowForward />
                                    </a>
                                </div>
                            </a>
                        </div>
                    </Col>
                </Row>
            </div>

            <div className="contentProfil">
                <div className="head sans-pro-semi-bold">
                    Adresse de facturation
                    <div className="icone" onClick={() => setShowModal(true)}>
                        <EditOutlined style={{ color: '#566F8F', fontSize: '22px', opacity: '0.8' }} />
                    </div>
                </div>
                <div className="content">
                    <Row>
                        <Col lg={8}>
                            <div className="label">
                                Pays
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userAddress?.pays}
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="label">
                                Ville
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userAddress?.ville}
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="label">
                                Email CC des factures
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userInfo.isSuccess && userInfo.data?.customerEmailCC}
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="label">
                                Code postal
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userAddress?.codepostal}
                            </div>
                        </Col>
                        <Col lg={16}>
                            <div className="label">
                                N° & Nom de rue
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userAddress?.adresse}
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="label">
                                Nom ou Raison sociale
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userInfo.data?.entreprise || `${userInfo.data?.firstname} ${userInfo.data?.lastname}`}
                            </div>
                        </Col>
                        <Col lg={16}>
                            <div className="label">
                                Numéro de TVA (facultatif)
                            </div>
                            <div className="value sans-pro-regular color-595959">
                                {userInfo.isSuccess && userInfo.data?.vatNumber}
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <InformationModalView
                status={showModal}
                handleCancel={() => cancel()}
                userInfo={userInfo.data}
                addr={userAddress}
                closeModal={() => setShowModal(false)}
            />
        </div>
    );
}

export default InformationPartPage;
