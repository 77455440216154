import React, { useEffect, useState } from 'react';
import { Row, Col, Divider, message } from "antd";
import EquipeMenageCreate from "./modal/equipe-menage-create";
import { useEquipeMenageCreateMutation } from "../../services/logement-api";
import { useGetDefaultPaymentMethodQuery } from '../../../profil/services/profil-api';
import MoyenPaiementModalView from '../../../modal-global/moyen-de-paiement';


function NoTeamView({ logement, readyToAskTeam }) {
    const [showModal, setShowModal] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [date, setDate] = useState(null);
    const [equipeCreate] = useEquipeMenageCreateMutation();
    const [showPayMethodModal, setPayMethodModal] = useState(false);
    const payMethod = useGetDefaultPaymentMethodQuery();


    const checkPayMethod = () => {
        if(payMethod.isSuccess && payMethod.data) {
            setShowModal(true);
        } else {
            setPayMethodModal(true)
        }
    }
    
    // console.log("logement2:====>", logement);


    useEffect(() => {
        setDate(null);
    }, [logement?.id]);

    // useEffect(() => {
    //     if (date !== null) {
    // equipeCreate({ logement_ids: [logement.id], start_date: "" }).unwrap()
    //     .then((value) => {
    //         messageApi.open({
    //             type: 'success',
    //             content: 'Modifications enregistrées',
    //             className: 'message-success'
    //         });
    //     })
    //     .catch(() => {
    //     });
    // }
    // }, [date]);

    return (
        <div className="blc-right equipe-de-menage">
            <div className="title">Équipe en charge de ce logement</div>
            <Divider />
            <Row gutter={24}>
                <Col lg={16}>
                    <div className="subTitle">Responsable de site</div>
                    <Row>
                        <Col lg={16}>
                            <p className="calibri-light color-566F8F">Le Responsable de site pilote l’équipe d’aides ménagères qui entretient le logement confié et il
                                veille à la bonne exécution des prestations. Il peut se rendre disponible à votre demande pour visiter le logement ou échanger sur vos exigences précises en termes de qualité.</p>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={6}>
                            <div className="boutton-demande">
                                <button className={"add-team sans-pro-regular " + (!readyToAskTeam && " disable-Button")} onClick={() => checkPayMethod()}> Demander une nouvelle équipe </button>
                            </div>
                        </Col>
                    </Row>
                    <div className="blc-content">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="cadre-chef">
                                    <img src="/assets/img/user-empty.png" alt="airbnb" className="chef-img" />
                                </div>
                                <div className="button-add">
                                    <a href="#" className={"contour-item-a sans-pro-regular " + (!readyToAskTeam && " disable")} onClick={readyToAskTeam ? () => checkPayMethod() : null}> Demander une équipe </a>
                                </div>
                                <br /><br />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={24}>
                                <div className="leftNC">
                                    <div className="nc">NC</div>
                                    <div className="nc-label">Prestations</div>
                                </div>
                                <div className="traitMiddle">

                                </div>
                                <div className="rightNC">
                                    <div className="nc">NC <img src="/assets/img/pousse.png" alt="pousse" className="rightNC-img" /></div>
                                    <div className="nc-label">Taux de performance</div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <div className="subTitle">Aide-ménagère principale</div>
                    <p className="calibri-light color-566F8F">C’est l’aide-ménagère qui est dédiée à l’entretien de votre logement.</p>
                    <br />
                    <div className="blc-content-simple">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={24}>
                                            <div className="aidem-content">
                                                <img src="/assets/img/user-empty.png" alt="airbnb" className="aidem-img" />
                                            </div>
                                            <div className="aidem-label sans-pro-regular">Aucune</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <br />
                    <div className="subTitle">Aide-ménagères remplaçantes</div>
                    <p className="calibri-light color-566F8F">Ce sont les aides ménagères qui sont déjà intervenues sur ce logement en remplacement de l’aide-ménagère <br /> principale. En cas de nouvel empêchement de l’intervenant principal, elles seront sollicitées en priorité.</p>
                    <br />
                    <div className="blc-content-simple">
                        <Row gutter={24}>
                            <Col lg={24}>
                                <div className="contour-item">
                                    <Row gutter={24}>
                                        <Col lg={24}>
                                            <div className="aidem-content">
                                                <img src="/assets/img/user-empty.png" alt="airbnb" className="aidem-img" />
                                            </div>
                                            <div className="aidem-label sans-pro-regular">Aucune</div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <EquipeMenageCreate
                status={showModal}
                logement={logement}
                handleCancel={() => setShowModal(false)}
            />
            <MoyenPaiementModalView
                    status={showPayMethodModal}
                    handleCancel={() => setPayMethodModal(false)}
                />
        </div>
    );
}

export default NoTeamView;