import React, { useState } from 'react';
import { Row, Col, Switch, Divider, Input, message, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useCoutDureeUpdateMutation, useLogementUpdateMutation } from '../../services/logement-api';
import { setRefresh } from '../../services/logement-slice';
const { TextArea } = Input;

function GestionCleView({ logement }) {
    const [messageApi, contextHolder] = message.useMessage();
    const [activeSwitch, setActiveSwitch] = useState(logement?.gestion_cle);
    const [gestionCleDesc, setGestionCleDesc] = useState(logement?.gestion_cle_desc);
    const { user } = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const [logementUpdate] = useLogementUpdateMutation();
    const [coutDureeUpdate] = useCoutDureeUpdateMutation();

    const handleSwitchChange = (switchName, checked) => {
        setLoading(true)
        if (checked) {
            setActiveSwitch(switchName);
            logementUpdate({ id: logement.id, data: ({ gestion_cle: switchName }) }).unwrap()
                .then(() => handleUpdateCout(switchName))
                .catch(() => { });
        } else {
            setActiveSwitch(null);
            logementUpdate({ id: logement.id, data: ({ gestion_cle: "None" }) }).unwrap()
                .then(() => handleUpdateCout("None"))
                .catch(() => { });
        }
    };

    const handleChange = (event) => {
        setGestionCleDesc(event.target.value);
    };

    const handleBlur = () => {
        if (gestionCleDesc?.length > 0) {
            setLoading(true)
            logementUpdate({ id: logement.id, data: ({ gestion_cle_desc: gestionCleDesc }) }).unwrap()
                .then(() => {
                    dispatch(setRefresh(true));
                    setLoading(false)
                    messageApi.open({
                        type: 'success',
                        content: 'Modifications enregistrées',
                        className: 'message-success'
                    });
                })
                .catch(() => { });
        }
    };


    const handleUpdateCout = (switchName) => {

        coutDureeUpdate({
            logementId: logement.id,
            userId: user.id,
            gestion_cle: switchName,
            type: "gestion_cle"
        }).unwrap()
            .then(() => {
                setLoading(false)
                dispatch(setRefresh(true));
                messageApi.open({
                    type: 'success',
                    content: 'Modifications enregistrées',
                    className: 'message-success'
                });
            })
            .catch(() => { });
    };

    return (
        <div className="blc-right">
            <Spin spinning={loading}>
                {contextHolder}
                <div className="title">Gestion des clés</div>
                <Divider />
                <div className="subTitle">Sécurité du logement</div>
                <Row gutter={24}>
                    <Col lg={14}>
                        <div className="blc-content">
                            <Row gutter={24}>
                                <Col lg={24}>
                                    <Row gutter={24}>
                                        <Col lg={12}>
                                            <p className="color-213856 sans-pro-regular">Comment rentre-t-on en possession des clés du logement et qu’en fait-on après chaque prestation ?</p>
                                        </Col>
                                    </Row>
                                    <br />
                                    <div>
                                        <Row gutter={24}>
                                            <Col lg={18}>
                                                <p className="mintitle sans-pro-semi-bold ">Serrure connectée</p>
                                                <span className="sans-pro-light color-5B6E8C">La porte est déverrouillée à distance et l’aide-ménagère reçoit des instructions le jour J pour ouvrir la porte et la refermer à son départ.</span>
                                            </Col>
                                            <Col lg={2}>
                                                <br className="if1020"/>
                                                <Switch
                                                    checked={activeSwitch === "Serrure connectée"}
                                                    onChange={(checked) => handleSwitchChange("Serrure connectée", checked)}
                                                />
                                            </Col>
                                        </Row>
                                        <Divider />
                                    </div>

                                    <div>
                                        <Row gutter={24}>
                                            <Col lg={18}>
                                                <p className="mintitle  sans-pro-semi-bold ">Boîte à clés</p>
                                                <span className="sans-pro-light color-5B6E8C">L’aide-ménagère récupère les clés dans une boîte à clés dont elle a le code, et les y redépose après chaque prestation.</span>
                                            </Col>
                                            <Col lg={2}>
                                                <br className="if1020"/>
                                                <Switch
                                                    checked={activeSwitch === "Boite à clés"}
                                                    onChange={(checked) => handleSwitchChange("Boite à clés", checked)}
                                                />
                                            </Col>
                                        </Row>
                                        <Divider />
                                    </div>

                                    <div>
                                        <Row gutter={24}>
                                            <Col lg={18}>
                                                <p className="mintitle sans-pro-semi-bold ">Commerce de proximité</p>
                                                <span className="sans-pro-light color-5B6E8C">L’aide-ménagère récupère les clés dans un lieu à proximité du logement selon vos instructions, et les y redépose après chaque prestation.</span>
                                            </Col>
                                            <Col lg={2}>
                                                <br className="if1020"/>
                                                <Switch
                                                    checked={activeSwitch === "Commerce de proximité"}
                                                    onChange={(checked) => handleSwitchChange("Commerce de proximité", checked)}
                                                />
                                            </Col>
                                        </Row>
                                        <Divider />
                                    </div>

                                    <div>
                                        <Row gutter={24}>
                                            <Col lg={18}>
                                                <p className="mintitle  sans-pro-semi-bold">Jeu de clés confié à l’aide-ménagère</p>
                                                <span className="sans-pro-light color-5B6E8C">Vous remettez un jeu de clés à l’aide-ménagère avant la première prestation, et elle le conserve aussi longtemps qu’elle intervient dans ce logement.</span>
                                            </Col>
                                            <Col lg={2}>
                                                <br className="if1020"/>
                                                <Switch
                                                    checked={activeSwitch === "Jeu de clés confié à l’aide-ménagère"}
                                                    onChange={(checked) => handleSwitchChange("Jeu de clés confié à l’aide-ménagère", checked)}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <br /><br />
                        </div>
                    </Col>
                    <Col lg={10}>
                        <p className="mintitle" style={{ marginBottom: "10px" }}>Informations complémentaires selon votre choix  (obligatoire)*</p>
                        <TextArea value={gestionCleDesc} onChange={handleChange} onBlur={handleBlur} rows={8} />
                    </Col>
                </Row>
            </Spin>
        </div>
    );
}

export default GestionCleView;