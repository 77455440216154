import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form, Input, Alert } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import flags from 'react-phone-number-input/flags'
import { useAuthRegisterMutation } from "../services/auth-api";
import { setToken } from "../services/auth-slice";
import { saveTokenState } from "../../../utils/local-storage";
import "react-phone-number-input/style.css";
import "../styles/style.scss";
import { UserOutlined } from "@ant-design/icons";
import { countryCodesMap } from "../../../utils/global-var";

export function RegisterProfilPage() {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [password, setPassword] = useState('');
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState(countryCodesMap['FR']);
    const [error, setError] = useState(false);
    const [authRegister] = useAuthRegisterMutation();

    const handleCountryChange = (value) => {
        setCountryCode(countryCodesMap[value]);
    };


    const onFinish = async () => {
        const data = {
            ...state,
            firstname,
            lastname,
            phone,
            password
        }

        setIsLoading(true);
        authRegister(data)
            .unwrap()
            .then((res) => {
                setIsLoading(false);
                saveTokenState(res.access_token, res.refresh_token);
                dispatch(setToken(res.access_token));
                navigate("/page-de-confirmation");
            })
            .catch((e) => {
                setIsLoading(false);
                setError(e.data.message);
            });

    };


    const handleFirstnameChange = (e) => setFirstname(e.target.value);
    const handleLastnameChange = (e) => setLastname(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    return (
        <div className="auth" data-aos="fade-in">
            <div className="login-page">
                <a href="https://kliner.com">
                    <img src="/assets/img/logo-black-small.png" alt="kliner" className="login-logo-topleft" />
                </a>                <Row>
                    <Col lg={5}>
                        <div className="blc-content">
                            <h1>Paramétrons votre profil</h1>
                            <p>Ces informations seront utilisées pour sécuriser votre espace et générer vos documents (factures, attestation crédit d’impôt...).</p> <br />
                            <Form name="basic" form={form} onFinish={onFinish} autoComplete="off" layout="">
                                <label>Prénom & Nom</label>
                                <Form.Item>
                                    <Row gutter={24}>
                                        <Col lg={12}>
                                            <Input placeholder="Prénom" onChange={handleFirstnameChange} />
                                        </Col>
                                        <Col lg={12}>
                                            <Input placeholder="Nom" onChange={handleLastnameChange} />
                                        </Col>
                                    </Row>
                                </Form.Item>

                                <label>Téléphone portable</label>
                                <Form.Item name="phone" style={{ position: "relative" }}>

                                    <PhoneInput
                                        className="phone-input"
                                        placeholder="0611220011"
                                        flags={flags}
                                        value={phone}
                                        onChange={setPhone}
                                        defaultCountry="FR"
                                        onCountryChange={handleCountryChange}
                                    />
                                    <div style={{ position: 'absolute', left: 105, top: '50%', transform: 'translateY(-50%)', color: "gray", borderRight: "1.5px solid #8080805c", paddingRight: "6px", width: "35px" }}>
                                        {countryCode}
                                    </div>
                                    <div style={{ position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }}>
                                        <UserOutlined />
                                    </div>
                                </Form.Item>

                                <label>Mot de passe</label>
                                <Form.Item name="password">
                                    <Input.Password placeholder="(6 caractères minimum)" onChange={handlePasswordChange} />
                                </Form.Item>
                                <br />
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className="btn-lg"
                                        loading={isLoading}
                                        style={{ fontWeight: "600" }}
                                        disabled={!firstname || !lastname || !password || !phone}
                                    >
                                        Accéder à votre espace Kliner
                                    </Button>
                                </Form.Item>
                                <span className="text-cgu">En créant votre compte, vous acceptez les <a href="#" >CGV & la politique de confidentialité</a> de Kliner.</span>
                            </Form>
                            {error && <Alert message={error} type="error" closable />}
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    );
}

export default RegisterProfilPage;