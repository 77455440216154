import React from "react";
import { Modal, Row, Col } from "antd";

const ExempleAutomatisationModal = ({ status, handleCancel }) => {
    return (
        <Modal
            open={status}
            footer={null}
            closable={true}
            onCancel={() => handleCancel()}
            width={600}>
            <div className="exemple-instructions">
                <div className="title">Exemple d’instructions d’accès</div>
                <Row gutter={24}>
                    <Col lg={12}>
                        <h3 className="sans-pro-semi-bold">Étape 1</h3>
                        <p>Arriver au 14 bis rue Dupont à Paris, devant le portillon.</p>
                    </Col>

                    <Col lg={12}>
                        <div className="blc">
                            <div className='img' style={{ backgroundImage: `url('assets/img/etape1.jpg')` }} />
                        </div>
                    </Col>
                </Row>
                <br /> <br />
                <Row gutter={24}>
                    <Col lg={12}>
                        <h3 className="sans-pro-semi-bold">Étape 2</h3>
                        <p>Tapez le code 1234B sur le digicode situé à droite du portillon</p>
                    </Col>

                    <Col lg={12}>
                        <div className="blc">
                            <div className='img' style={{ backgroundImage: `url('assets/img/etape2.jpg')` }} />
                        </div>
                    </Col>
                </Row>

                <br /> <br />
                <Row gutter={24}>
                    <Col lg={12}>
                        <h3 className="sans-pro-semi-bold">Étape 3</h3>
                        <p>Traverser la cour pour vous rendre devant le bâtiment B2</p>
                    </Col>

                    <Col lg={12}>
                        <div className="blc">
                            <div className='img' style={{ backgroundImage: `url('assets/img/etape3.jpg')` }} />
                        </div>
                    </Col>
                </Row>

                <br /> <br />
                <Row gutter={24}>
                    <Col lg={12}>
                        <h3 className="sans-pro-semi-bold">Étape 4</h3>
                        <p>Entrez dans le bâtiment B2 et montez au 3ème étage puis prenez à droite jusqu’à la porte verte. Vous y êtes.</p>
                    </Col>

                    <Col lg={12}>
                        <div className="blc">
                            <div className='img' style={{ backgroundImage: `url('assets/img/etape4.jpg')` }} />
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
export default ExempleAutomatisationModal;