import React from 'react';
import NoTeamView from "./equipe-de-menage/no-team-view";
import TeamPendingView from "./equipe-de-menage/team-pending-view";
import TeamFoundView from "./equipe-de-menage/team-found-view";
import { useEquipeMenageCheckQuery } from '../services/logement-api';


function EquipeDeMenageView({ logement, readyToAskTeam }) {
  const data = useEquipeMenageCheckQuery(logement.id)

  return (
    <>
      {(data?.data === null) && <NoTeamView logement={logement} readyToAskTeam={readyToAskTeam} />}
      {(data?.data?.status === "Offre envoyée") && <> <TeamPendingView /></>}
      {(data?.data?.status === "Offre acceptée") && <> <TeamFoundView logement={logement} /></>}
    </>
  );
}

export default EquipeDeMenageView;