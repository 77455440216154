import React from 'react';
import "../../styles/style.scss"
import {Button, Col, Input, Row} from "antd";

const AccueilPubPage = ({itemSelected}) => {
    return (
        <div className='AccueilPubPage'>
            <div className="menu">
                <div className={"menu-in sans-pro-semi-bold active"} onClick={() => itemSelected("AccueilPubPage")}>Accueil</div>
                <div className={"menu-in sans-pro-semi-bold "} onClick={() => itemSelected("DashboardPubPage")}>Dashboard</div>
            </div>
            <Row>
                <Col lg={5}></Col>
                <Col lg={14}>
                    <div className="titre sans-pro-bold">
                        Trouvez les voyageurs pour <br/>
                        <span>Booster votre taux d'occupation</span> <br/>
                        En Quelques Secondes
                    </div>

                    <div className="subTitre sans-pro-regular">
                        Générez des publicités pour doper vos réservations en un clic !
                    </div>

                    <div className="search-box">
                        <Row>
                            <Col lg={4}></Col>
                            <Col lg={16}>
                                <Input
                                    className='search-input'
                                    placeholder="Sur quel logement il vous faut des reservations ?"
                                    suffix={<Button type={"primary"} onClick={() => itemSelected("PubLogementModalPage")}>
                                        Générer
                                        <div className="imageButton">
                                            <img src="./assets/img/start.png" alt="generer"/>
                                        </div>
                                    </Button>}
                                />
                            </Col>
                            <Col lg={4}></Col>
                        </Row>
                    </div>

                    <div className="promotion">
                        <Row>
                            <Col lg={6}>
                                <div className="head sans-pro-semi-bold">300K</div>
                                <div className="description sans-pro-regular">Annonces vues</div>
                            </Col>
                            <Col lg={6}>
                                <div className="head sans-pro-semi-bold">700+</div>
                                <div className="description sans-pro-regular">Réservations générées</div>
                            </Col>
                            <Col lg={6}>
                                <div className="head sans-pro-semi-bold">1.2M€+</div>
                                <div className="description sans-pro-regular">Revenus locatifs</div>
                            </Col>
                            <Col lg={6}>
                                <div className="head sans-pro-semi-bold">2.1K+</div>
                                <div className="description sans-pro-regular">hôtes heureux</div>
                            </Col>
                        </Row>
                    </div>

                    <div className="fonctionnalite sans-pro-semi-bold">
                        FONCTIONNALITÉS
                    </div>

                    <div className="subHead sans-pro-bold">
                        Augmentez vos revenus de location saisonnière. <br/>
                        Grâce à notre intelligence artificielle.
                    </div>

                    <Row className="block-fonction">
                        <Col lg={8}>
                            <div className="block-in">
                                <div className="icone">
                                    <img src="./assets/img/annonce.png" alt="icone"/>
                                </div>
                                <div className="head sans-pro-semi-bold">Annonce personnalisée</div>
                                <div className="description">
                                    <p className="sans-pro-regular">Générez en un clic des créations publicitaires qui
                                        attirent les voyageurs sur vos annonces de locations. Notre IA crée les textes
                                        et images personnalisés pour chacun de vos logements.
                                    </p>
                                    <Button> En savoir plus</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="block-in">
                                <div className="icone">
                                    <img src="./assets/img/target.png" alt="icone"/>
                                </div>
                                <div className="head sans-pro-semi-bold">Publicité multi-canal</div>
                                <div className="description">
                                    <p className="sans-pro-regular">Kliner est la seule plateforme qui paramètre et
                                        déploie automatiquement vos publicités sur Facebook, Instagram, Google, TikTok
                                        et YouTube en quelques minutes, tout en priorisant les canaux les plus
                                        performants.</p>

                                    <Button> En savoir plus</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="block-in">
                                <div className="icone">
                                    <img src="./assets/img/ciblage.png" alt="icone"/>
                                </div>
                                <div className="head sans-pro-semi-bold">Ciblage dynamique</div>
                                <div className="description">
                                    <p className="sans-pro-regular">Ciblez automatiquement les personnes susceptibles
                                        d’effectuer un voyage dans un périmètre autour de vos logements sur vos périodes
                                        creuses.</p>

                                    <Button> <a href="https://intercom.help/kliner-france/fr/articles/9172447-obtenez-100-de-taux-d-occupation" target="_blank">En savoir plus</a></Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="block-in">
                                <div className="icone">
                                    <img src="./assets/img/optimisation.png" alt="icone"/>
                                </div>
                                <div className="head sans-pro-semi-bold">Optimisation intelligente</div>
                                <div className="description">
                                    <p className="sans-pro-regular">Notre IA répartit dynamiquement votre budget sur les
                                        canaux où se trouve votre cible à chaque instant. Elle optimise ensuite
                                        automatiquement les annonces pour maximiser votre retour sur investissement.</p>

                                    <Button> En savoir plus</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="block-in">
                                <div className="icone">
                                    <img src="./assets/img/budget.png" alt="icone"/>
                                </div>
                                <div className="head sans-pro-semi-bold">Budget maîtrisé</div>
                                <div className="description">
                                    <p className="sans-pro-regular">Générez des milliers d’euros de revenus locatifs en
                                        investissant quelques euros par jour en publicité. De plus, aucun euro n’est
                                        dépensé lorsque votre planning affiche complet.</p>

                                    <Button> En savoir plus</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={8}>
                            <div className="block-in">
                                <div className="icone">
                                    <img src="./assets/img/rapport.png" alt="icone"/>
                                </div>
                                <div className="head sans-pro-semi-bold">Rapports en temps réel</div>
                                <div className="description">
                                    <p className="sans-pro-regular">Découvrez en temps réel combien de visites les
                                        publicités génèrent sur vos annonces de location et surtout, l’évolution de
                                        votre taux d’occupation.</p>

                                    <Button> <a href="https://intercom.help/kliner-france/fr/articles/9172447-obtenez-100-de-taux-d-occupation" target="_blank">En savoir plus</a></Button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <div className="fonctionnalite sans-pro-semi-bold">
                        RETOUR SUR INVESTISSEMENT
                    </div>

                    <div className="subHead sans-pro-bold" style={{marginBottom: "10px"}}>
                        Générez de nouvelles réservations qui vous <br/>
                        rapportent jusqu’à 13 fois le montant investi.
                    </div>

                    <div className="subTitre sans-pro-regular" style={{textAlign: 'left', marginTop: "25px"}}>
                        En moyenne un loueur qui dépense 5€ par jour en publicité, <br/>
                        obtient 1 950€ de revenus locatifs additionnels par mois.
                    </div>

                    <div className="avis">
                        <div className="text">
                            <Row>
                                <Col lg={4}></Col>
                                <Col lg={17}>
                                    <span className="sans-pro-light">
                                        ‘‘Kliner a métamorphosé la façon dont je gère mes logements et la croissance de mes
                                    revenus locatifs. C’est indispensable pour tout hôte qui veut passer à la vitesse
                                    supérieure.’’
                                    </span>
                                </Col>
                                <Col lg={3}></Col>
                            </Row>
                        </div>
                        <div className="name sans-pro-semi-bold">Henri Loubens</div>
                        <div className="fonction sans-pro-light">Superhôte</div>
                    </div>

                    <div className="banniere">
                        <Row>
                            <Col lg={13}>
                                <div className="head sans-pro-bold">Prêt à commencer ?</div>
                                <div className="description">Créez une publicité gratuitement pour essayer le service.
                                </div>
                            </Col>
                            <Col lg={11}>
                                <Input
                                    className='search-input'
                                    placeholder="Saisir le nom d'un logement..."
                                    suffix={<Button type={"primary"}>
                                        Générer
                                        <div className="imageButton">
                                            <img src="./assets/img/start.png" alt="generer"/>
                                        </div>
                                    </Button>}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col lg={5}></Col>
            </Row>
        </div>
    );
}

export default AccueilPubPage;